import { useEffect, useRef, useState, useCallback } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import apiClient from "../utils/axiosInstance";
import { useAppDispatch, useAppSelector } from "../store";
import { addDocument, addFolder } from "../store/DocumentSlice";
import { toast } from "react-toastify";

const FileDisplay = ({ file, onRemove, isUploading, docS3Loc }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const getFileIcon = (fileName) => {
        const fileExtension = fileName.split(".").pop().toLowerCase();
        switch (fileExtension) {
            case "jpg":
            case "jpeg":
            case "png":
                return "mdi:file-image";
            case "pdf":
                return "mdi:file-pdf-box";
            case "doc":
            case "docx":
                return "mdi:file-word-box";
            default:
                return "mdi:file";
        }
    };

    const deleteFile = async () => {
        setIsDeleting(true);
        try {
            await apiClient().delete("/delete-file-from-s3", {
                params: { fileName: docS3Loc },
            });
            onRemove(file);
        } catch (error) {
            console.error("Error deleting file:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div className="flex items-center space-x-2 p-2 border rounded shadow-sm bg-white">
            <Icon icon={getFileIcon(file.name)} className="w-6 h-6 text-gray-500" />
            <span className="text-sm text-gray-700 flex-grow truncate">
                {file.name}
            </span>
            <div className="flex-shrink-0">
                {isUploading || isDeleting ? (
                    <div className="w-6 h-6 flex items-center justify-center bg-gray-200 rounded-full">
                        <Icon
                            icon="eos-icons:loading"
                            className="w-4 h-4 text-blue-500 animate-spin"
                        />
                    </div>
                ) : (
                    <button
                        onClick={deleteFile}
                        className="w-6 h-6 flex items-center justify-center bg-gray-200 rounded-full text-red-500 hover:bg-gray-300 hover:text-red-700 focus:outline-none transition-colors duration-200"
                    >
                        <Icon icon="mdi:close" className="w-4 h-4" />
                    </button>
                )}
            </div>
        </div>
    );
};

export const FilesPreview = ({ selectedFiles, setSelectedFiles, uploadingFiles }) => {
    const handleRemoveFile = (fileToRemove) => {
        setSelectedFiles((prevFiles) =>
            prevFiles.filter((fileObj) => fileObj.file !== fileToRemove)
        );
    };
    return (
        <div className={`space-y-4 ${selectedFiles.length > 0 && "m-2"}`}>
            <div className="flex flex-wrap gap-4">
                {selectedFiles.map((fileObj, index) => (
                    <FileDisplay
                        key={index}
                        file={fileObj.file}
                        onRemove={handleRemoveFile}
                        isUploading={uploadingFiles.includes(fileObj.file.name)}
                        docS3Loc={fileObj.docS3Loc}
                    />
                ))}
            </div>
        </div>
    );
};

export const ImageUpload = ({ setSelectedFiles, setUploadingFiles }) => {
    const { organizationState } = useAppSelector((state) => ({
        organizationState: state.organization.organization[0].isDocumentSearchUsed,
      }));

    const fileInputRef = useRef(null);
    const dispatch = useAppDispatch();
    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const uploadFile = async (file) => {
        setUploadingFiles((prev) => [...prev, file.name]);
        const formData = new FormData();
        formData.append("file", file);

        try {
            if(organizationState === false){
                toast.error("Please enable document embedding to upload files")
                return;
            }
            const response = await apiClient().post(
                "/upload-file-to-folder",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            dispatch(addFolder(response.data.folder));
            dispatch(addDocument(response.data.document.document));
            setSelectedFiles((prev) =>
                prev.map((f) =>
                    f.file.name === file.name
                        ? {
                            ...f,
                            docS3Loc: response.data.document.document.docS3Loc,
                            url: response.data.document.document.document,
                        }
                        : f
                )
            );
        } catch (error) {
            console.error("Error uploading file:", error);
            setSelectedFiles((prev) =>
                prev.filter((f) => f.file.name !== file.name)
            );
        } finally {
            setUploadingFiles((prev) => prev.filter((name) => name !== file.name));
        }
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles((prevFiles) => {
            const newFiles = files.filter(
                (file) =>
                    !prevFiles.some(
                        (prevFile) =>
                            prevFile.file.name === file.name &&
                            prevFile.file.size === file.size
                    )
            );
            newFiles.forEach(uploadFile);
            return [
                ...prevFiles,
                ...newFiles.map((file) => ({ file, docS3Loc: null, url: null })),
            ];
        });
        event.target.value = null;
    };

    return (
        <>
            <div className="rdw-option-wrapper" onClick={handleIconClick}>
                <img src="/upload.svg" alt="Upload File" />
            </div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept="image/*,.pdf,.doc,.docx"
            />
        </>
    );
};

export const ChatFileUpload = ({ setSelectedFiles, setUploadingFiles }) => {
    const fileInputRef = useRef(null);
    const ACCEPTED_FILE_TYPES = "image/*,.pdf,.doc,.docx";
    const MAX_FILES = 5;
    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const uploadFiles = useCallback(async (files) => {
        const uploadPromises = files.map(async (file) => {
            setUploadingFiles((prev) => [...prev, file.name]);
            const formData = new FormData();
            formData.append("file", file);

            try {
                const response = await apiClient().post(
                    "/upload-file-to-s3",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                const uploadedFileData = {
                    file,
                    docS3Loc: response.data.docS3Loc,
                    url: response.data.url,
                };

                setSelectedFiles((prev) => {
                    const fileIndex = prev.findIndex(
                        (f) => f.file.name === file.name
                    );
                    if (fileIndex !== -1) {
                        const updatedFiles = [...prev];
                        updatedFiles[fileIndex] = uploadedFileData;
                        return updatedFiles;
                    }
                    return [...prev, uploadedFileData];
                });

                return true;
            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
                setSelectedFiles((prev) =>
                    prev.filter((f) => f.file.name !== file.name)
                );
                return false;
            } finally {
                setUploadingFiles((prev) =>
                    prev.filter((name) => name !== file.name)
                );
            }
        });

        return Promise.all(uploadPromises);
    }, [setSelectedFiles, setUploadingFiles]);

    const isFileUnique = useCallback((file, existingFiles) => {
        return !existingFiles.some(
            (existingFile) =>
                existingFile.file.name === file.name &&
                existingFile.file.size === file.size
        );
    }, []);

    const handleFileChange = useCallback(async (event) => {
        const files = Array.from(event.target.files);
        
        setSelectedFiles((prevFiles) => {
            if (prevFiles.length + files.length > MAX_FILES) {
                alert(`You can only upload a maximum of ${MAX_FILES} files. Currently selected: ${prevFiles.length}`);
                return prevFiles;
            }
            const uniqueFiles = files.filter((file) => 
                isFileUnique(file, prevFiles)
            );

            if (uniqueFiles.length > 0) {
                uploadFiles(uniqueFiles);
                return [
                    ...prevFiles,
                    ...uniqueFiles.map((file) => ({
                        file,
                        docS3Loc: null,
                        url: null,
                    })),
                ];
            }
            
            return prevFiles;
        });

        event.target.value = null;
    }, [setSelectedFiles, uploadFiles, isFileUnique]);

    return (
        <>
            <div 
                className="rdw-option-wrapper" 
                onClick={handleIconClick}
                role="button"
                aria-label="Upload files"
            >
                <img src="/upload.svg" alt="Upload File" />
            </div>
            <input
                type="file"
                multiple
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileChange}
                accept={ACCEPTED_FILE_TYPES}
            />
        </>
    );
};