import React, { memo, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import Avatar from "react-avatar";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { format, isToday, isYesterday, isSameWeek, isSameYear } from "date-fns";
import useAuth from "../utils/useAuth";
import { Document, Page, pdfjs } from "react-pdf";
import Logo1 from "../assets/logo-1.png";
import MessageLinkPreview from "./MessageLinkPreview";
import { useAppDispatch } from "../store";
import {
  addemojiinDirectMessage,
  addEmojiinSpace,
  getMessageReplies,
  removeDirectMessage,
  removeChat,
  removeReplyMessage,
  decreaseReplyDirectMessageCounter,
  updateDirectMessage,
  updateMessage,
  updateReplyMessage,
  decreaseSpaceMessageCounter,
} from "../store/ThreadSlice";
import moment from "moment/moment";
import apiClient from "../utils/axiosInstance";
import { useSocket } from "../context/SocketProvider";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import wwwImg from "../assets/www.png";
import { MdEdit, MdDelete } from "react-icons/md";
import { useAppSelector } from "../store";
import MessageEditor2 from "./MessageEditor2";
import { toast } from "react-toastify";
import avtarPic from "../assets/user.png";
import Modal from "./Modal";
const MessageItem = memo(
  ({
    msg,
    handleRelatedQueryClick,
    organization,
    isDisplayReplies,
    tab,
    setIsReplying,
    isReplying,
    handleRelatedQueryClickReplay,
  }) => {
    const { user } = useAuth();
    const { socket } = useSocket();
    const [isHovered, setIsHovered] = useState(false);
    const [showPreview, setShowPreview] = useState(true);
    const dispatch = useAppDispatch();
    const [isEmojiModalOpen, setEmojiModalOpen] = useState(false);
    const [did, setDid] = useState("");
    const [mid, setmid] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { replyMessage } = useAppSelector((state) => ({
      replyMessage: state.thread.replyMessage,
    }));

    if (msg?.is_deleted) {
      return;
    }

    const downloadFile = (url, fileName) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => console.error("Error downloading file:", error));
    };
    const getFileIcon = (fileName) => {
      const extension = fileName.split(".").pop().toLowerCase();
      switch (extension) {
        case "jpg":
        case "jpeg":
        case "png":
          return "ph:file-png-bold";
        case "pdf":
          return "ph:file-pdf-bold";
        case "doc":
        case "docx":
          return "ph:file-doc-bold";
        default:
          return "mdi:file";
      }
    };

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    const renderFile = (file) => (
      <div
        key={file.fileName}
        className="flex flex-col justify-between p-2 rounded-lg border-2 bg-white mr-5 mb-2 w-[19rem]"
      >
        <div className="flex items-center justify-between w-full border-b-2 pb-2">
          <div className="flex items-center">
            <Icon icon={getFileIcon(file.fileName)} className="w-6 h-6 mr-2" />
            <p className="text-sm text-gray-700 flex-grow w-40 truncate">
              {file.fileName}
            </p>
          </div>
          <div className="flex-shrink-0">
            {isHovered && (
              <div className="flex items-center">
                <button
                  onClick={() => downloadFile(file.url, file.fileName)}
                  className="w-6 h-6 flex items-center justify-center bg-sidebarBgColor rounded-full  hover:bg-gray-300  mr-2"
                  style={{ color: organization?.primaryColor }}
                >
                  <Icon icon="octicon:download-24" className="w-4 h-4" />
                </button>
                <button className="w-6 h-6 flex items-center justify-center bg-sidebarBgColor rounded-full text-red-500 hover:bg-gray-300">
                  <Icon icon="mdi:close" className="w-4 h-4" />
                </button>
              </div>
            )}
          </div>
        </div>
        {renderFilePreview(file)}
      </div>
    );

    const renderFilePreview = (file) => {
      const extension = file.fileName.split(".").pop().toLowerCase();
      switch (extension) {
        case "txt":
          return (
            <pre className="w-full mt-2 max-h-40 overflow-auto">
              File content here
            </pre>
          );
        case "jpg":
        case "jpeg":
        case "webp":
        case "png":
          return (
            <div className="mt-2 w-full h-40 overflow-hidden">
              <img
                src={file.url}
                alt={file.fileName}
                className="w-full h-full object-contain"
              />
            </div>
          );
        case "pdf":
          return (
            <div className="pdf-preview mt-2 w-full h-40 overflow-hidden">
              <Document file={file.url}>
                <Page pageNumber={1} width={300} />
              </Document>
            </div>
          );
        case "doc":
        case "docx":
          return (
            <div className="mt-2 w-full h-40 overflow-hidden">
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                document={file.url}
              />
            </div>
          );
        default:
          return null;
      }
    };

    const renderAvatar = () => {
      if (msg?.isAiChat) {
        return (
          <img
            src={Logo1}
            alt="cospace Logo"
            className="w-10 h-10 mr-2 text-gray-950 rounded-[50%] p-2"
            style={{ backgroundColor: "rgb(245, 245, 247)" }}
          />
        );
      } else {
        return (
          <img
            alt="sender profile"
            src={msg?.senderProfile || avtarPic}
            className="w-10 h-10 mr-2 text-gray-950 object-cover rounded-[50%]"
            style={{ backgroundColor: "rgb(245, 245, 247)" }}
          />
        );
      }
    };

    const getSenderName = () => {
      return msg?.isAiChat ? organization?.chatBotName : msg?.senderName;
    };

    const handleRelatedClick = async (question, messageType, provider) => {
      handleRelatedQueryClick(question, messageType, provider);
    };

    const handleClosePreview = () => {
      setShowPreview(false);
    };

    const getFaviconUrl = (url) => {
      try {
        const urlObj = new URL(`https://${url}`);
        return `https://www.google.com/s2/favicons?domain=${urlObj.hostname}`;
      } catch (e) {
        return null;
      }
    };

    const renderMessage = () => {
      if (isEditing) {
        return (
          <MessageEditor2
            initialMessage={msg.message}
            onSave={handleSaveEdit}
            onCancel={() => setIsEditing(false)}
          />
        );
      }
      if (msg?.isAiChat) {
        if (msg.messageType === "WebChatMessage") {
          return (
            <div className={`${!isReplying && "xl:px-5"} md:px-2`}>
              <div className="flex items-start">
                {/* {renderAvatar()} */}
                <div className="flex-1">
                  <p className="text-[15px] font-semibold capitalize text-[#1D1C1D] leading-[20.75px]">
                    {/* {getSenderName()} */}
                    <span className="text-[#616061] font-medium ml-2 text-[12px] leading-[18.16px]">
                      {/* {format(new Date(msg?.createdAt), "h:mm")} */}
                    </span>
                  </p>

                  <div className="text-xl font-bold mb-5 mt-2">{msg.query}</div>
                  <div className="markdown-content mb-4">
                    <Markdown remarkPlugins={[remarkGfm]}>
                      {msg.answer.answer}
                    </Markdown>
                  </div>

                  {msg?.answer?.grounding_metadata?.grounding_chunks?.length >
                    0 && (
                    <div className="text-sm border-t pt-4">
                      <h3 className="font-semibold mb-2">Sources:</h3>
                      <div className="ml-4 flex flex-wrap">
                        {msg.answer.grounding_metadata.grounding_chunks.map(
                          (citation, index) => (
                            <a
                              key={index}
                              href={citation.web.uri}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:text-blue-800 font-medium"
                            >
                              <div className="relative group border rounded-lg p-4 m-3 w-[280px] max-h-[86px]">
                                <div className="flex items-center mb-2">
                                  <p className="mr-2 font-medium">
                                    {index + 1}.
                                  </p>
                                  <img
                                    src={
                                      getFaviconUrl(citation.web.title) ||
                                      wwwImg
                                    }
                                    alt="Favicon"
                                    className="w-6 h-6 mr-2"
                                  />
                                  <p className="text-nowrap text-ellipsis overflow-hidden">
                                    {citation.web.meta_title ||
                                      citation.web.title}
                                  </p>
                                </div>
                                <p className="text-gray-600 text-sm overflow-hidden text-ellipsis text-nowrap">
                                  {citation.web.meta_description ||
                                    "No description available."}
                                </p>
                                <div className="absolute hidden group-hover:flex flex-col bg-white border shadow-lg p-2 rounded-md top-[60px] mt-2 left-[50px] min-w-[280px] max-w-[400px] max-h-[200px] z-10 overflow-auto">
                                  <p className="text-gray-800 font-semibold">
                                    {citation.web.meta_title ||
                                      citation.web.title}
                                  </p>
                                  <p className="text-gray-600 text-sm">
                                    {citation.web.meta_description ||
                                      "No description available."}
                                  </p>
                                </div>
                              </div>
                            </a>
                          )
                        )}
                      </div>
                    </div>
                  )}

                  {msg.answer?.questions?.length > 0 && (
                    <div className="mt-8  text-[#676767]">
                      <h3 className="font-bold mb-2">Related:</h3>
                      <ol className="list-decimal list-inside">
                        {msg.answer?.questions?.map((question, index) => (
                          <li
                            key={index}
                            className="mb-1 hover:underline cursor-pointer"
                            onClick={() =>
                              handleRelatedClick(
                                question,
                                msg.messageType,
                                msg?.AIModel
                              )
                            }
                          >
                            {question}
                          </li>
                        ))}
                      </ol>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }

        // Original AI chat rendering logic
        return (
          <>
            {Array.isArray(msg?.answer?.answers) &&
              msg.answer.answers.length > 0 && (
                <div className="ml-12 mt-[-15px]">
                  {msg?.answer?.answers?.map((answer, index) => (
                    <div key={index} className="mb-4">
                      {answer.filename && (
                        <div className="flex items-center justify-between my-2 bg-gray-100 p-2 rounded text-[#0D2425]">
                          <div className="font-semibold mt-2">
                            {answer.filename}
                          </div>
                          {answer.source && (
                            <button
                              onClick={() =>
                                downloadFile(answer.source, answer.filename)
                              }
                              className="flex items-center text-blue-600 hover:text-blue-800"
                            >
                              <Icon
                                icon="octicon:download-24"
                                className="w-4 h-4 mr-1"
                              />
                              Download
                            </button>
                          )}
                        </div>
                      )}
                      <div className="markdown-content">
                        <Markdown remarkPlugins={[remarkGfm]}>
                          {answer.answer}
                        </Markdown>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            {msg?.answer?.questions && msg.answer?.questions?.length > 0 && (
              <div className="ml-12 mt-4 text-[#676767]">
                <h3 className="font-bold mb-2">Related:</h3>
                <ol className="list-decimal list-inside">
                  {msg.answer?.questions?.map((question, index) => (
                    <li
                      key={index}
                      className="mb-1 hover:underline"
                      onClick={() =>
                        handleRelatedClick(
                          question,
                          msg?.messageType,
                          msg?.AIModel
                        )
                      }
                    >
                      {question}
                    </li>
                  ))}
                </ol>
              </div>
            )}
            {msg.answer.answer && (
              <div className="ml-12 mt-[-15px]">
                <div className="text-xl font-bold mb-5">{msg.answer.input}</div>
                <div className="markdown-content mb-4 w-50">
                  <Markdown remarkPlugins={[remarkGfm]}>
                    {msg.answer.answer}
                  </Markdown>
                </div>
                {msg.answer.relevant_queries?.length > 0 && (
                  <div className="ml-12 mt-4 text-[#676767]">
                    <h3 className="font-bold mb-2">Related:</h3>
                    <ol className="list-decimal list-inside">
                      {msg.answer.relevant_queries.map((query, index) => (
                        <li
                          key={index}
                          className="mb-1 hover:underline cursor-pointer"
                          onClick={() =>
                            isReplying
                              ? handleRelatedQueryClickReplay(
                                  query,
                                  msg.messageType,
                                  msg?.AIModel
                                )
                              : handleRelatedClick(
                                  query,
                                  msg.messageType,
                                  msg?.AIModel
                                )
                          }
                        >
                          {query}
                        </li>
                      ))}
                    </ol>
                  </div>
                )}
              </div>
            )}
          </>
        );
      } else {
        switch (msg.messageType) {
          case "text":
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const match = msg.message.match(urlRegex);
            if (match) {
              const url = match[0];
              const parts = msg.message.split(urlRegex);
              return (
                <>
                  <p className="ml-12 mt-[-15px]">
                    {parts.map((part, index) =>
                      index % 2 === 0 ? (
                        part
                      ) : (
                        <a
                          key={index}
                          href={part}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          {part}
                        </a>
                      )
                    )}
                  </p>
                  {showPreview && (
                    <MessageLinkPreview
                      url={url}
                      onClose={handleClosePreview}
                    />
                  )}
                </>
              );
            }
            return (
              <div className="ml-12 mt-[-15px] markdown-content">
                <Markdown
                  className="markdown-content"
                  remarkPlugins={[remarkGfm]}
                >
                  {msg.message}
                </Markdown>
                {msg.is_edited && (
                  <span className=" text-gray-500 text-sm">(edited)</span>
                )}
              </div>
            );
          case "file":
          case "text-file":
            return (
              <>
                {msg.messageType === "text-file" && (
                  <div className="ml-12 mt-[-15px] markdown-content">
                    <Markdown
                      className="markdown-content"
                      remarkPlugins={[remarkGfm]}
                    >
                      {msg.message}
                    </Markdown>
                    {msg.is_edited && (
                      <span className=" text-gray-500 text-sm">(edited)</span>
                    )}
                  </div>
                )}
                <div className="flex flex-wrap ml-12 mt-2">
                  {msg?.uploaded_shared_files?.map((file) => renderFile(file))}
                </div>
              </>
            );
          default:
            return null;
        }
      }
    };

    const replyInThread = (messageId) => {
      const data = {
        messageId,
        tab,
      };
      dispatch(getMessageReplies(data));
      setIsReplying(true);
    };

    const EmojiPicker = ({ onSelect }) => {
      const emojis = ["😀", "😂", "😍", "🥳"];

      return (
        <div className="emoji-picker bg-slate-200  flex gap-2 py-2 items-center p-2 rounded-full mt-2 w-full ">
          {emojis.map((emoji, index) => (
            <button className="" key={index} onClick={() => onSelect(emoji)}>
              {emoji}
            </button>
          ))}
        </div>
      );
    };

    const handleEmojiButtonClick = (id) => {
      if (tab === "dm") {
        setDid(id);
      } else {
        setmid(id);
      }
      setEmojiModalOpen((prev) => !prev);
    };

    const handleEmojiSelect = async (emoji) => {
      try {
        if (tab === "dm") {
          const response = await apiClient().post(`emoji-reply/${did}`, {
            emoji: emoji,
          });
          socket.emit("emoji_reply", {
            directMessageId: did,
            emoji: response.data.emojis,
          });
          dispatch(
            addemojiinDirectMessage({
              messageId: did,
              emojis: response.data.emojis,
            })
          );
        } else {
          const response = await apiClient().post(
            `/emoji-reply/thread-massage/${mid}`,
            { emoji: emoji }
          );
          dispatch(
            addEmojiinSpace({ messageId: mid, emojis: response.data.emojis })
          );
          socket.emit("emoji_thread_reply", {
            messageId: msg.messageId,
            emoji: response.data.emojis,
          });
        }
      } catch (error) {
        console.error("Error saving emoji:", error);
        setEmojiModalOpen(false);
      }
      setEmojiModalOpen(false);
    };
    const emojiCountMap = Array.isArray(msg?.emojis)
      ? msg.emojis.reduce((acc, emoji) => {
          acc[emoji.react_emoji] = (acc[emoji.react_emoji] || 0) + 1;
          return acc;
        }, {})
      : {};

    const handleMessageDelete = () => {
      setDeleteModalOpen(true);
    };

    const handleConfirmDelete = () => {
      deleteMessage();
      setDeleteModalOpen(false);
    };
    const deleteMessage = async () => {
      try {
        const payload = {};
        if (tab === "dm" && !msg.replyId) {
          payload["messageId"] = msg.directMessageId || msg?.messageId;
          payload["isDirectMessage"] = true;
        } else if (msg.replyId) {
          payload["messageId"] = msg.replyId;
          payload["isReplyMessage"] = true;
        } else if (msg.messageId) {
          payload["messageId"] = msg.messageId;
          payload["isThreadMessage"] = true;
        }
        const response = await apiClient().post("delete-message", payload);
        if (response.status === 200) {
          console.log("payload", payload);
          if (tab === "dm" && !msg.replyId) {
            dispatch(removeDirectMessage(payload.messageId));
            if (replyMessage?.messageId === payload.messageId) {
              setIsReplying(false);
            }
          } else if (payload["isThreadMessage"]) {
            dispatch(removeChat(payload.messageId));
          } else if (payload["isReplyMessage"]) {
            if (
              replyMessage?.messageId === msg.messageId &&
              Number(replyMessage?.replyCounter) === 1
            ) {
              setIsReplying(false);
            }
            dispatch(removeReplyMessage(payload.messageId));
            if (tab === "dm") {
              dispatch(decreaseReplyDirectMessageCounter(payload));
            } else if (tab === "thread") {
              dispatch(decreaseSpaceMessageCounter(payload));
            } else if (tab === "team") {
              dispatch(decreaseSpaceMessageCounter(payload));
            }
          }
        }
      } catch (error) {
        console.error("Error posting message:", error);
      }
    };

    const handleEdit = () => {
      setIsEditing(true);
    };

    const handleSaveEdit = async (newMessage) => {
      try {
        const payload = {};
        if (tab === "dm" && !msg.replyId) {
          payload.messageId = msg.directMessageId || msg.messageId;
          payload.isDirectMessage = true;
        } else if (msg.replyId) {
          payload.messageId = msg.replyId;
          payload.isReplyMessage = true;
        } else if (msg.messageId) {
          payload.messageId = msg.messageId;
          payload.isThreadMessage = true;
        }
        payload.newMessage = newMessage;

        const response = await apiClient().post("edit-message", payload);
        if (response.status === 200) {
          if (payload.isDirectMessage) {
            dispatch(
              updateDirectMessage({
                directMessageId: payload.messageId,
                message: newMessage,
                is_edited: true,
              })
            );
          } else if (payload.isThreadMessage) {
            dispatch(
              updateMessage({
                messageId: payload.messageId,
                message: newMessage,
                is_edited: true,
              })
            );
          } else if (payload.isReplyMessage) {
            dispatch(
              updateReplyMessage({
                replyId: payload.messageId,
                message: newMessage,
                is_edited: true,
              })
            );
          }
          setIsEditing(false);
          toast.success("Message updated successfully");
        }
      } catch (error) {
        console.error("Error updating message:", error);
        toast.error("Failed to update message");
      }
    };
    return (
      <>
        {(!msg?.isAiChat ||
          (msg?.isAiChat && msg?.answer?.answers?.length > 0) ||
          (msg?.isAiChat && msg?.answer.answer)) && (
          <div
            className={`my-1 rounded transition-all duration-300 ease-in-out w-full`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="flex items-center justify-between relative w-full">
              <div className="w-full">
                <div className={`${!isReplying && "xl:px-5"} md:px-2 py-1`}>
                  <div className="flex items-start ">
                    {renderAvatar()}
                    <p className="text-[15px] font-semibold capitalize text-[#1D1C1D]  leading-[20.75px] ">
                      {getSenderName()}
                      <span className="text-[#616061] font-medium ml-2 text-[12px] leading-[18.16px]">
                        {format(new Date(msg?.createdAt), "h:mm")}
                      </span>
                    </p>
                  </div>
                  <div>{renderMessage()}</div>
                  {msg?.hasReplies && isDisplayReplies && (
                    <div
                      className={`relative flex items-center justify-between ml-12 md:w-[50%] w-[calc(100% - 3rem)] p-1 pl-3 rounded-md cursor-pointer transition-colors duration-200 ${
                        isHovered ? "bg-gray-100" : ""
                      }`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onClick={() => {
                        replyInThread(
                          tab === "dm" ? msg?.directMessageId : msg?.messageId
                        );
                      }}
                    >
                      <div className="flex item-center">
                        <div className="flex">
                          {msg?.profile?.map((profileUrl, index) => (
                            <img
                              src={profileUrl}
                              key={index}
                              className="-ml-2 first:ml-0 h-6 w-6 rounded-full"
                              alt={`Profile ${index}`}
                            />
                          ))}
                          {/* {msg?.profile?.map((pics, index) => {
                          if (
                            pics.is_profile_picture === true
                          ) {
                            return (
                              <img
                                src={pics.profilepicture}
                                key={index}
                                className="-ml-2 first:ml-0  h-6 w-6 rounded-full"
                              />
                            );
                          } else {
                            return <Avatar
                              name={pics?.name.split("")[0]}
                              size="25"
                              round={true}
                              className="-ml-2 first:ml-0 border-1"
                              textSizeRatio={2}
                              fgColor="#1D1D1F"
                            />
                          }

                        })} */}
                        </div>
                        <p className="ms-2 text-blue-500 font-bold">
                          {msg?.replyCounter}{" "}
                          {msg?.replyCounter.length === 1 ? "reply" : "replies"}
                        </p>
                        <p className="ml-2">
                          {isHovered
                            ? "View thread"
                            : `Last reply ${moment(
                                msg?.lastRepliedAt
                              ).fromNow()}`}
                        </p>
                      </div>
                      <div className={`${isHovered ? "block" : "hidden"}`}>
                        <p className="items-end">
                          <Icon
                            icon="ic:twotone-greater-than"
                            width="1.2rem"
                            height="1.2rem"
                          />
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="flex px-[50px] items-center pt-1">
                    {Object.entries(emojiCountMap).map(
                      ([emoji, count], index) => (
                        <div
                          key={index}
                          className="bg-gray-100 me-2 rounded-full"
                        >
                          <div className="rounded-md flex items-start justify-center">
                            <p className="cursor-pointer flex p-1 items-center justify-center">
                              <div onClick={() => handleEmojiSelect(emoji)}>
                                {emoji}
                              </div>
                              <p>
                                {count > 1 && (
                                  <span className="text-[10px] font-semibold mb-[-25px] ms-2">
                                    {count}
                                  </span>
                                )}
                              </p>
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="absolute top-1/2 translate-y-[-50%]  right-[1%]">
                  {isHovered && !isEditing && (
                    <div className="transition-all duration-300 ease-in-out bg-gray-200 p-1 flex rounded items-center">
                      <div className="flex items-center">
                        {/* <button className="bg-borderColor p-1.5 rounded-full mr-2 text-lendingButton hover:bg-gray-200 transition-colors duration-200"
                        style={{ color: organization?.primaryColor }}
                      >
                        <Icon
                          icon="heroicons:clipboard-document-check"
                          className="w-4 h-4"
                        />
                      </button> */}
                        {user?.userId === msg?.senderId && (
                          <button
                            className="bg-borderColor p-1 rounded-full mx-1 text-textPrimary hover:bg-gray-200 transition-colors duration-200"
                            onClick={handleEdit}
                          >
                            <MdEdit />
                          </button>
                        )}
                        {isDisplayReplies && (
                          <button
                            className="bg-borderColor p-1 rounded-full mx-1 text-textPrimary hover:bg-gray-200 transition-colors duration-200"
                            onClick={() => {
                              replyInThread(
                                tab === "dm"
                                  ? msg?.directMessageId
                                  : msg?.messageId
                              );
                            }}
                          >
                            <Icon icon="bi:chat-text" className="w-4 h-4" />
                          </button>
                        )}
                        <button
                          onClick={() => {
                            if (tab === "dm") {
                              handleEmojiButtonClick(msg?.directMessageId);
                            } else {
                              handleEmojiButtonClick(msg?.messageId);
                            }
                          }}
                          className="bg-borderColor p-1 rounded-full mx-1 text-textPrimary hover:bg-gray-200 transition-colors duration-200"
                        >
                          <Icon
                            icon="fluent:emoji-add-24-regular"
                            className="w-4 h-4"
                          />
                        </button>
                        {user?.userId === msg?.senderId && (
                          <button
                            className="bg-borderColor p-1 mx-1 rounded-full text-textPrimary hover:bg-gray-200 transition-colors duration-200"
                            onClick={handleMessageDelete}
                          >
                            <MdDelete />
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  {isEmojiModalOpen && (
                    <div className="modal">
                      {/* Render emoji picker here */}
                      <EmojiPicker onSelect={handleEmojiSelect} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <Modal
          showModal={deleteModalOpen}
          setShowModal={setDeleteModalOpen}
          confirmAction={handleConfirmDelete}
          label={"Delete Message"}
          description={
            "Are you sure you want to delete this message? This action cannot be undone and the thread will be permanently removed from your account."
          }
          confirmText={"Confirm"}
          cancelText={"Cancel"}
        />
      </>
    );
  }
);

const DateHeader = ({ date }) => {
  const today = new Date();
  let displayText;

  if (isToday(date)) {
    displayText = 'Today';
  } else if (isYesterday(date)) {
    displayText = 'Yesterday';
  } else if (isSameWeek(date, today)) {
    displayText = format(date, 'EEEE, MMMM do');
  } else if (isSameYear(date, today)) {
    displayText = format(date, 'MMMM do');
  } else {
    displayText = format(date, 'MMMM do, yyyy');
  }

  return (
    <div className="sticky top-0 z-[11] bg-white">
      <div className="flex items-center py-2">
        <div className="flex-grow h-[1px] bg-gray-200"></div>
        <div className="px-4">
          <div className="inline-block px-4 py-1 rounded-full text-sm font-medium bg-gray-100 cursor-default">
            {displayText}
          </div>
        </div>
        <div className="flex-grow h-[1px] bg-gray-200"></div>
      </div>
    </div>
  );
};

export const MessageList = memo(({
  messages,
  handleRelatedQueryClickReplay,
  handleRelatedQueryClick,
  organization,
  isReplying,
  setIsReplying,
  tab,
  isDisplayReplies = true,
}) => {
  const groupedMessages = messages.reduce((groups, msg) => {
    const date = new Date(msg.createdAt);
    const dateStr = format(date, 'yyyy-MM-dd');
    
    if (!groups[dateStr]) {
      groups[dateStr] = [];
    }
    groups[dateStr].push(msg);
    return groups;
  }, {});

  const sortedDates = Object.entries(groupedMessages)
    .sort((a, b) => new Date(a[0]) - new Date(b[0]));

  return (
    <>
      {sortedDates.map(([dateStr, msgs]) => (
        <div key={dateStr} className="relative">
          <DateHeader date={new Date(dateStr)} />
          <div className="space-y-1">
            {msgs
              .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((msg, index) => (
                <MessageItem
                  key={index}
                  msg={msg}
                  handleRelatedQueryClick={handleRelatedQueryClick}
                  handleRelatedQueryClickReplay={handleRelatedQueryClickReplay}
                  organization={organization}
                  isReplying={isReplying}
                  setIsReplying={setIsReplying}
                  isDisplayReplies={isDisplayReplies}
                  tab={tab}
                />
              ))}
          </div>
        </div>
      ))}
    </>
  );
});

export const EmptyState = () => (
  <div className="flex flex-col items-center justify-center h-full text-gray-500">
    <Icon icon="carbon:chat" width="64" height="64" />
    <h2 className="mt-4 text-xl font-semibold">No messages yet</h2>
    <p className="mt-2">Be the first to start a conversation!</p>
  </div>
);

export const HeaderSkeleton = () => (
  <div className="bg-white shadow-sm xl:px-20 lg:px-4 md:px-2 lg:py-4 md:py-2 flex items-center">
    <div className="w-48 h-6 bg-gray-200 rounded animate-pulse"></div>
  </div>
);
export const MessageSkeleton = ({ isReplying = false }) => (
  <div
    className={`xl:m-2 ${
      isReplying ? "px-1" : "xl:px-20 lg:px-10"
    }  px-4 py-4 rounded`}
  >
    <div className="flex items-start">
      <div className="w-10 h-10 bg-gray-200 rounded-full mr-2 animate-pulse"></div>
      <div className="flex-1">
        <div className="w-32 h-4 bg-gray-200 rounded mb-2 animate-pulse"></div>
        <div className="w-full h-4 bg-gray-200 rounded mb-1 animate-pulse"></div>
        <div className="w-2/3 h-4 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </div>
  </div>
);
