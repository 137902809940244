import { Icon } from "@iconify/react/dist/iconify.js";
import useAuth from "../../utils/useAuth";
import apiClient from "../../utils/axiosInstance";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  addTeam,
  removeTeamMember,
  updateTeamMemberRole,
} from "../../store/teamSlice";
import { useState } from "react";
import Modal from "../Modal";
import SpinnerLoading from "../SpinnerLoading";
import { useSocket } from "../../context/SocketProvider";
function AllMembersModal({
  isAnotherspace,
  setIsAnotherspace,
  teamId,
  team,
  members,
  showAllMembers,
  setShowAllMembers,
  setInviteModule,
}) {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const {socket} = useSocket();
  const [showModal, setShowModal] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);

  const addMember = () => {
    setShowAllMembers(false);
    setInviteModule(true);
  };

  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  const isAdmin =
    members.find((member) => member.email === user?.email)?.role === "admin";

  const handleRoleChange = async (memberId, newRole) => {
    try {
      await apiClient().put(`/update-teamMember-role/${memberId}/${teamId}`, {
        role: newRole,
      });
      dispatch(updateTeamMemberRole({ teamId, memberId, role: newRole }));
    } catch (error) {
      console.error("Error updating role:", error);
    }
  };

  const handleJoinSpace = () => {
    setShowJoinModal(true);
  };

  const confirmJoinSpace = async () => {
    try {
      setIsLoading(true);
      const response = await apiClient().post(`/self-joined-public-space`, {
        spaceId: teamId,
      });

      const newMember = response.data.member;

      const updatedTeam = {
        ...team,
        members: [...team.members, newMember],
      };

      dispatch(addTeam(updatedTeam));

      setIsAnotherspace(false);
    } catch (error) {
      console.error("Error joining space:", error);
    } finally {
      setIsLoading(false);
      setShowJoinModal(false);
    }
  };

  const handleRemoveMember = (memberId) => {
    setSelectedMemberId(memberId);
    setShowModal(true);
  };

  const confirmRemoveMember = async () => {
    try {
      setIsLoading(true);
      await apiClient().delete(
        `/remove-user-to-team/${selectedMemberId}/${teamId}`
      );
      const socketData = {
        member: selectedMemberId,
        teamId: teamId,
        organizationId: organizationState[0]?.organizationId,
      }
      socket.emit("member_leave_team", socketData);
      dispatch(removeTeamMember({ teamId, memberId: selectedMemberId }));
    } catch (error) {
      console.log("Error removing member:", error);
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SpinnerLoading isLoading={true} />}
      {showAllMembers && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl w-[350px] md:w-[600px]">
            <div className="my-6">
              <div className="flex items-center justify-between mx-4">
                <h2 className="text-2xl font-bold  ">All Members</h2>
                <button
                  onClick={() => {
                    setShowAllMembers(false);
                  }}
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <Icon icon="iconamoon:close" width="1.5em" height="1.5em" />
                </button>
              </div>
              <div className="max-h-96 overflow-y-auto mx-2">
                {isAnotherspace ? (
                  <div
                    className="flex items-center px-2 py-3 hover:bg-gray-100 cursor-pointer"
                    onClick={handleJoinSpace}
                  >
                    <div className="flex items-center">
                      <div className="w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold">
                        <Icon
                          icon="mdi:account-group"
                          fontSize={30}
                          style={{ color: "black" }}
                        />
                      </div>
                    </div>
                    <span className="text-md text-gray-500 ml-2">
                      Join Space
                    </span>
                  </div>
                ) : (
                  <div
                    className="flex items-center px-2 py-3 hover:bg-gray-100 cursor-pointer"
                    onClick={addMember}
                  >
                    <div className="flex items-center">
                      <div className="w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold">
                        <Icon
                          icon="icon-park-outline:people-plus-one"
                          fontSize={30}
                          style={{ color: "black" }}
                        />
                      </div>
                    </div>
                    <span className="text-md text-gray-500 ml-2">
                      Add Member
                    </span>
                  </div>
                )}
                {members.map((member) => {
                  return (
                    <div
                      key={member.email}
                      className="flex items-center justify-between px-2 py-3  hover:bg-gray-100 cursor-pointer"
                    >
                      <div className="flex items-center justify-between">
                        <div className="-ml-2 first:ml-0 relative">
                          <div
                            className="w-8 h-8 rounded-full  flex items-center justify-center text-white font-semibold"
                            style={{
                              backgroundColor:
                                organizationState[0]?.primaryColor,
                            }}
                          >
                            {member?.userPictureUrl === null ? (
                              // <Icon
                              //   icon="heroicons:user-circle"
                              //   width="1.5rem"
                              //   height="1.5rem"
                              //   className="text-pricingText"
                              //   style={{ color: "white" }}
                              // />
                              member.email[0].toUpperCase()
                            ) : (
                              <img
                                src={member?.userPictureUrl}
                                className="rounded-full w-full h-full object-cover"
                              />
                            )}
                          </div>
                          <div
                            className={`absolute top-0 right-0 w-3 h-3 rounded-full border-2 border-white ${
                              member.user_status === "active"
                                ? "bg-green-500"
                                : "bg-gray-400"
                            }`}
                          ></div>
                        </div>
                        <span className="ml-2 text-sm font-normal">
                          {member.email}
                        </span>
                      </div>
                      <div className="flex items-center justify-center">
                        {isAdmin && member.email !== user.email ? (
                          <select
                            value={member.role}
                            onChange={(e) =>
                              handleRoleChange(member.email, e.target.value)
                            }
                            className="border-2 rounded p-1"
                            style={{
                              borderColor: organizationState[0]?.primaryColor,
                            }}
                          >
                            <option value="admin">Admin</option>
                            <option value="member">Member</option>
                          </select>
                        ) : (
                          <span>{member.role}</span>
                        )}
                      </div>
                      {isAdmin && member.email !== user.email && (
                        <div>
                          <span className="text-sm text-gray-500">
                            <button
                              onClick={() => handleRemoveMember(member.email)}
                              className="p-2 text-md hover:underline "
                              style={{
                                color: organizationState[0]?.secondaryColor,
                              }}
                            >
                              Remove
                            </button>
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {isAdmin && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          confirmAction={confirmRemoveMember}
          label={"Remove Member"}
          description={`Are you sure you want to remove ${selectedMemberId} from the organization?`}
          confirmText={"Confirm"}
          cancelText={"Cancel"}
        />
      )}
      <Modal
        showModal={showJoinModal}
        setShowModal={setShowJoinModal}
        confirmAction={confirmJoinSpace}
        label={"Join Space"}
        description={`Are you sure you want to join this space?`}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
      />
    </>
  );
}

export default AllMembersModal;
