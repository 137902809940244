import React, { useState, useEffect } from 'react';
import pinConeImage from "../../assets/pinecone.png";
import { useDispatch, useSelector } from 'react-redux';
import apiClient from '../../utils/axiosInstance';
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FiSave } from "react-icons/fi";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { toggleAiSetting } from '../../store/organization';
import { updateKey } from '../../store/aiModelSlice';
import { encryptKey, decryptKey } from '../../utils/static';
import { toast } from 'react-toastify';

const Pinecone = () => {
    const dispatch = useDispatch();
    const { modelState, userDetails, organizationState } = useSelector((state) => ({
        modelState: state.aiModels.aiModels,
        userDetails: state.auth.userDetails,
        organizationState: state.organization.organization
    }));

    const [editAPI, setEditAPI] = useState(false);
    const [viewKey, setViewKey] = useState(false);
    const [apiKey, setApiKey] = useState("");
    const [pineConeProvider, setPineConeProvider] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const pcProvider = modelState?.providers?.find(provider => provider.provider_name === "pinecone");
        setApiKey(decryptKey(pcProvider?.provider_key));
        setPineConeProvider(pcProvider);
    }, [modelState]);

    const removeApi = async (providerId) => {
        try {
            setIsLoading(true);
            await apiClient().put(`/provider/${providerId}`, {
                providerKey: ""
            });
            const { data } = await apiClient().post("/toggle-ai-setting", { settingName: "isDocumentSearchUsed" });
            dispatch(toggleAiSetting({ settingName: "isDocumentSearchUsed", value: data?.setting?.isDocumentSearchUsed }));
            dispatch(updateKey({ providerId: providerId, key: null }));
            setApiKey("");
        } catch (error) {
            toast.error(`Error updating provider key: ${error}`);
        } finally {
            setIsLoading(false);
            setEditAPI(false);
        }
    };

    const submitApi = async (providerId) => {
        if (isLoading) return;
        if (apiKey === "" || apiKey === null) {
            toast.error("Provider Key Should not be empty");
            return;
        }

        try {
            setIsLoading(true);
            const encryptedKey = encryptKey(apiKey);
            if (!encryptedKey) {
                throw new Error("Encryption failed.");
            }
            await apiClient().put(`/provider/${providerId}`, {
                providerKey: encryptedKey
            });
            dispatch(updateKey({ providerId: providerId, key: encryptedKey }));
        } catch (error) {
            toast.error(`Error updating provider key: ${error}`);
        } finally {
            setIsLoading(false);
            setEditAPI(false);
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='w-full my-2 px-5'>

            <div className='bg-slate-100'>
                <img src={pinConeImage}
                    alt={"Pinecone"}
                    className="w-8 h-8 rounded-md"
                />
            </div>
            <div className='w-[90%] flex flex-col space-y-4'>
                {editAPI || !pineConeProvider?.provider_key ? <>
                    <div className="flex items-center h-[37px] justify-between w-[100%] py-2 rounded-md">
                        <input
                            type='text'
                            className='rounded-md w-[70%] h-[37px] px-3 py-2 border border-gray-300 focus:outline-none'
                            value={apiKey}
                            onChange={(e) => {
                                setApiKey(e.target.value);
                            }}
                            placeholder={`Enter the Pinecone API Key`} />
                        {(!apiKey && pineConeProvider?.provider_key) ? <button
                            className='text-white px-4 py-2 h-[37px] w-[24%] rounded-md flex items-center justify-center'
                            style={{ backgroundColor: organizationState[0]?.primaryColor }}
                            disabled={isLoading}
                            onClick={() => {
                                removeApi(pineConeProvider?.modelId);
                            }}>
                            {isLoading ? <span className="loader"></span> : <><RiDeleteBin6Fill />&nbsp;Remove</>}
                        </button> : <button
                            className='text-white px-4 py-2 h-[37px] w-[24%] rounded-md flex items-center justify-center'
                            style={{ backgroundColor: !apiKey ? "#aaa" : organizationState[0]?.primaryColor }}
                            disabled={isLoading || !apiKey}
                            onClick={() => {
                                submitApi(pineConeProvider?.modelId);
                            }}>
                            {isLoading ? <span className="loader"></span> : <><FiSave />&nbsp;Save</>}
                        </button>}
                    </div>

                    

                </> : <>
                    <div className="flex items-center h-[37px] justify-between w-[100%] px-3 py-2 rounded-md border border-gray-300">
                        {viewKey ? <>
                            <span className="flex items-center text-red-500 w-[6%] cursor-pointer">
                                <FaLockOpen onClick={() => setViewKey(!viewKey)} />
                            </span>
                            <span className="text-gray-600 w-[70%]" style={{ textWrap: "nowrap", overflow: "auto" }}>{decryptKey(pineConeProvider?.provider_key)}</span>
                        </> : <>
                            <span className="flex items-center w-[6%] cursor-pointer">
                                <FaLock onClick={() => setViewKey(!viewKey)} />
                            </span>
                            <span className="text-gray-600 w-[70%] relative top-1" style={{ textWrap: "nowrap", overflow: "auto" }}>**********************************</span>
                        </>}

                        <button
                            className="text-blue-500 text-sm w-[24%]"
                            onClick={() => {
                                setEditAPI(true);
                            }}
                        >
                            Change Key
                        </button>
                    </div>
                </>}
            </div>
        </div>
    );
};

export default Pinecone;
