import { Icon } from "@iconify/react/dist/iconify.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { dashboardActiveTab } from "../store/sidebarSlice";
import { useState, useEffect } from "react";

const sidebar = [
  {
    name: "Home",
    path: "/dashboard",
    icon: "heroicons:home",
  },
  {
    name: "Threads",
    path: "/answer-search",
    icon: "heroicons:chat-bubble-left-ellipsis",
  },
  // {
  //   name: "Starred",
  //   icon: "heroicons:star",
  // },
  {
    name: "DMs",
    path: "/dm",
    icon: "heroicons:chat-bubble-left-right",
  },
  {
    name: "Space",
    path: "/teams",
    icon: "heroicons:user-group",
  },
  {
    name: "Files",
    path: "/folder",
    icon: "heroicons:document-duplicate",
  },
];
function DashboardSidebar() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabType = queryParams.get("tabType");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const { dashboardActiveTabState, organizationState, userDetailsState } =
    useAppSelector((state) => ({
      dashboardActiveTabState: state.sidebar.dashboardActiveTab,
      organizationState: state.organization.organization,
      userDetailsState: state.auth.userDetails,
    }));

  useEffect(() => {
    const itemIndex = sidebar.findIndex(
      (item) => item.path === location.pathname
    );
    if (tabType && tabType === "dashboard") {
      dispatch(dashboardActiveTab(sidebar[0].name));
    } else if (itemIndex !== -1) {
      dispatch(dashboardActiveTab(sidebar[itemIndex].name));
    } else {
      if (location.pathname === "/thread") {
        dispatch(dashboardActiveTab(sidebar[1].name));
      } else if (location.pathname === "/team") {
        dispatch(dashboardActiveTab(sidebar[3].name));
      } else if (location.pathname === "/chat-history") {
        dispatch(dashboardActiveTab(sidebar[1].name));
      } else {
        dispatch(dashboardActiveTab(sidebar[0].name));
      }
    }
  }, [location.pathname]);

  const handleNavigation = (item) => {
    dispatch(dashboardActiveTab(item.name));
    if (
      ["Home", "Files", "Threads", "DMs", "Space", "Starred"].includes(
        item.name
      )
    ) {
      navigate(item.path, {
        state: {
          isFirstTime: item.name === "Threads" || item.name === "Starred" ? true : false,
        }
      });
    }
  };

  return (
    <>
      <div
        className={`dashboard-sidebar flex  md:flex-col justify-between header-height md:w-20 px-2 py-3 mypadding`}
        style={{ backgroundColor: organizationState[0]?.primaryColor }}
      >
        <div className="dashboard-icon-container flex md:flex-col md:items-center">
          {sidebar.map((item, index) => {
            return (
              <div
                key={index}
                className={`relative flex w-full items-center justify-center rounded-md p-2 mb-2`}
                onClick={() => handleNavigation(item)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                style={{
                  backgroundColor:
                    dashboardActiveTabState === item.name ||
                    hoveredIndex === index
                      ? organizationState[0]?.secondaryColor
                      : "transparent",
                  transition: "background-color 0.3s",
                }}
              >
                <button className="text-white flex items-center flex-col justify-center">
                  <Icon icon={item.icon} width="1.5em" height="1.5em" />
                  <p className="text-xs font-normal text-center">{item.name}</p>
                </button>
              </div>
            );
          })}
        </div>
        <div className="md:flex hidden md:items-center md:justify-center md:rounded-md md:p-2">
          <button
            className="text-white flex items-center flex-col justify-center"
            onClick={() => navigate("/dashboard")}
          >
            <Icon icon="heroicons:pencil-square" width="1.8em" height="1.8em" />
          </button>
        </div>
      </div>
    </>
  );
}

export default DashboardSidebar;
