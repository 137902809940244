import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import cospacelogo from "../../assets/logo-cospace.png";
import { jwtDecode } from "jwt-decode";

function Header({ toPricing }) {

  const navigate = useNavigate();
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);
  const redirectToSignUp = () => {
    navigate("/signup");
  };

  const redirectToLogin = () => {
    navigate("/signin", {
      state: {
        toLogin: true,
      },
    });
  };

  const scrollToPricing = () => {
    document
      .getElementById("pricing-table")
      .scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (toPricing) {
      scrollToPricing();
    }
  }, [toPricing]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    let decodedToken = "";

    if (token) {
      decodedToken = jwtDecode(token);
      if (decodedToken?.userId) {
        setLoggedIn(true);
      }else{
        localStorage.removeItem("authToken");
        setLoggedIn(false);
      }
    }else{
      setLoggedIn(false);
    }
  }, []);

  return (
    <nav className="bg-white border-gray-200">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4" style={{ height: "70px" }}>
        <a href="/" className="flex items-center space-x-3">
          <img src={cospacelogo} className="md:h-6 h-6" alt="cospace Logo" />
        </a>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto"
          id="navbar-cta"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white ">
            <li onClick={() => navigate("/")}>
              <a
                href="#"
                className="block py-2 px-3 md:p-0 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700"
                aria-current="page"
              >
                About
              </a>
            </li>
            <li onClick={() => navigate("/")}>
              <a
                href="#features"
                className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700"
              >
                Features
              </a>
            </li>
            <li onClick={() => navigate("/")}>
              <a
                href="#pricing"
                className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 "
              >
                Pricing
              </a>
            </li>
          </ul>
        </div>
        <div className="flex space-x-3 md:space-x-0">
          <div className="hidden md:block">
            {loggedIn ? <>
              <button
                type="button"
                onClick={() => navigate("/dashboard")}
                className={`signBtn active`}
              >
                Dashboard
              </button>
            </> : <>
              <button
                type="button"
                onClick={redirectToLogin}
                className={`signBtn ${location.pathname === "/signin" ? "active" : ""}`}
              >
                Sign In
              </button>
              <button
                type="button"
                onClick={redirectToSignUp}
                className={`signBtn ${location.pathname === "/signup" ? "active" : ""}`}
              >
                Sign Up
              </button>
            </>}
          </div>
          <button
            data-collapse-toggle="navbar-cta"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-cta"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Header;
