import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";

const initialState = {
    promptsData: [],
    yourPromts:[],
    totalItems: 0,
    totalOrgItems: 0,
    promptLoading: "succeeded",
    orgPromptLoading: "succeeded",
    promptError: null,
    orgPromptError: null,
    offset: 0,
    orgOffset: 0,
    hasMore: true,
    orgHasMore: true,
    limit: 10,
};
export const getPrompts = createAsyncThunk(
    "getPrompts",
    async ({search = "", currentOffset = 0, currLimit = 10}) => {
        try {
            const response = await apiClient().post('/prompt', {
                limit: currLimit,
                offset: currentOffset,
                prompt_query: search,
            });
            if (response.status === 200) {
                return {
                    items: response.data.items,
                    total_items: response.data.total_items,
                    offset: currentOffset + currLimit,
                    currentOffset: currentOffset,
                };
            }
        } catch (error) {
            console.log("error in prompt slice:", error);
            throw error;
        }
    }
);

export const getAllOrgPrompts = createAsyncThunk(
    "getAllOrgPrompts",
    async ({search = "", currentOffset = 0, currLimit = 10}) => {
        try {
            const response = await apiClient().post('/org_prompt', {
                limit: currLimit,
                offset: currentOffset,
                organizationId: search,
            });
            if (response.status === 200) {
                return {
                    items: response.data.items,
                    total_items: response.data.total_items,
                    offset: currentOffset + currLimit,
                    currentOffset: currentOffset,
                };
            }
        } catch (error) {
            console.log("error in prompt slice:", error);
            throw error;
        }
    }
);

export const promptSlice = createSlice({
    name: "prompt",
    initialState,
    reducers: {
        setPromptState: (state, action) => {
            state.promptsData = action.payload;
        },
        addtoprompts: (state, action) => {
            state.yourPromts.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPrompts.fulfilled, (state, action) => {
                state.promptLoading = "succeeded";
                state.promptsData = action.payload.currentOffset === 0 
                ? action.payload.items 
                : [...state.promptsData, ...action.payload.items];
                state.totalItems = action.payload.total_items;
                state.offset = action.payload.offset;
                state.hasMore = action.payload.items.length >= state.limit;
            })
            .addCase(getPrompts.pending, (state, action) => {
                state.promptLoading = "loading";
            })
            .addCase(getPrompts.rejected, (state, action) => {
                state.promptLoading = "failed";
            })
            .addCase(getAllOrgPrompts.fulfilled, (state, action) => {
                state.orgPromptLoading = "succeeded";
                state.yourPromts = action.payload.currentOffset === 0 
                ? action.payload.items 
                : [...state.yourPromts, ...action.payload.items];
                state.totalOrgItems = action.payload.total_items;
                state.orgOffset = action.payload.offset;
                state.orgHasMore = action.payload.items.length >= state.limit;
            })
            .addCase(getAllOrgPrompts.pending, (state, action) => {
                state.orgPromptLoading = "loading";
            })
            .addCase(getAllOrgPrompts.rejected, (state, action) => {
                state.orgPromptLoading = "failed";
            });
    },
});

export const { setPromptState,addtoprompts } = promptSlice.actions;
export const promptReducer = promptSlice.reducer;
