import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";

export const getThreadChats = createAsyncThunk("getThreadChats", async ({ threadId }) => {
  const payload = {
    threadId
  };
  try {
    const response = await apiClient().post(`/thread-chats`, payload);
    return response.data.chats;
  } catch (error) {
    toast.error(`${error}`);
    throw error;
  }
}
);

export const getDirectMessages = createAsyncThunk("getDirectMessages", async ({ senderId, receiverId }) => {
  try {
    const response = await apiClient().get(`/direct-messages/${senderId}/${receiverId}`);
    return response.data.messages;
  } catch (error) {
    toast.error(`${error}`);
    throw error;
  }
}
);


export const getMessageReplies = createAsyncThunk("getMessageReplies", async (data) => {
  const { messageId, tab } = data;
  try {
    const response = await apiClient().post("/replies", {
      messageId,
      tab
    });
    return response.data.message;
  } catch (error) {
    console.log("error in chat slice:", error);
    toast.error(`${error}`);
    throw error;
  }
}
);

export const thread = createSlice({
  name: "Thread",
  initialState: {
    threadChats: [],
    threads: [],
    directMessages: [],
    threadStatus: null,
    replyMessage: {},
    replyMessageStatus: null,
    fetchDms: true,
  },
  reducers: {
    addChat: (state, action) => {
      state.threadChats = [...state.threadChats, action.payload];
    },
    addReplyMessage: (state, action) => {
      state.replyMessage.replies.push(action.payload);
    },
    resetChat: (state) => {
      state.threadChats = [];
    },
    updateDirectMessage: (state, action) => {
      state.directMessages = state.directMessages.map((chat) => {
        if (chat.directMessageId === action.payload.directMessageId) {
          return {
            ...chat,
            answer: action.payload.answer || chat.answer,
            is_edited: action.payload.is_edited ?? chat.is_edited,
            message: action.payload.message ?? chat.message,
          };
        }
        return chat;
      }
      );
    },
    updateDirectMessageCounter: (state, action) => {
      state.directMessages = state.directMessages.map((chat) => {
        const { messageId, profile } = action.payload;
        if (chat.directMessageId === messageId) {
          const currentReplyCounter = Number(chat.replyCounter);
          return {
            ...chat,
            hasReplies: true,
            lastRepliedAt: new Date().toISOString(),
            profile: profile,
            replyCounter: isNaN(currentReplyCounter) ? 1 : currentReplyCounter + 1,
          };
        }
        return chat
      });
    },
    addemojiinDirectMessage: (state, action) => {
      const { messageId, emojis } = action.payload;
      state.directMessages = state.directMessages.map((chat) => {
        if (chat.directMessageId === messageId) {
          return {
            ...chat,
            emojis: emojis
          };
        }
        return chat;
      });
    },

    removeDirectMessage: (state, action) => {
      state.directMessages = state.directMessages.filter((chat) => chat.directMessageId !== action.payload);
    },
    updateMessage: (state, action) => {
      state.threadChats = state.threadChats.map((chat) => {
        if (chat.messageId === action.payload.messageId) {
          return {
            ...chat,
            answer: action.payload.answer || chat.answer,
            message: action.payload.message || chat.message,
            is_edited: action.payload.is_edited??chat.is_edited,
          };
        }
        return chat;
      }
      );
    },
    updateReplyAiMessage: (state, action) => {
      state.replyMessage.replies = state.replyMessage.replies.map((chat) => {
        if (chat.replyId === action.payload.replyId) {
          return {
            ...chat,
            answer: action.payload.answer,
          };
        }
        return chat;
      }
      );
    },
    updateSpaceMessageCounter: (state, action) => {
      state.threadChats = state.threadChats.map((chat) => {
        const { messageId, profile } = action.payload;
        if (chat.messageId === messageId) {
          const currentReplyCounter = Number(chat.replyCounter);
          return {
            ...chat,
            hasReplies: true,
            lastRepliedAt: new Date().toISOString(),
            profile: profile,
            replyCounter: isNaN(currentReplyCounter) ? 1 : currentReplyCounter + 1,
          };
        }
        return chat
      });
    },
    decreaseSpaceMessageCounter: (state, action) => {
      if(state.replyMessage.replies.length === 0){
        state.threadChats = state.threadChats.map((chat) => {
          if(chat.messageId === state.replyMessage.messageId){
            return {
              ...chat,
              hasReplies: false,
            };
          }
          return chat;
        });
      }
      state.threadChats = state.threadChats.map((chat) => {
        if(chat.messageId === state.replyMessage.messageId){
          return {
            ...chat,
            replyCounter: Number(chat.replyCounter) - 1,
          };
        }
        return chat;
      });
      state.replyMessage.replyCounter = Number(state.replyMessage.replyCounter) - 1;
    },
    addEmojiinSpace: (state, action) => {
      state.threadChats = state.threadChats.map((chat) => {
        const { messageId, emojis } = action.payload;
        if (chat.messageId === messageId) {
          return {
            ...chat,
            emojis: emojis
          };
        }
        return chat;
      })
    },
    updateThreadMessageCounter: (state, action) => {
      state.threadChats = state.threadChats.map((chat) => {
        const { messageId, profile } = action.payload;
        if (chat.messageId === messageId) {
          const currentReplyCounter = Number(chat.replyCounter);
          return {
            ...chat,
            hasReplies: true,
            lastRepliedAt: new Date().toISOString(),
            profile: profile,
            replyCounter: isNaN(currentReplyCounter) ? 1 : currentReplyCounter + 1,
          };
        }
        return chat
      });
    },
    removeChat: (state, action) => {
      state.threadChats = state.threadChats.filter((chat) => chat.messageId !== action.payload);
    },
    removeParentReplyMessage: (state, action) => {
      if(action.payload === state.replyMessage?.messageId){
        state.replyMessage = {};
      }
    },
    decreaseReplyDirectMessageCounter: (state, action) => {
      if(state.replyMessage.replies.length === 1){
        state.directMessages = state.directMessages.map((chat) => {
          if(chat.directMessageId === state.replyMessage.messageId){
            return {
              ...chat,
              hasReplies: false,
            };
          }
          return chat;
        });
      }
      state.directMessages = state.directMessages.map((chat) => {
        if(chat.directMessageId === state.replyMessage.messageId){
          return {
            ...chat,
            replyCounter: Number(chat.replyCounter) - 1,
          };
        }
        return chat;
      });
      state.replyMessage.replyCounter = Number(state.replyMessage.replyCounter) - 1;
    },
    removeReplyMessage: (state, action) => {
      state.replyMessage.replies = state.replyMessage.replies.filter((chat) => chat.replyId !== action.payload);
    },
    addNewDirectMessage: (state, action) => {
      state.directMessages = [...state.directMessages, action.payload];
    },
    resetDirectMessages: (state) => {
      state.directMessages = [];
    },
    updateReplyMessageCounter: (state, action) => {
      const { messageId, profile } = action.payload;
      if (state.replyMessage.replies.length !== 1) {
        state.threadChats = state.threadChats.map((chat) => {
          if (chat.messageId === messageId) {
            return {
              ...chat,
              replyCounter: Number(chat.replyCounter) + 1,
            };
          }
          return chat
        });
        state.replyMessage.replyCounter = Number(state.replyMessage.replyCounter) + 1;
      }
      state.threadChats = state.threadChats.map((chat) => {
        if (chat.messageId === messageId) {
          return {
            ...chat,
            profile: profile,
          };
        }
        return chat
      });
    },
    updateReplyDirectMessageCounter: (state, action) => {
      const { messageId, profilePicturesUsers } = action.payload;
      if (state.replyMessage.replies.length !== 1) {
        state.directMessages = state.directMessages.map((chat) => {
          if (chat.directMessageId === messageId) {
            return {
              ...chat,
              replyCounter: Number(chat.replyCounter) + 1,
            };
          }
          return chat
        });
        state.replyMessage.replyCounter = Number(state.replyMessage.replyCounter) + 1;
      }
      state.directMessages = state.directMessages.map((chat) => {
        if (chat.directMessageId === messageId) {
          return {
            ...chat,
            profilePicturesUsers: profilePicturesUsers,
          };
        }
        return chat
      }
      );
    },
 // In your ThreadSlice.js
updateReplyMessage: (state, action) => {
  const { replyId, message, is_edited } = action.payload;
  
  // If we're updating a specific reply's message
  if (message) {
    if (state.replyMessage?.replies) {
      state.replyMessage.replies = state.replyMessage.replies.map((reply) => {
        if (reply.replyId === replyId) {
          return {
            ...reply,
            message,
            is_edited
          };
        }
        return reply;
      });
    }
    return;
  }

  const { tab, messageId, profile } = action.payload;
  state.replyMessage.hasReplies = true;
  state.replyMessage.replyCounter = Number(state.replyMessage.replyCounter) + 1 || 1;
  state.replyMessage.lastRepliedAt = new Date().toISOString();
  if (tab === "dm") {
    state.directMessages = state.directMessages.map((chat) => {
      if (chat.directMessageId === messageId) {
        return {
          ...chat,
          hasReplies: true,
          replyCounter: 1,
          lastRepliedAt: new Date().toISOString(),
          profile: profile,
        };
      }
      return chat;
    });
  } else {
    state.threadChats = state.threadChats.map((chat) => {
      if (chat.messageId === messageId) {
        return {
          ...chat,
          hasReplies: true,
          replyCounter: 1,
          lastRepliedAt: new Date().toISOString(),
          profile: profile,
        };
      }
      return chat;
    });
      }
    },
    setFetchDms: (state, action) => {
      state.fetchDms = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThreadChats.fulfilled, (state, action) => {
        state.threadStatus = "succeeded";
        state.threadChats = action.payload;
      })
      .addCase(getThreadChats.pending, (state, action) => {
        state.threadStatus = "loading";
      })
      .addCase(getThreadChats.rejected, (state, action) => {
        state.threadStatus = "failed";
      })
      .addCase(getDirectMessages.fulfilled, (state, action) => {
        state.threadStatus = "succeeded";
        state.directMessages = action.payload;
      })
      .addCase(getDirectMessages.pending, (state, action) => {
        state.threadStatus = "loading";
      })
      .addCase(getDirectMessages.rejected, (state, action) => {
        state.threadStatus = "failed";
      })
      .addCase(getMessageReplies.fulfilled, (state, action) => {
        state.replyMessageStatus = "succeeded";
        state.replyMessage = action.payload;
      })
      .addCase(getMessageReplies.pending, (state, action) => {
        state.replyMessageStatus = "loading";
      })
      .addCase(getMessageReplies.rejected, (state, action) => {
        state.replyMessageStatus = "failed";
      });
  },
});

export const { addEmojiinSpace, addemojiinDirectMessage, updateDirectMessageCounter, updateThreadMessageCounter, updateSpaceMessageCounter,decreaseSpaceMessageCounter,decreaseReplyDirectMessageCounter, updateReplyMessage, updateReplyAiMessage, addChat, resetChat, resetDirectMessages, addNewDirectMessage, updateDirectMessage, updateMessage, removeChat, removeDirectMessage, removeParentReplyMessage, addReplyMessage, updateReplyMessageCounter, updateReplyDirectMessageCounter, removeReplyMessage, setFetchDms } = thread.actions;

export const threadReducer = thread.reducer;
