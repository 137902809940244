import React, { useEffect, useState, useRef, useCallback } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../store";
import { addtoprompts, getPrompts, setPromptState } from "../store/promptSlice";
import { debounce } from 'lodash';
import apiClient from "../utils/axiosInstance";


const PromptLibraryPopup = ({ setIsPromptPopupOpen, setSelectedPrompt }) => {
    const dispatch = useAppDispatch();
    const orgId = useAppSelector((state) => state.auth?.userDetails?.organizationId);
    const { promptsData, promptLoading, totalItems, offset, hasMore} = useAppSelector((state) => ({
        promptsData: state.prompt.promptsData,
        promptLoading: state.prompt.promptLoading,
        totalItems: state.prompt.totalItems,
        offset: state.prompt.offset,
        hasMore: state.prompt.hasMore,
    }));
    const { orgPromptLoading, totalOrgItems, orgOffset, orgHasMore, yourPromts } = useAppSelector((state) => ({
        orgPromptLoading: state.prompt.orgPromptLoading,
        totalOrgItems: state.prompt.totalOrgItems,
        orgOffset: state.prompt.orgOffset,
        orgHasMore: state.prompt.orgHasMore,
        yourPromts: state.prompt.yourPromts
    }))
    const [activeTab, setActiveTab] = useState("communityPrompts");
    const [showAddPromptForm, setShowAddPromptForm] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        tags: "",
        prompt: ""
    })
    const [error, setError] = useState(null);
    const [search, setSearch] = useState("");



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowAddPromptForm(false);
        setFormData({ title: "", description: "", tags: "", prompt: "" });
    };

    const fetchPrompts = async (searchTerm = search, currentOffset = offset) => {
        try {
            setError(null);

            if ((promptLoading !== "loading" && hasMore) || currentOffset === 0) {
                dispatch(getPrompts({ search: searchTerm, currentOffset: currentOffset, currLimit: 10 }));
            }
        } catch (err) {
            console.error("Error fetching prompts:", err);
            setError(err?.response?.data?.message || 'Failed to fetch prompts');
        }
    };

    const debouncedFetchPrompts = useCallback(
        debounce((searchTerm, currentOffset) => {
            fetchPrompts(searchTerm, currentOffset);
        }, 1500),
        []
    );

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value;
        setSearch(searchTerm);
        debouncedFetchPrompts(searchTerm, 0);
    };

    const loadMorePrompts = () => {
        fetchPrompts(search, offset);
    };

    const handleClose = () => {
        dispatch(getPrompts({ search: "", currentOffset: 0, currLimit: 10 }));
        setIsPromptPopupOpen(false);
    };

    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectToAct = (item, index) => {
        setSelectedPrompt(item);
        setIsPromptPopupOpen(false);

    }

    const handleAddPrompy = async (item) => {
        try {
            const response = await apiClient().post(`/prompt_add`, {
                promptId: item?.promptId,
                organizationId: orgId
            });
            if (response.status) {
                dispatch(addtoprompts(response.data));
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 !z-[50]">
            <div className="bg-white rounded-lg shadow-lg max-w-lg w-full " ref={popupRef}>
                <div className="p-4 border-b border-gray-200">
                    <h2 className="text-xl font-semibold text-center">Prompt Library</h2>
                    <p className="text-md text-center mt-2 text-gray-500">
                        Prompts are message templates that you can quickly fill in the chat input.
                        Some prompts come with variables.
                    </p>
                </div>

                <div className="p-4 flex items-center border-b border-gray-200">
                    <button
                        className={`flex items-center text-center py-2 mr-4 ${activeTab === "yourPrompts"
                            ? "text-blue-600 border-b-2 border-blue-600"
                            : "text-gray-500 hover:text-gray-800 hidden"
                            }`}
                        onClick={() => setActiveTab("yourPrompts")}
                    >
                        Your Prompts
                        <span className={`${activeTab === "yourPrompts" ? "bg-blue-400" : "bg-gray-400"} w-6 h-6 text-xs rounded-full p-1 text-white ml-2 flex justify-center items-center`}>
                            {totalOrgItems || 0}
                        </span>
                    </button>
                    <button
                        className={`flex items-center text-center py-2 ${activeTab === "communityPrompts"
                            ? "text-blue-600 border-b-2 border-blue-600"
                            : "text-gray-500 hover:text-gray-800"
                            }`}
                        onClick={() => setActiveTab("communityPrompts")}
                    >
                        Community Prompts
                        <span className={`${activeTab === "communityPrompts" ? "bg-blue-400" : "bg-gray-400"} w-6 h-6 text-xs rounded-full p-1 text-white ml-2 flex justify-center items-center`}>
                            {totalItems || 0}
                        </span>
                    </button>
                </div>

                <div className="p-4">
                    {activeTab === "yourPrompts" ? (
                        <div className="space-y-4">
                            <div className="space-y-2">
                                <div className="flex justify-between items-center">
                                    <input
                                        type="text"
                                        placeholder="Search your prompts"
                                        className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                                    />
                                    <button
                                        onClick={() => setShowAddPromptForm(!showAddPromptForm)}
                                        className="ml-2 px-3 py-2 text-blue-600 rounded-md text-sm hover:underline font-medium"
                                    >
                                        + Add Prompt
                                    </button>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Filter by tags"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                                />
                            </div>

                            {showAddPromptForm && (
                                <form onSubmit={handleSubmit} className="space-y-4 p-4 border border-gray-200 rounded-lg">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">Title:</label>
                                        <input
                                            type="text"
                                            name="title"
                                            placeholder="Prompt Title (e.g., 'Domain Names Inspirations')"
                                            value={formData.title}
                                            onChange={handleInputChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">Description (optional):</label>
                                        <input
                                            type="text"
                                            name="description"
                                            placeholder="e.g., A list of domain names based on topics"
                                            value={formData.description}
                                            onChange={handleInputChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">Tags (optional):</label>
                                        <input
                                            type="text"
                                            name="tags"
                                            placeholder="Enter new tag"
                                            value={formData.tags}
                                            onChange={handleInputChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Prompt:
                                            <span className="text-gray-500 text-xs ml-2">
                                                Use {"{field 1}"} {"{field 1}"} {"{or anything}"} to indicate the fill in the blank part.
                                            </span>
                                        </label>
                                        <textarea
                                            name="prompt"
                                            placeholder="Enter your prompt here..."
                                            value={formData.prompt}
                                            onChange={handleInputChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 h-24"
                                            required
                                        />
                                    </div>
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            type="button"
                                            onClick={() => setShowAddPromptForm(false)}
                                            className="px-4 py-2 text-gray-600 border border-gray-300 rounded-md hover:bg-gray-100"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
                                        >
                                            Add Prompt
                                        </button>
                                    </div>
                                </form>
                            )}

                            <div className="max-h-96 overflow-y-auto px-1">
                                {yourPromts?.map((item, index) => (
                                    <div
                                        key={item.id || index}
                                        className="mb-4 p-4 border border-gray-200 rounded-lg hover:bg-gray-50"
                                    >
                                        <div className="flex justify-between items-start">
                                            <div className="max-w-[270px]">
                                                <h3 className="font-medium text-gray-900 truncate">
                                                    {item?.act}
                                                </h3>
                                                <p className="text-sm text-gray-600 line-clamp-2">
                                                    {item?.prompt}
                                                </p>
                                            </div>

                                            <div className="flex space-x-2 ml-4">
                                                <button
                                                    className="flex items-center justify-center px-3 py-1 text-blue-600 border border-blue-600 rounded hover:bg-blue-50"
                                                    onClick={(e) => selectToAct(item, index, e)}
                                                >
                                                    <FaArrowRight className="mr-1 text-md" />
                                                    <span className="text-sm">Use</span>
                                                </button>
                                                <button
                                                    onClick={() => handleAddPrompy(item)}
                                                    className="flex items-center justify-center px-3 py-1 text-white bg-green-600 rounded hover:bg-green-700"
                                                >
                                                    <IoMdAdd className="mr-1 text-md" />
                                                    <span className="text-sm">Add</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {promptLoading === "loading" && (
                                    <div className="text-center py-4">
                                        <span className="text-gray-500">Loading...</span>
                                </div>
                            )}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <input
                                type="text"
                                placeholder="Search community prompts"
                                value={search}
                                onChange={handleSearchChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 mb-4"
                            />

                            {error && (
                                <div className="text-red-500 text-sm mb-4">
                                    {error}
                                </div>
                            )}

                            <div className="max-h-96 overflow-y-auto px-1">
                                {promptsData?.map((item, index) => (
                                    <div
                                        key={item.id || index}
                                        className="mb-4 p-4 border border-gray-200 rounded-lg hover:bg-gray-50"
                                    >
                                        <div className="flex justify-between items-start">
                                            <div className="max-w-[270px]">
                                                <h3 className="font-medium text-gray-900 truncate">
                                                    {item?.act}
                                                </h3>
                                                <p className="text-sm text-gray-600 line-clamp-2">
                                                    {item?.prompt}
                                                </p>
                                            </div>

                                            <div className="flex space-x-2 ml-4">
                                                <button
                                                    className="flex items-center justify-center px-3 py-1 text-blue-600 border border-blue-600 rounded hover:bg-blue-50"
                                                    onClick={(e) => selectToAct(item, index, e)}
                                                >
                                                    <FaArrowRight className="mr-1 text-md" />
                                                    <span className="text-sm">Use</span>
                                                </button>
                                                <button
                                                    onClick={() => handleAddPrompy(item)}
                                                    className="items-center justify-center px-3 py-1 text-white bg-green-600 rounded hover:bg-green-700 hidden"
                                                >
                                                    <IoMdAdd className="mr-1 text-md" />
                                                    <span className="text-sm">Add</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {promptLoading === "loading" && (
                                    <div className="text-center py-4">
                                        <span className="text-gray-500">Loading...</span>
                                    </div>
                                )}

                                {hasMore && (
                                    <div className="flex items-center justify-center w-full">
                                        <button
                                            className="px-4 py-2 text-blue-600 rounded-md"
                                            onClick={loadMorePrompts}
                                            disabled={promptLoading === "loading"}
                                        >
                                            {promptLoading === "loading" ? <span className="loader" style={{ border: "2px solid #1c64f2" }}></span> : "Load More"}
                                        </button>
                                    </div>
                                )}

                                {promptLoading !== "loading" && !hasMore && promptsData.length === 0 && (
                                    <div className="text-center py-4">
                                        <span className="text-gray-500">No prompts found</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {/* Footer */}
                <div className="p-4 border-t border-gray-200 flex justify-center">
                    <button
                        className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
                        onClick={() => {
                            dispatch(getPrompts({ search: "", currentOffset: 0, currLimit: 10 }));
                            setIsPromptPopupOpen(false)
                        }}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PromptLibraryPopup;