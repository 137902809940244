import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import InviteModal from "./sidebar/InviteModal";
import useAuth from "../utils/useAuth";
import { getUserDetails } from "../store/authSlice";
import debounce from "lodash/debounce";
import apiClient from "../utils/axiosInstance";
import { format } from "date-fns";
import { getOrganization } from "../store/organization";
import cospacelogo from "../assets/logo2.png";
import { getModelDetails } from "../store/aiModelSlice";
import { getPrompts, getAllOrgPrompts } from "../store/promptSlice";
import { IoMdArrowDropright } from "react-icons/io";
import CustomPricingTable from "../Components/Account/CustomPricingTable";
import { useSocket } from "../context/SocketProvider";
import { userInvitedToTeam, removeTeamMember, removeTeam } from "../store/teamSlice";
import { toast } from "react-toastify";

function DashboardHeader() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {socket} = useSocket();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const dropdownRef = useRef(null);
  const [inviteModule, setInviteModule] = useState(false);
  const [showStripeModal, setShowStripeModal] = useState(false);
  const {
    userDetail,
    userDetailsState,
    organizationState,
    organizationStatus,
  } = useAppSelector((state) => ({
    userDetail: state.auth.userDetails,
    userDetailsState: state.auth.userDetails,
    organizationStatus: state.organization.organizationStatus,
    organizationState: state.organization.organization,
  }));

  const org = organizationState[0];
  const invitedMembers = org?.members
    .filter(
      (member) => member.invitedBy === user?.userId && !member.isSubscribe
    )
    ?.map((member) => member.email);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (user?.userId && !userDetail) {
      dispatch(getUserDetails(user.userId));
    }
    dispatch(getModelDetails());
  }, [user, userDetail, dispatch]);

  useEffect(() => {
    if (organizationState?.length === 0) {
      dispatch(getOrganization());
    }
    dispatch(getPrompts({ search: "", currentOffset: 0, currLimit: 10 }));
    dispatch(getAllOrgPrompts({ search: "", currentOffset: 0, currLimit: 10 }));
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleButtonClick = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const debouncedSearch = useRef(
    debounce(async (query) => {
      if (query.length > 0) {
        setIsLoading(true);
        try {
          const response = await apiClient().post("/search", { query });
          setSearchResults(response.data.search_results || []);
        } catch (error) {
          console.error("Search error:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setSearchResults([]);
      }
    }, 300)
  ).current;

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    const socketData = {
      userId: userDetail?.userId,
      organizationId: org?.organizationId,
    }
    socket.emit("user_joined_cospace",socketData);

    socket.on("user_invited_to_team", (data) => {
      console.log("user_invited_to_team", data);
      if(userDetail?.email === data?.member?.email){
        dispatch(userInvitedToTeam({teamId: data.teamId}));
      }
    })

    socket.on("member_leave_team_", (data) => {
      if(userDetail?.email === data?.member){
        dispatch(removeTeam(data.teamId));
        console.log("location", location.pathname, queryParams.get("id"), data.teamId);
        if(location.pathname.includes("/team") && queryParams.get("id") === data.teamId){
          toast.warning("You have been removed from the team");
          navigate("/dashboard");
        }
      }else{
        dispatch(removeTeamMember({ teamId: data.teamId, memberId: data.member }));
      }
    })

    return () => {
      socket.off("user_joined_cospace");
      socket.off("user_invited_to_team");
      socket.off("member_leave_team_");
    }

  }, [socket]);



  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchResultClick = (item) => {
    switch (item.searchType) {
      case "thread":
        if (item.type === "thread") {
          navigate(`/thread?id=${item.threadId}`);
        } else {
          navigate(`/answer-search?id=${item.threadId}`);
        }
        break;
      case "team":
        navigate(`/team?id=${item.teamId}`);
        break;
      case "user":
        navigate(`/dm?id=${item.userId}`);
        break;
      default:
        console.error("Unknown search result type:", item.searchType);
    }
    setSearchTerm("");
    setSearchResults([]);
  };

  const subscriptionDate =
    userDetailsState?.subscriptionDate || new Date(org.createdAt);

  // Function to calculate remaining days
  function calculateRemainingDays(targetDate) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const diffTime = targetDate - today;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  const trialEndDate = new Date(org.createdAt);
  const trialFutureDate = trialEndDate.setDate(trialEndDate.getDate() + 7);
  const remainingDaysTrial = Math.max(calculateRemainingDays(trialEndDate), 0);

  let remainingDays;

  if (org?.FreeTrial === true) {
    remainingDays = trialFutureDate;
  }

  return (
    <>
      {organizationState?.length > 0 && (
        <div>
          <header
            className="flex items-center justify-between px-4 py-2 w-full"
            style={{ backgroundColor: organizationState[0]?.primaryColor }}
          >
            <div className="xl:min-w-[348px] lg:w-[388px] md:w-[328px] pl-5">
              {organizationStatus === "loading" ? (
                <div className="logo-container flex items-center md:w-full w-2/12"></div>
              ) : organizationState[0]?.logoS3Loc ? (
                <div className="logo-container flex items-center cursor-pointer md:w-full w-2/12 pl-5">
                  <p className="text-2xl font-semibold text-white hidden md:block">
                    <Link to={"/dashboard"}>
                      <img
                        src={organizationState[0]?.logoUrl}
                        alt="logo"
                        className="h-10 w-25 object-fit"
                      />
                    </Link>
                  </p>
                </div>
              ) : (
                <div className="logo-container flex items-center cursor-pointer md:w-full w-2/12">
                  <Link to={"/dashboard"}>
                    <img
                      src={cospacelogo}
                      alt="logo"
                      className="h-10 w-[180px] object-fit relative top-1"
                    />
                  </Link>
                </div>
              )}
            </div>

            <div className="flex items-center justify-between w-full ">
              <div className="search-container flex w-[400px] mr-[20px] justify-center">
                <div className="input-container w-full relative">
                  <input
                    type="text"
                    placeholder="Search"
                    className="pl-10 pr-4 py-2 rounded-full  w-full bg-[#FFFFFF21] text-white placeholder-white border-none focus:border-none focus:ring-0 focus:outline-none"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                  />
                  <Icon
                    icon="tabler:search"
                    width="24px"
                    height="24px"
                    className="absolute left-3 top-2.5 h-5 w-5 text-white"
                  />
                  {(isLoading || searchResults.length > 0) && (
                    <div className="absolute z-10 w-full mt-2 bg-white rounded-md shadow-lg max-h-96 overflow-y-auto">
                      {isLoading ? (
                        <div className="p-4">
                          <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                              <div className="h-2 bg-slate-200 rounded"></div>
                              <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                  <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div className="h-2 bg-slate-200 rounded"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : searchResults.length > 0 ? (
                        searchResults.map((item, index) => (
                          <div
                            key={index}
                            className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
                            onClick={() => handleSearchResultClick(item)}
                          >
                            <div className="flex items-center w-full">
                              <Icon
                                icon={
                                  item.searchType === "thread"
                                    ? "heroicons:chat-bubble-left-ellipsis"
                                    : item.searchType === "team"
                                    ? "mdi:account-group-outline"
                                    : "mdi:account-outline"
                                }
                                className="mr-3 text-gray-600 flex-shrink-0"
                                width="24"
                                height="24"
                              />
                              <div className="flex-grow truncate">
                                <p className="font-semibold truncate py-1">
                                  {item.searchType === "thread"
                                    ? `${item.chatName}`
                                    : item.searchType === "team"
                                    ? item.teamName
                                    : item.name}
                                </p>
                              </div>
                              {item.searchType === "user" && (
                                <span
                                  className={`h-3 w-3 rounded-full ${
                                    item?.status === "online"
                                      ? "bg-green-500"
                                      : "bg-gray-500"
                                  }`}
                                ></span>
                              )}
                              <p className="text-sm text-gray-600 ml-3 flex-shrink-0">
                                {format(new Date(item.createdAt), "d MMM")}
                              </p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="p-2">No results found</div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="profile-container flex md:items-center mr-[20px]">
                <div className="mr-[20px] flex items-center justify-space-between">
                 
                  {org?.FreeTrial === true && (
                    <>
                      {remainingDaysTrial > 1 ? (
                        <span className="text-md text-red-500">
                          {remainingDaysTrial} days left in your free trial
                        </span>
                      ) : remainingDaysTrial === 1 ? (
                        <span className="text-md text-red-500">
                          1 day left in your free trial
                        </span>
                      ) : remainingDaysTrial === 0 ? (
                        <span className="text-md text-red-500">
                          Today is the last day!
                        </span>
                      ) : null}
                    </>
                  )}
                  {!userDetailsState?.isSubscribe &&
                        userDetailsState?.role === "super admin" ? (
                        <>
                          <button
                            onClick={() => setShowStripeModal(true)}
                            className="text-white bg-red-600  font-medium mx-2 px-4 py-2 rounded-full flex items-center text-base active:scale-95 transition-transform"
                          >
                            Subscribe Now{" "}
                            <IoMdArrowDropright className="text-xl" />
                          </button>
                        </>
                      ) : null}
                 
                </div>
                <div className="relative inline-block" ref={dropdownRef}>
                  <div className="flex items-center justify-center overflow-hidden">
                    <button
                      onClick={toggleDropdown}
                      className="w-9 h-9 rounded-full focus:outline-none overflow-hidden"
                    >
                      {userDetail?.userPictureUrl ? (
                        <img
                          src={userDetail.userPictureUrl}
                          alt="Profile"
                          className="rounded-full w-full h-full object-cover"
                        />
                      ) : (
                        <div
                          className=" text-white text-2xl rounded-full flex items-center justify-center cursor-pointer"
                          style={{
                            backgroundColor:
                              organizationState[0]?.secondaryColor,
                          }}
                        >
                          {userDetail?.name?.charAt(0).toUpperCase()}
                        </div>
                      )}
                    </button>
                    <div />
                    {isOpen && (
                      <div className="absolute right-0 top-full z-[12] mt-3 w-48 bg-white border border-gray-200 rounded shadow-lg">
                        <ul>
                          <button
                            className="px-2 py-2 flex items-center w-48 cursor-pointer"
                            onClick={() => handleButtonClick("/account")}
                            onMouseEnter={() => setHoveredButton("account")}
                            onMouseLeave={() => setHoveredButton(null)}
                            style={{
                              backgroundColor:
                                hoveredButton === "account" &&
                                organizationState[0]?.secondaryColor,
                              transition: "background-color 0.3s",
                              color:
                                hoveredButton === "account" ? "white" : "black",
                            }}
                          >
                            <Icon
                              icon="ant-design:setting-outlined"
                              width="24px"
                              height="24px"
                              className="mr-2"
                            />
                            Account
                          </button>
                          {/* <button
                        className="px-2 py-2 flex items-center w-48 cursor-pointer"
                        onClick={() => setInviteModule(true)}
                        onMouseEnter={() => setHoveredButton('invite')}
                        onMouseLeave={() => setHoveredButton(null)}
                        style={{
                          backgroundColor: hoveredButton === 'invite' && organizationState[0]?.secondaryColor,
                          transition: 'background-color 0.3s',
                        }}
                      >
                        <Icon
                          icon="bx:user-plus"
                          width="24px"
                          height="24px"
                          className="mr-2"
                        />
                        Invite a colleague
                      </button> */}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      )}

      {showStripeModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full">
            <div className="p-4 bg-gray-100 flex justify-between items-start">
              <h2 className="text-lg ">Subscribe</h2>
              <button
                onClick={() => {
                  setShowStripeModal(false);
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <Icon icon="heroicons:x-mark" width="24" height="24" />
              </button>
            </div>
            <CustomPricingTable
              setShowStripeModal={setShowStripeModal}
              invitedMembers={invitedMembers}
              isNewInvitation={false}
              userDetails={userDetailsState}
            />
          </div>
        </div>
      )}
      <InviteModal
        inviteModule={inviteModule}
        setInviteModule={setInviteModule}
      />
    </>
  );
}

export default DashboardHeader;
