import { Icon } from "@iconify/react/dist/iconify.js";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import apiClient from "../../utils/axiosInstance";
import { useAppDispatch, useAppSelector } from "../../store";
import { addTeam, updateTeam } from "../../store/teamSlice";

function TeamModal({ setTeamModal, teamModal, initialTeam = null, setInitialTeam }) {
    const dispatch = useAppDispatch();

    const validationSchema = Yup.object({
        teamName: Yup.string()
            .required('Space name is required')
            .min(2, 'Space name must be at least 2 characters')
            .max(50, 'Space name must be at most 50 characters'),
        teamType: Yup.string()
            .oneOf(['public', 'private'], 'Invalid team type')
            .required('Space type is required'),
    });

    const { organizationState, teamState } = useAppSelector((state) => ({
        organizationState: state.organization.organization,
        teamState: state.teams.teams,
    }));


    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setSubmitting(true);
            const teamExist = teamState.find((team) => team.teamName === values.teamName);
            if(teamExist){
                toast.error("Team name already exists");
                return;
            }

            if (initialTeam) {
                await apiClient().put(`/team/${initialTeam.teamId}`, {
                    teamName: values.teamName,
                    teamType: values.teamType,
                });
                dispatch(updateTeam({
                    teamId: initialTeam.teamId,
                    teamName: values.teamName,
                    teamType: values.teamType,
                }));
            } else {
                const response = await apiClient().post(`/team`, {
                    teamName: values.teamName,
                    teamType: values.teamType,
                });
                dispatch(addTeam(response.data.team));
            }
            setTeamModal(false);
        } catch (error) {
            toast.error(initialTeam ? "Error while updating team" : "Error while creating team");
        } finally {
            setSubmitting(false);
            setInitialTeam(null);
        }
    };

    const closeModal = () => {
        setTeamModal(false);
        if (initialTeam) setInitialTeam(null);
    };

    return (
        <>
            {teamModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg overflow-hidden shadow-xl w-[350px] md:w-[450px] h-auto">
                        <div className="p-6">
                            <Formik
                                initialValues={{
                                    teamName: initialTeam ? initialTeam.teamName : '',
                                    teamType: initialTeam ? initialTeam.teamType : 'public',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form className="bg-white">
                                        <div className="flex items-center justify-between mb-6">
                                            <h2 className="text-2xl font-bold">{initialTeam ? "Update Space" : "Create Space"}</h2>
                                            <button onClick={closeModal} className="p-2 hover:bg-gray-100 rounded-full transition duration-200">
                                                <Icon icon="iconamoon:close" width="1.5em" height="1.5em" />
                                            </button>
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="teamName" className="block text-gray-700 text-left font-medium mb-2">
                                                Space Name
                                            </label>
                                            <Field
                                                id="teamName"
                                                name="teamName"
                                                type="text"
                                                className="border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                                                placeholder="Enter team name"
                                            />
                                            <ErrorMessage name="teamName" component="div" className="text-red-500 text-sm mt-1 text-start" />
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="teamType" className="block text-gray-700 text-left font-medium mb-2">
                                                Space Type
                                            </label>
                                            <Field
                                                as="select"
                                                id="teamType"
                                                name="teamType"
                                                className="border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                                            >
                                                <option value="public">Public</option>
                                                <option value="private">Private</option>
                                            </Field>
                                            <ErrorMessage name="teamType" component="div" className="text-red-500 text-sm mt-1 text-start" />
                                        </div>
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="w-full mt-4  text-white font-medium py-3 px-4 rounded-md hover:bg-opacity-90 transition duration-200"
                                            style={{ backgroundColor: organizationState[0]?.primaryColor }}
                                        >
                                            {isSubmitting ? "Processing..." : (initialTeam ? "Update Space" : "Create Space")}
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TeamModal;