import React from "react";
import { likedChat, newQuery, updateChat, updateStarredThread } from "../store/finChat";
import { Icon } from "@iconify/react";
import { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../store/index";
import { toast } from "react-toastify";
import ReportModal from "../Components/ReportModal";
import clipboardCopy from "clipboard-copy";
import apiClient from "../utils/axiosInstance";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import useAuth from "../utils/useAuth";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";
import Preview from "./Preview";
import wwwImg from "../assets/www.png";

function ChatAnswer({
  answerChat,
  setIsChatLoading,
  setIsLoading,
  threadId,
  messageId,
  setQuestion,
  index = 0,
  questions,
  answers
}) {

  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const mackDown = useRef();
  const [copy, setCopy] = useState(false);
  const [isReportModal, setIsReportModal] = useState(false);
  const [processedText, setProcessedText] = useState(answerChat?.answer.answer);
  const navigate = useNavigate()
  const { chatQueries, chats, isPlanActive } = useAppSelector(
    (state) => ({
      chatQueries: state.finChat.chatQueries,
      chats: state.finChat.chatWithLimit,
      isPlanActive: state.organization.isPlanActive
    })
  );

  const handleRelated = async (data) => {
    setQuestion(data);
    try {
      setIsChatLoading(true);
      const body = {
        action: "chat",
        query: data,
        threadId: threadId,
        createdAt: new Date().toISOString(),
        previous_queries: questions,
        previous_answers: answers
      };
      const response = await apiClient().post(`/chat`, body);
      if (
        response &&
        chatQueries?.chat?.queries?.length === 1 &&
        chats?.history?.length === 1 &&
        user?.email
      ) {
        posthog.identify(user?.email);
        posthog.capture("follow_up_chat_started", { email: user?.email });
      }
      dispatch(newQuery(response.data));
      dispatch(updateStarredThread({ threadId: response.data.threadId, key: ["updatedAt"], value: [response.data.updatedAt] }));
      dispatch(updateChat(response.data));
      setQuestion("");
    } catch (error) {
      toast.error(error);
    } finally {
      setQuestion("");
      setIsChatLoading(false);
    }
  };
  const handleLike = async (liked) => {
    if (liked === 1) {
      return;
    }
    try {
      setIsLoading(true);
      const body = {
        action: "like",
        messageId: messageId,
      };
      await apiClient().post(`/chat`, body);
      dispatch(likedChat(messageId));
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  }, [copy]);

  const handleCopyAnswer = () => {
    const answerText = answerChat?.answer?.answer
    if (answerText) {
      clipboardCopy(answerText)
        .then(() => {
          setCopy(answerChat);
        })
        .catch((error) => {
          // Handle any errors
        });
    }
  };


  useEffect(() => {
    let newText = answerChat?.answer?.answer;
    if (answerChat?.answer?.grounding_metadata?.grounding_supports?.length != 0) {
      answerChat?.answer?.grounding_metadata?.grounding_supports.forEach(support => {
        const cleanWebText = support.web_text.trim();

        const referenceIndices = support.grounding_chunk_indices.map(i => parseInt(i) + 1);
        const referenceString = `[${referenceIndices.join(',')}]`;
        if (!cleanWebText.endsWith(']')) {
          const regex = new RegExp(`${cleanWebText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}(?!\\[\\d+(?:,\\d+)*\\])`, 'g');
          newText = newText.replace(regex, `${cleanWebText} ${referenceString}`);
        }
      });

    }
    setProcessedText(newText);
  }, [answerChat]);

  const handleRefClick = (index) => {
    const refIndex = parseInt(index) - 1;
    const chunk = answerChat?.answer?.grounding_metadata?.grounding_chunks[refIndex];
    if (chunk?.web?.uri) {
      window.open(chunk.web.uri, '_blank');
    }
  };

  const processTextWithRefs = (text) => {
    if (typeof text !== 'string') return text;

    const parts = text.split(/(\[\d+(?:,\d+)*\])/).map((part, i) => {
      const match = part.match(/\[(\d+(?:,\d+)*)\]/);
      if (match) {
        const refIndices = match[1].split(',');
        return (
          <span key={i}>
            [
            {refIndices.map((index, idx) => (
              <React.Fragment key={index}>
                <button
                  onClick={() => handleRefClick(index.trim())}
                  className="text-blue-600 hover:text-blue-800 font-medium"
                >
                  {index.trim()}
                </button>
                {idx < refIndices.length - 1 && ','}
              </React.Fragment>
            ))}
            ]
          </span>
        );
      }
      return part;
    });

    return <>{parts}</>;
  };

  const getFaviconUrl = (url) => {
    try {
      const urlObj = new URL(`https://${url}`);
      return `https://www.google.com/s2/favicons?domain=${urlObj.hostname}`;
    } catch (e) {
      return null;
    }
  };


  return (
    <>
      <div className="px-4 py-2">
        {index !== 0 && <div className="flex justify-between mt-4 break-words mb-0">
          <div className="text-md xl:text-xl font-normal capitalize flex items-center justify-between w-full  text-textPrimary">
            <div className="heading-thread flex items-center">
              <div>
                <Icon icon="gridicons:menus" className="mr-2 w-6 h-6" />
              </div>
              <div>
                {answerChat?.query}
              </div>
            </div>
            <div className="close-btn md:hidden" >
              <button>
                <Icon
                  icon="carbon:close"
                  className="w-6 h-6 text-textPrimary"
                  onClick={() => navigate('/dashboard')}
                />
              </button>
            </div>
          </div>
        </div>}

        {answerChat?.answer && (
          <div className="break-words p-5 mb-5 ">
            <p className="text-textPrimary flex items-center text-xl font-medium mb-2">
              Answer
            </p>
            <div className="relative mb-3">
              <div className="text-md text-textPrimary font-normal mb-3 relative">
                <div className="mb-4 markdown-content" ref={mackDown}>
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      p: ({ children }) => (
                        <p>
                          {React.Children.map(children, child => {
                            if (typeof child === 'string') {
                              return processTextWithRefs(child);
                            }
                            return child;
                          })}
                        </p>
                      ),
                      li: ({ children }) => (
                        <li>
                          {React.Children.map(children, child => {
                            if (typeof child === 'string') {
                              return processTextWithRefs(child);
                            }
                            return child;
                          })}
                        </li>
                      ),
                      strong: ({ children }) => (
                        <strong>
                          {React.Children.map(children, child => {
                            if (typeof child === 'string') {
                              return processTextWithRefs(child);
                            }
                            return child;
                          })}
                        </strong>
                      ),
                      em: ({ children }) => (
                        <em>
                          {React.Children.map(children, child => {
                            if (typeof child === 'string') {
                              return processTextWithRefs(child);
                            }
                            return child;
                          })}
                        </em>
                      )
                    }}
                  >
                    {processedText}
                  </Markdown>
                </div>

                {answerChat?.answer?.grounding_metadata?.grounding_chunks?.length > 0 && <div className="text-sm border-t pt-4">
                  <h3 className="font-semibold mb-2">Sources:</h3>
                  <div className="ml-4 flex flex-wrap">
                    {answerChat?.answer?.grounding_metadata?.grounding_chunks?.map((citation, index) => {
                      return (<>
                        <a href={citation.web.uri} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 font-medium">
                          <div className="relative group border rounded-lg p-4 m-3 w-[280px] max-h-[86px]">
                            <div className="flex items-center mb-2">
                              <p className="mr-2 font-medium">{index + 1}.</p>
                              <img src={getFaviconUrl(citation.web.title) || wwwImg} alt="Favicon" className="w-6 h-6 mr-2" />
                              <p className="text-nowrap text-ellipsis overflow-hidden">
                                {citation.web.meta_title || citation.web.title}  
                              </p>
                            </div>
                            <p className="text-gray-600 text-sm overflow-hidden text-ellipsis text-nowrap">{citation.web.meta_description || "No description available."}</p>
                            <div className="absolute hidden group-hover:flex flex-col bg-white border shadow-lg p-2 rounded-md top-[60px] mt-2 left-[50px] min-w-[280px] max-w-[400px] max-h-[200px] z-10 overflow-auto ">
                              <p className="text-gray-800 font-semibold"> {citation.web.meta_title || citation.web.title}</p>
                              <p className="text-gray-600 text-sm">{citation.web.meta_description || "No description available."}</p>
                            </div>
                          </div>
                        </a>
                      </>)
                    })}
                  </div>
                </div>}


              </div>
            </div>
            <div className="flex items-center mt-4 justify-between">
              <div className="flex items-center mt-4">
                <button
                  onClick={handleCopyAnswer}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-1 rounded-full"
                >
                  <Icon
                    icon={copy ? "uit:check" : "icon-park-outline:clipboard"}
                    className={`text-xl mr-1 ${copy ? " text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Copy
                </button>
              </div>
              <div className="flex items-center mt-4">
                <button
                  onClick={() => {
                    handleLike(answerChat.liked);
                  }}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-0.5 rounded-full"
                >
                  <Icon
                    icon="ei:like"
                    className={`text-3xl mr-1 ${answerChat.liked === 1 ? "text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Like
                </button>
                <button
                  onClick={() => setIsReportModal(!isReportModal)}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-1 rounded-full"
                >
                  <Icon
                    icon="solar:dislike-broken"
                    className={`text-2xl mr-1 ${answerChat.report === 1 ? "text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Report
                </button>
              </div>
            </div>
          </div>
        )}

        {answerChat?.answer?.source && (
          <div className="mb-8">
            <p className="text-textPrimary flex items-center text-lg font-medium mb-2">
              Sources
            </p>
            <div className=" h-26 items-normal">
              {Array.isArray(answerChat?.answer?.source) ? (
                answerChat?.answer?.source.map((source, sourceIndex) => (
                  <div
                    key={sourceIndex}
                    className={`flex border-b-2 border-allBorderColor p-2 tran items-center mr-3 mb-2 cursor-pointer "h-18" `}
                  >
                    <p className="text-sm font-medium text-secondary">
                      {sourceIndex + 1}.
                    </p>
                    <Preview
                      imageUrl={source}
                      index={sourceIndex}
                    />
                    <a
                      target="_blank"
                      href={source}
                      className={`h-18 text-sm break-words overflow-hidden truncate overflow-ellipsis text-secondary font-normal`}
                    >
                      {source}
                    </a>
                  </div>
                ))
              ) : answerChat?.answer?.source ? (
                <div
                  key={0}
                  className={`flex border-b-2 border-allBorderColor p-2 tran items-center mr-3 mb-2 cursor-pointer "h-18" `}
                >
                  <p className="text-sm font-medium text-secondary">
                    1.
                  </p>
                  <Preview
                    imageUrl={answerChat?.answer?.source}
                    index={0}
                  />
                  <a
                    target="_blank"
                    href={answerChat?.answer?.source}
                    className={`h-18 text-sm break-words overflow-hidden truncate overflow-ellipsis text-secondary font-normal`}
                  >
                    {answerChat?.answer?.source}
                  </a>
                </div>
              ) : (
                <div
                  className={`flex bg-sidebarBgColor rounded-lg p-2 items-start hover:bg-allBorderColor mr-3 mb-2 cursor-pointer`}
                >
                  <p className="text-sm break-words md:w-48 h-10 overflow-hidden overflow-ellipsis text-secondary font-normal">
                    The source is not available
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        {(answerChat?.answer?.questions || answerChat?.answer?.relevant_queries) && (
          <div className="mt-8 p-5 mb-5 ">
            <p className="flex items-center text-md font-medium mb-4">
              Related
            </p>
            {(answerChat?.answer?.questions || []).concat(answerChat?.answer?.relevant_queries || []).map((data, index) => {
              return (
                <div className="w-full" key={index}>
                  <button
                    onClick={() => {
                      if (isPlanActive) {
                        handleRelated(data);
                      } else {
                        toast.error("Please upgrade your plan to use this feature");
                      }
                    }}
                    className="text-textPrimary border-b-2 text-sm text-left w-full border-allBorderColor p-3 hover:bg-allBorderColor"
                  >
                    {data}
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {answerChat?.report !== 1 && (
        <ReportModal
          isReportModal={isReportModal}
          setIsReportModal={setIsReportModal}
          messageId={messageId}
        />
      )}
    </>
  );
}

export default ChatAnswer;