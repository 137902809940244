import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import useAuth from "../../utils/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import FolderModal from "../Documents/FolderModal";
import { getFolders, removeFolder } from "../../store/DocumentSlice";
import Modal from "../Modal";
import apiClient from "../../utils/axiosInstance";
import SpinnerLoading from "../SpinnerLoading";
import ShareFolderModal from "../Documents/ShareFolderModal";
import { IoAdd } from "react-icons/io5";
import { VscKebabVertical } from "react-icons/vsc";

function SidebarRenderFolders({ isItemActive, setActiveItemId }) {
  const location = useLocation();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const { folderState, status, organizationState } = useAppSelector(
    (state) => ({
      organizationState: state.organization.organization,
      folderState: state.document.folders,
      status: state.document.folderStatus,
    })
  );
  const [folderModal, setFolderModal] = useState(false);
  const [initialFolder, setInitialFolder] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteFolderId, setDeleteFolderId] = useState(null);
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [folder, setFolder] = useState({});

  const [isSharedWithMeOpen, setIsSharedWithMeOpen] = useState(false);
  const [isSharedByMeOpen, setIsSharedByMeOpen] = useState(false);

  const toggleSharedWithMe = () => setIsSharedWithMeOpen(!isSharedWithMeOpen);
  const toggleSharedByMe = () => setIsSharedByMeOpen(!isSharedByMeOpen);

  useEffect(() => {
    if (user) {
      dispatch(getFolders());
    }
  }, [user]);

  const handleItemClick = (itemId) => {
    setActiveItemId(itemId);
  };

  const handleUpdate = (folder) => {
    setInitialFolder(folder);
    setFolderModal(true);
  };

  const confirmDeleteFolder = async () => {
    try {
      setIsApiCalling(true);
      await apiClient().delete(`/folder/${deleteFolderId}`);
      dispatch(removeFolder(deleteFolderId));
      setDeleteFolderId(null);
      setShowModal(false);
    } catch (error) {
      console.log("error in delete folder:", error);
    } finally {
      setIsApiCalling(false);
    }
  };

  const handleDelete = (folderId) => {
    setShowModal(true);
    setDeleteFolderId(folderId);
  };

  const handleShare = (folderId) => {
    setFolder(
      folderState?.folders.find((folder) => folder.folderId === folderId)
    );
    setShareModal(true);
  };

  const downloadFile = (url, fileName) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
        return "ph:file-png-bold";
      case "pdf":
        return "ph:file-pdf-bold";
      case "doc":
      case "docx":
        return "ph:file-doc-bold";
      default:
        return "mdi:file";
    }
  };
  useEffect(() => {
    if (status !== "loading" && location.pathname === "/folder") {
      if (folderState?.folders?.length > 0) {
        const firstFolder = folderState.folders[0];
        setActiveItemId(firstFolder.folderId);
        navigate(`/folder?id=${firstFolder.folderId}`);
      }
    }
  }, [status]);

  const handleShowButtons = (index) => {
    if (showButtons === index) {
      setShowButtons(false);
    } else {
      setShowButtons(index);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowButtons(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  return (
    <>
      {isApiCalling && <SpinnerLoading isLoading={isApiCalling} />}
      <div>
        <ul className="space-y-2 px-3 py-1">
          <li>
            <div className="flex items-center justify-between py-[0.70rem] border-b-2">
              <p className="text-xl font-bold tracking-wider">Files</p>
            </div>
          </li>

          {status === "loading" ? (
            <div className="animate-pulse space-y-3">
              {[1, 2, 3, 4, 5].map((item) => (
                <div key={item} className="flex items-center space-x-2">
                  <div className="w-6 h-6 bg-gray-200 rounded-full"></div>
                  <div className="flex-1 h-4 bg-gray-200 rounded"></div>
                </div>
              ))}
            </div>
          ) : (
            folderState?.folders?.map((item, index) => (
              <li
                key={index}
                className={`text-sm flex items-center mt-2 p-1 rounded-lg  hover:bg-slate-200 `}
                style={{
                  background: isItemActive(item.folderId)
                    ? organizationState[0]?.secondaryColor
                    : "",
                  color: isItemActive(item.folderId) ? "white" : "black",
                }}
                // onMouseEnter={() => setShowButtons(index)}
                // onMouseLeave={() => setShowButtons(false)}
              >
                <div className="flex items-center w-full relative">
                  <Icon
                    icon="ph:folders"
                    width="24px"
                    height="24px"
                    className="text-white mr-2"
                  />
                  <Link
                    to={`/folder?id=${item.folderId}`}
                    className="truncate flex-grow text-landing capitalize font-normal"
                    onClick={() => handleItemClick(item.folderId)}
                    title={item.folderName}
                  >
                    {item.folderName}
                  </Link>
                  <div
                    className="cursor-pointer	"
                   
                    onClick={() => handleShowButtons(index)}
                  >
                    <VscKebabVertical />
                  </div>

                  {/* {showButtons === index && (
                    <div className="absolute right-0 top-6 flex items-center space-x-2 bg-white px-2 py-1 rounded">
                      {item.isShared ? (
                        <div className="flex items-center text-xs text-gray-600">
                          <Icon
                            icon="mdi:information-outline"
                            width="1.2em"
                            height="1.2em"
                            className="text-secondary mr-1"
                          />
                          shared by {item.sharedBy}
                        </div>
                      ) : (
                        <>
                          <button
                            className=" rounded"
                            style={{
                              color: `${organizationState[0]?.primaryColor}`,
                            }}
                            onClick={() => handleUpdate(item)}
                          >
                            <Icon
                              icon="clarity:edit-solid"
                              width="1.5em"
                              height="1.5em"
                            />
                          </button>
                          <button
                            className=" rounded"
                            style={{
                              color: `${organizationState[0]?.primaryColor}`,
                            }}
                            onClick={() => handleDelete(item.folderId)}
                          >
                            <Icon
                              icon="mingcute:delete-2-fill"
                              width="1.5em"
                              height="1.5em"
                            />
                          </button>
                          <button
                            className="rounded"
                            style={{
                              color: `${organizationState[0]?.primaryColor}`,
                            }}
                            onClick={() => handleShare(item.folderId)}
                          >
                            <Icon
                              icon="ooui:share"
                              width="1.5em"
                              height="1.5em"
                            />
                          </button>
                        </>
                      )}
                    </div>
                  )} */}

                  {showButtons === index && (
                    <div className="absolute right-0 top-6 bg-white rounded shadow-lg py-2 z-50 "  ref={popupRef}>
                      {item.isShared ? (
                        <div className="flex items-center px-4 py-2 text-xs text-gray-600">
                          <Icon
                            icon="mdi:information-outline"
                            width="1.2em"
                            height="1.2em"
                            className="text-secondary mr-1"
                          />
                          shared by {item.sharedBy}
                        </div>
                      ) : (
                        <div className="flex flex-col">
                          <button
                            className="px-4 py-2 text-left hover:bg-gray-100 flex items-center"
                            style={{
                              color: `${organizationState[0]?.primaryColor}`,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUpdate(item)
                              }}
                          >
                            <Icon
                              icon="clarity:edit-solid"
                              width="1.2em"
                              height="1.2em"
                              className="mr-2"
                            />
                            Edit
                          </button>
                          <button
                            className="px-4 py-2 text-left hover:bg-gray-100 flex items-center"
                            style={{
                              color: `${organizationState[0]?.primaryColor}`,
                            }}
                            onClick={() => handleDelete(item.folderId)}
                          >
                            <Icon
                              icon="mingcute:delete-2-fill"
                              width="1.2em"
                              height="1.2em"
                              className="mr-2"
                            />
                            Delete
                          </button>
                          <button
                            className="px-4 py-2 text-left hover:bg-gray-100 flex items-center"
                            style={{
                              color: `${organizationState[0]?.primaryColor}`,
                            }}
                            onClick={() => handleShare(item.folderId)}
                          >
                            <Icon
                              icon="ooui:share"
                              width="1.2em"
                              height="1.2em"
                              className="mr-2"
                            />
                            Share
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </li>
            ))
          )}

          {/* {folderState?.sharedByMe?.length > 0 && (
            <li
              className={`text-sm flex items-center mt-2 p-1 rounded-lg  hover:bg-slate-200`}
             
            // onMouseEnter={() => setShowButtons(index)}
            // onMouseLeave={() => setShowButtons(false)}
            >
              <div
                className="flex items-center justify-between cursor-pointer mt-3 px-1"
                onClick={toggleSharedByMe}
              >
                <div className="flex items-center">
                  <Icon
                    icon="ph:folders"
                    width="24px"
                    height="24px"
                    className="text-pricingText mr-2"
                  />
                  <Link
                    to={`/sharebyme-folder`}
                    className="truncate flex-grow text-landing capitalize font-normal"
                    // onClick={() => handleItemClick(item?.files[0]?.docS3Loc)}
                    // title={item?.files[0]?.fileName}
                  >
                    Shared By Me
                  </Link>
                  {/* <p className="text-sm font-normal text-landing capitalize">
                  Shared By Me
                  </p> */}
          {/* </div> */}

          {/* <Icon
                  icon={
                    isSharedByMeOpen ? "mdi:chevron-up" : "mdi:chevron-down"
                  }
                  width="1.5em"
                  height="1.5em"
                /> */}
          {/* </div> */}
          {/* {isSharedByMeOpen && (
                <ul className="ml-4 mt-2 space-y-2">
                  {folderState?.sharedByMe?.map((item, index) => (
                    <li key={index} className="text-sm">
                      <p className="font-medium">{item.folderName}</p>
                      {item.files.map((file, fileIndex) => (
                        <div className="flex items-center justify-between w-full pb-2">
                          <div className="flex items-center">
                            <Icon
                              icon={getFileIcon(file.fileName)}
                              className="w-6 h-6 mr-2"
                            />
                            <p className="text-sm text-gray-700 flex-grow w-40 truncate">
                              {file.fileName}
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <div className="flex items-center">
                              <button
                                onClick={() =>
                                  downloadFile(file.url, file.fileName)
                                }
                                className="w-6 h-6 flex items-center justify-center bg-gray-200 rounded-full mr-2"
                                style={{ color: `${organizationState[0]?.primaryColor}` }}
                              >
                                <Icon
                                  icon="octicon:download-24"
                                  className="w-5 h-5"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )} */}
          {folderState?.sharedByMe?.length > 0 && (
            <li className="mt-4">
              {/* <div
                className="flex items-center justify-between cursor-pointer mt-3 px-1"
                onClick={toggleSharedByMe}
              >
                <div className="flex items-center">
                  <Icon
                    icon="ph:folders"
                    width="24px"
                    height="24px"
                    className="text-pricingText mr-2"
                  />
                  <p className="text-sm font-normal text-landing capitalize">
                    Shared By Me
                  </p>
                </div>

                <Icon
                  icon={
                    isSharedByMeOpen ? "mdi:chevron-up" : "mdi:chevron-down"
                  }
                  width="1.5em"
                  height="1.5em"
                />
              </div> */}
              {isSharedByMeOpen && (
                <ul className="ml-4 mt-2 space-y-2">
                  {folderState?.sharedByMe?.map((item, index) => (
                    <li key={index} className="text-sm">
                      <p className="font-medium">{item.folderName}</p>
                      {item.files.map((file, fileIndex) => (
                        <div className="flex items-center justify-between w-full pb-2">
                          <div className="flex items-center">
                            <Icon
                              icon={getFileIcon(file.fileName)}
                              className="w-6 h-6 mr-2"
                            />
                            <p className="text-sm text-gray-700 flex-grow w-40 truncate">
                              {file.fileName}
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <div className="flex items-center">
                              <button
                                onClick={() =>
                                  downloadFile(file.url, file.fileName)
                                }
                                className="w-6 h-6 flex items-center justify-center bg-gray-200 rounded-full mr-2"
                                style={{
                                  color: `${organizationState[0]?.primaryColor}`,
                                }}
                              >
                                <Icon
                                  icon="octicon:download-24"
                                  className="w-5 h-5"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )}
          {folderState?.sharedWithMe?.length > 0 && (
            <li className="mt-4">
              <div
                className="flex items-center justify-between cursor-pointer mt-2 px-1"
                onClick={toggleSharedWithMe}
              >
                <div className="flex items-center">
                  <Icon
                    icon="ph:folders"
                    width="24px"
                    height="24px"
                    className="text-pricingText mr-2"
                  />
                  <p className="text-sm font-normal text-landing capitalize">
                    Shared With Me
                  </p>
                </div>
                <Icon
                  icon={
                    isSharedWithMeOpen ? "mdi:chevron-up" : "mdi:chevron-down"
                  }
                  width="1.5em"
                  height="1.5em"
                />
              </div>
              {isSharedWithMeOpen && (
                <ul className="ml-4 mt-2 space-y-2">
                  {folderState?.sharedWithMe?.map((item, index) => (
                    <li key={index} className="text-sm">
                      <p className="font-medium">{item.folderName}</p>
                      {item.files.map((file, fileIndex) => (
                        <div className="flex items-center justify-between w-full pb-2">
                          <div className="flex items-center">
                            <Icon
                              icon={getFileIcon(file.fileName)}
                              className="w-6 h-6 mr-2"
                            />
                            <p className="text-sm text-gray-700 flex-grow w-40 truncate">
                              {file.fileName}
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <div className="flex items-center">
                              <button
                                onClick={() =>
                                  downloadFile(file.url, file.fileName)
                                }
                                className="w-6 h-6 flex items-center justify-center bg-gray-200 rounded-full  hover:bg-gray-300  mr-2"
                                style={{
                                  color: `${organizationState[0]?.primaryColor}`,
                                }}
                              >
                                <Icon
                                  icon="octicon:download-24"
                                  className="w-4 h-4"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )}

          <button
            className="text-center text-sm flex items-center gap-2 p-1"
            onClick={() => {
              setFolderModal(true);
            }}
          >
            <div className="flex items-center justify-center p-1 rounded-md" style={{backgroundColor:"#e5e7eb"}}><IoAdd/></div>
            Create New Folder
          </button>
        </ul>
      </div>
      <FolderModal
        folderModal={folderModal}
        setFolderModal={setFolderModal}
        initialFolder={initialFolder}
        setInitialFolder={setInitialFolder}
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        confirmAction={confirmDeleteFolder}
        label={"Delete Folder"}
        description={"Are you sure you want to delete this folder?"}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
      />
      <ShareFolderModal
        shareModal={shareModal}
        setShareModal={setShareModal}
        folder={folder}
      />
    </>
  );
}

export default SidebarRenderFolders;
