import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { addNewModel, addNewProvider, changeProvider, getModelDetails, updateKey } from '../store/aiModelSlice';
import apiClient from '../utils/axiosInstance';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toggleAiSetting, toggleAiSettingAvaliable, updateOrganizationKeyValue } from '../store/organization';
import AiChatsettings from './Account/AiChatsettings';
import CryptoJS from "crypto-js";
import Pinecone from './Account/Pinecone';

const providerValidationSchema = Yup.object().shape({
    providerName: Yup.string().required('Provider name is required'),
    providerKey: Yup.string().required('Provider key is required'),
    modelName: Yup.string().required('Model name is required'),
});

const pineconeValidationSchema = Yup.object().shape({
    providerKey: Yup.string().required('Pinecone API key is required'),
});

const websearchValidationSchema = Yup.object().shape({
    providerKey: Yup.string().required('Provider key is required'),
});

const modelValidationSchema = Yup.object().shape({
    providerId: Yup.string().required('Provider is required'),
    modelName: Yup.string().required('Model name is required'),
});

const ToggleSwitch = ({ id, checked, onChange, disabled }) => (
    <div className="relative inline-block w-12 h-6 mr-2 align-middle select-none transition duration-200 ease-in">
        <input
            type="checkbox"
            name={id}
            id={id}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            className={`toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-200 ease-in-out transform ${checked ? 'translate-x-6' : 'translate-x-0'} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        />
        <label
            htmlFor={id}
            className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer transition-colors duration-200 ease-in-out bg-gray-200 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        ></label>
    </div>

);


const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 max-w-md w-full relative">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">{title}</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <Icon icon="ic:baseline-close" width="1.2em" height="1.2em" />
                    </button>
                </div>
                {children}
            </div>
        </div>
    );
};


const AIModelsSection = ({ organization }) => {
    const dispatch = useDispatch();
    const { modelState, userDetails } = useSelector((state) => ({
        modelState: state.aiModels.aiModels,
        userDetails: state.auth.userDetails,
    }));
    const [settings, setSettings] = useState({
        aiChat: organization.isAiChatUsed,
        documentSearch: organization.isDocumentSearchUsed,
        webSearch: organization.isWebsearchUsed,
    });
    const filterProvider = modelState?.providers?.filter(
        provider => provider.provider_name !== "pinecone" && provider.provider_name !== "exa"
    );
    const Providders = filterProvider;

    const [showAiChatModal, setShowAiChatModal] = useState(false);
    const [showDocumentEmbeddingsModal, setShowDocumentEmbeddingsModal] = useState(false);
    const [showWebsearchModal, setShowWebsearchModal] = useState(false);
    const [showDescriptionPopup, setShowDescriptionPopup] = useState(false);
    const [showAddProviderModal, setShowAddProviderModal] = useState(false);
    const [showAddModelModal, setShowAddModelModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [newChatBotName, setNewChatBotName] = useState(organization?.chatBotName);
    const [showPromptModal, setShowPromptModal] = useState(false);
    const [isPromptEditing, setIsPromptEditing] = useState(false);
    const [currentPrompt, setCurrentPrompt] = useState(organization?.prompt || '');
    const [pid, setpid] = useState("");
    const [loader1, setLoader1] = useState(false);
    const [error1, setError1] = useState(null);
    useEffect(() => {
        setSettings({
            aiChat: organization.isAiChatUsed,
            documentSearch: organization.isDocumentSearchUsed,
            webSearch: organization.isWebsearchUsed,
        })
    }, [organization]);

    const handlePromptEdit = () => {
        setIsPromptEditing(true);
    };

    const handlePromptSave = async () => {
        try {
            const response = await apiClient().put("/organization", {
                prompt: currentPrompt,
            });
            dispatch(updateOrganizationKeyValue({ key: 'prompt', value: response.data.prompt }));
            setIsPromptEditing(false);
        } catch (error) {
            toast.error(error);
        }
    };

    const settingNameMap = {
        aiChat: 'isAiChatUsed',
        documentSearch: 'isDocumentSearchUsed',
        webSearch: 'isWebsearchUsed'
    };
    const encryptKey = (data) => {
        try {
            const key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_SECRATE_KEY);
            const dataString = typeof data === "string" ? data : JSON.stringify(data);
            const encrypted = CryptoJS.AES.encrypt(dataString, key, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });
            const encryptedText = encrypted.toString();

            return encryptedText;
        } catch (error) {
            console.error('Encryption failed:', error);
            return null;
        }
    };

    const toggleSetting = async (setting) => {
        try {
            setSettings(prev => ({ ...prev, [setting]: !prev[setting] }));
            const settingName = settingNameMap[setting];

            const { data } = await apiClient().post("/toggle-ai-setting", {
                settingName: settingName
            });

            dispatch(toggleAiSetting({ settingName: settingName, value: !settings[setting] }));
            if (!data?.setting?.isDocumentSearchUsed) {
                const pinConeProvider = modelState?.providers?.filter(
                    provider => provider.provider_name === "pinecone"
                );
                await apiClient().put(`/provider/${pinConeProvider[0].modelId}`, {
                    providerKey: "",
                });
                dispatch(updateKey({ providerId: pinConeProvider[0].modelId, key: null }));
            }

        } catch (error) {
            toast.error(error);
        }
    };

    const togglePineconeStorage = async () => {
        try{
            await apiClient().post("/toggle-ai-setting", { settingName: "isPineconeCospaceStorage" })
            dispatch(toggleAiSetting({ settingName: "isPineconeCospaceStorage", value: !organization.isPineconeCospaceStorage }));
        } catch (error) {
            toast.error(error);
        }
    }

    const handleToggle = async (setting) => {
        if (setting === 'aiChat') {
            if (organization.AiChatAvailable) {
                if (organization.isAiChatUsed) {
                    await toggleSetting(setting)
                } else {
                    const filterProvider = modelState?.providers?.filter(
                        provider => provider.provider_name !== "pinecone" && provider.provider_name !== "exa" && provider.provider_key
                            !== null && provider.provider_key
                            !== ""
                    );
                    if (filterProvider.length > 0) {
                        await toggleSetting(setting)
                    } else {
                        toast.error("Please add a provider to use AI chat feature");
                    }
                }
            } else {
                setShowAiChatModal(true);
            }
        } else if (setting === 'documentSearch') {
            if (!organization.isAiChatUsed) {
                setShowDescriptionPopup(true);
            } else {
                await toggleSetting(setting);
            }
        } else if (setting === 'webSearch') {
            changeWebSearchSetting(organization.WebsearchAvailable);
        } else {
            await toggleSetting(setting);
        }
    };

    const changeWebSearchSetting = async (WebsearchAvailable) => {
        if (loader1) return;
        const gemini = Providders.find(provider => provider.provider_name === 'gemini');
        if (!gemini || !gemini.provider_key) {
            setError1({ message: 'Gemini Api Key is necessary to enable web search', type: "error" });
            setTimeout(() => {
                setError1(null);
            }, 2000)
            return;
        }

        try {
            setLoader1(true);
            const { data } = await apiClient().post("/toggle-ai-setting", { settingName: "isWebsearchUsed" })
            dispatch(toggleAiSetting({ settingName: "isWebsearchUsed", value: data?.setting?.isWebsearchUsed }));
            setError1({ message: data?.message, type: "success" });
            setTimeout(() => {
                setError1(null);
            }, 2000)
        } catch (error) {
            console.log("Error while toggling websearch:", error);
            setError1({ message: error?.response?.data?.message || error?.response?.data || error?.message || error?.toString(), type: "error" });
            setTimeout(() => {
                setError1(null);
            }, 2000)
        } finally {
            setLoader1(false);
        }
    }

    const verifyGeminiApiKey = async (apiKey) => {
        const API_VERSION = 'v1';
        const apiUrl = `https://generativelanguage.googleapis.com/${API_VERSION}/models?key=${apiKey}`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            muteHttpExceptions: true,
        });
        return response.status === 200;
    };

    const verifyOpenaiApiKey = async (apiKey) => {
        const apiUrl = `https://api.openai.com/v1/models`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiKey}` },
            muteHttpExceptions: true,
        });
        return response.status === 200;
    };

    const handleSaveProvider = async (values, { setSubmitting, setFieldError }, isFromAiChatModal = false) => {
        try {
            let isValid = false;
            if (values.providerName === 'openai') {
                isValid = await verifyOpenaiApiKey(values.providerKey);
            } else if (values.providerName === 'gemini') {
                isValid = await verifyGeminiApiKey(values.providerKey);
            }
            if (isValid) {
                setSubmitting(true);
                const response = await apiClient().post("/provider", values)
                dispatch(addNewProvider(response.data));
                dispatch(toggleAiSettingAvaliable({ settingName: 'AiChatAvailable', value: true }));

                if (isFromAiChatModal) {
                    await toggleSetting('aiChat');
                }
            } else {
                setFieldError('providerKey', 'Invalid API key');
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setSubmitting(false);
            setShowAiChatModal(false);
            setShowAddProviderModal(false);
        }
    };

    const handleSavePinecone = async (values, { setSubmitting }) => {
        try {

            const encryptedValues = encryptKey(values.providerKey);

            if (!encryptedValues) {
                throw new Error("Encryption failed.");
            }

            setSubmitting(true);
            const pinConeProvider = modelState?.providers?.filter(
                provider => provider.provider_name === "pinecone"
            );
            if (pinConeProvider) {
                await apiClient().put(`/provider/${pinConeProvider[0].modelId}`, {
                    providerKey: encryptedValues,
                });
                toggleSetting("documentSearch");
                dispatch(updateKey({ providerId: pinConeProvider[0].modelId, key: encryptedValues }));
                dispatch(toggleAiSetting({ settingName: "documentSearch", value: true }));
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setSubmitting(false);
            setShowDocumentEmbeddingsModal(false);
        }
    };

    const handleSaveWebsearch = async (values, { setSubmitting }) => {
        try {
            setSubmitting(true);
            const response = await apiClient().post("/provider", values)
            dispatch(addNewProvider(response.data));
            dispatch(toggleAiSettingAvaliable({ settingName: 'WebsearchAvailable', value: true }));
            await toggleSetting('webSearch');
        } catch (error) {
            toast.error(error);
        } finally {
            setSubmitting(false);
            setShowWebsearchModal(false);
        }
    };

    const handleAddNewModel = (provider) => {
        setpid(provider.modelId)
        setShowAddModelModal(true);
    };

    const handleSaveModel = async (values, { setSubmitting }) => {
        try {
            setSubmitting(true);
            const response = await apiClient().post("/model", values);
            dispatch(addNewModel(response.data));
            toast.success('Model added successfully');
        } catch (error) {
            toast.error('Failed to add new model');
        } finally {
            setSubmitting(false);
            setShowAddModelModal(false);
        }
    };

    const getRemainProviders = () => {
        const allProviders = ['openai', 'gemini', 'anthropic'];
        const existingProviders = modelState?.providers?.map(provider => provider.provider_name) || [];
        const remainingProviders = allProviders.filter(provider => !existingProviders.includes(provider));
        return remainingProviders;
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            if (newChatBotName === organization?.chatBotName) {
                setIsEditing(false);
                return;
            }
            const response = await apiClient().put("/organization", {
                chatBotName: newChatBotName,
            })
            dispatch(updateOrganizationKeyValue({ key: 'chatBotName', value: response.data.chatBotName }));
        } catch (error) {
            console.error("Error updating org name:", error);
        } finally {
            setIsEditing(false);
        }
    };

    const formatName = (key) => {
        if (key === 'aiChat') {
            return 'AI Chat';
        }

        return key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim();
    };

    return (
        <div className="ai-models-section mt-5 mb-20">

            <div>
                <h1 className='mb-5 text-xl'>General AI Settings</h1>
                <div className='bg-white border border-borderColor rounded-xl p-5'>
                    <div className='mb-5'>
                        <p className='mb-2 text-slate-500 font-medium'>Chat Bot Name</p>
                        <div className='flex gap-4 items-center'>
                            {userDetails?.role === "admin" || userDetails?.role === "super admin" ? (
                                isEditing ? (
                                    <>
                                        <input
                                            type='text'
                                            value={newChatBotName}
                                            onChange={(e) => setNewChatBotName(e.target.value)}
                                            className='rounded-full w-full border-slate-300'
                                        />
                                        <Icon
                                            icon="mdi:check"
                                            className='cursor-pointer text-green-800 mr-5'
                                            width="1.2em"
                                            height="1.2em"
                                            onClick={handleSave}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <p className='w-full rounded-full border py-2 px-4'>
                                            {organization?.chatBotName}
                                        </p>
                                        <Icon
                                            icon="mdi:pencil"
                                            className="cursor-pointer text-green-800 mr-5"
                                            width="1.2em"
                                            height="1.2em"
                                            onClick={handleEdit}
                                        />
                                    </>
                                )
                            ) : (
                                <p className='rounded-full w-full border-slate-300'>
                                    {organization?.chatBotName}
                                </p>
                            )}
                        </div>

                    </div>
                    <div className='overflow-hidden'>
                        <div className='flex justify-between items-center mb-3'>

                            <p className='mb-2 text-slate-500 font-medium'>Current Prompt</p>
                            <div className="flex justify-between items-center pr-5">
                                {!isPromptEditing ? (
                                    <Icon
                                        icon="mdi:pencil"
                                        className="cursor-pointer text-green-800"
                                        width="1.2em"
                                        height="1.2em"
                                        onClick={handlePromptEdit}
                                    />
                                ) : (
                                    <Icon
                                        icon="mdi:check"
                                        className="cursor-pointer"
                                        width="1.2em"
                                        height="1.2em"
                                        onClick={handlePromptSave}
                                    />
                                )}
                            </div>
                        </div>
                        <textarea
                            id="prompt"
                            value={currentPrompt}
                            onChange={(e) => setCurrentPrompt(e.target.value)}
                            readOnly={!isPromptEditing}
                            className={`w-full p-2 border rounded-md border-slate-300`}
                            rows={5}
                        />
                    </div>
                </div>
            </div>

            <div className="py-6 rounded-xl space-y-4 mt-5">
                {Object.entries(settings).map(([key, value]) => (
                    <div key={key}>
                        <div className="flex  items-center  justify-between  p-2 rounded-tl-xl rounded-tr-xl rounded-bl-none rounded-br-none">
                            <div className='w-full'>
                                <div className='flex items-center justify-between'>

                                    <label htmlFor={key} className="text-md font-bold pl-2 mt-2">
                                        {formatName(key)}
                                    </label>
                                    <ToggleSwitch
                                        id={key}
                                        checked={value}
                                        onChange={() => handleToggle(key)}
                                        disabled={false}
                                    />
                                </div>

                                {key === "aiChat" && <AiChatsettings handleAddNewModel={handleAddNewModel} />}
                                {key === "documentSearch" && organization.isDocumentSearchUsed ? <>
                                    <div className="flex items-center justify-between w-full px-5 my-4">
                                        <label htmlFor="pineConeStorage" className="text-md font-normal pl-2 mt-2 mr-2">
                                            Use cospace storage
                                        </label>
                                        <div className="relative inline-block w-12 h-6 mr-2 align-middle select-none scale-90 transition duration-200 ease-in">
                                            <input
                                                type="checkbox"
                                                name="storageType"
                                                id={"storageType"}
                                                checked={organization.isPineconeCospaceStorage}
                                                onChange={() => { 
                                                    togglePineconeStorage();
                                                }}
                                                className={`toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-200 ease-in-out transform ${organization.isPineconeCospaceStorage ? 'translate-x-6' : 'translate-x-0'}`}
                                            />
                                            <label
                                                htmlFor={"storageType"}
                                                className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer transition-colors duration-200 ease-in-out bg-gray-200 `}
                                            ></label>
                                        </div>
                                    </div>
                                    {!organization.isPineconeCospaceStorage ? <Pinecone /> : null}
                                </>
                                    : null}
                                {/* {key === "documentSearch" && <Pinecone />} */}
                                {key === "webSearch" && <>
                                    <div className={`text-center ${error1?.type === "error" ? "text-red-600" : "text-green-500"}`}>
                                        {error1 && (
                                            <span className="fade-in-out">
                                                {error1.message}
                                            </span>
                                        )}
                                    </div>
                                </>}
                            </div>
                        </div>


                    </div>
                ))}
            </div>

            <Modal
                isOpen={showPromptModal}
                onClose={() => {
                    setShowPromptModal(false);
                    setIsPromptEditing(false);
                    setCurrentPrompt(organization?.prompt || '');
                }}
                title="Change Prompt"
            >
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <label htmlFor="prompt" className="text-sm font-medium">
                            Current Prompt
                        </label>
                        {!isPromptEditing ? (
                            <Icon
                                icon="fa:edit"
                                className="cursor-pointer"
                                width="1.2em"
                                height="1.2em"
                                onClick={handlePromptEdit}
                            />
                        ) : (
                            <Icon
                                icon="pajamas:false-positive"
                                className="cursor-pointer"
                                width="1.2em"
                                height="1.2em"
                                onClick={handlePromptSave}
                            />
                        )}
                    </div>
                    <textarea
                        id="prompt"
                        value={currentPrompt}
                        onChange={(e) => setCurrentPrompt(e.target.value)}
                        readOnly={!isPromptEditing}
                        className={`w-full p-2 border rounded-md ${isPromptEditing ? 'bg-white' : 'bg-gray-100'
                            }`}
                        rows={5}
                    />
                </div>
            </Modal>

            <Modal
                isOpen={showAddProviderModal}
                onClose={() => setShowAddProviderModal(false)}
                title="Add New Provider"
            >
                <Formik
                    initialValues={{ providerName: '', providerKey: '', modelName: '' }}
                    validationSchema={providerValidationSchema}
                    onSubmit={(values, formikBag) => handleSaveProvider(values, formikBag, false)}
                >
                    {({ isSubmitting }) => (
                        <Form className="space-y-4">
                            <div>
                                <Field
                                    as="select"
                                    name="providerName"
                                    className="w-full p-2 border rounded-md"
                                >
                                    <option value="">Select a provider</option>
                                    {getRemainProviders().map((provider, index) => (
                                        <option key={index} value={provider}>
                                            {provider}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="providerName" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <div>
                                <Field
                                    type="text"
                                    name="providerKey"
                                    placeholder="Provider Key"
                                    className="w-full p-2 border rounded-md"
                                />
                                <ErrorMessage name="providerKey" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <div>
                                <Field
                                    type="text"
                                    name="modelName"
                                    placeholder="Model Name"
                                    className="w-full p-2 border rounded-md"
                                />
                                <ErrorMessage name="modelName" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className=" text-white px-4 py-2 rounded"
                                style={{ backgroundColor: organization?.primaryColor }}
                            >
                                {isSubmitting ? 'Saving...' : 'Save'}
                            </button>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal
                isOpen={showAddModelModal}
                onClose={() => setShowAddModelModal(false)}
                title="Add  Chat Model"
            >
                <Formik
                    initialValues={{ providerId: pid, modelName: '' }}
                    validationSchema={modelValidationSchema}
                    onSubmit={handleSaveModel}
                >
                    {({ isSubmitting }) => (
                        <Form className="space-y-4">
                            <div>
                                <label className='text-slate-500 px-4'>Model name</label>
                                <Field
                                    type="text"
                                    name="modelName"
                                    placeholder="Enter Model Name"
                                    className="w-full p-2 px-4 border rounded-full mt-2"
                                />
                                <ErrorMessage name="modelName" component="div" className="text-red-500 text-sm mt-1" />
                            </div>

                            <div className="flex justify-end space-x-2">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    onClick={() => setShowAddModelModal(false)}
                                    className=" text-black border border-black  px-6 py-2 rounded-[20px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className=" text-white px-6 py-2 rounded-[20px]"
                                    style={{ backgroundColor: organization?.primaryColor }}
                                >
                                    {isSubmitting ? 'Saving...' : 'Save'}
                                </button>

                            </div>

                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal
                isOpen={showAiChatModal}
                onClose={() => setShowAiChatModal(false)}
                title="Set Up AI Chat"
            >
                <Formik
                    initialValues={{ providerName: '', providerKey: '', modelName: '' }}
                    validationSchema={providerValidationSchema}
                    onSubmit={(values, formikBag) => handleSaveProvider(values, formikBag, true)}
                >
                    {({ isSubmitting }) => (
                        <Form className="space-y-4">
                            <div>
                                <Field
                                    as="select"
                                    name="providerName"
                                    className="w-full p-2 border rounded-md"
                                >
                                    <option value="">Select a provider</option>
                                    {['openai', 'gemini', 'anthropic'].map((provider, index) => (
                                        <option key={index} value={provider}>
                                            {provider}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="providerName" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <div>
                                <Field
                                    type="text"
                                    name="providerKey"
                                    placeholder="Provider Key"
                                    className="w-full p-2 border rounded-md"
                                />
                                <ErrorMessage name="providerKey" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <div>
                                <Field
                                    type="text"
                                    name="modelName"
                                    placeholder="Model Name"
                                    className="w-full p-2 border rounded-md"
                                />
                                <ErrorMessage name="modelName" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className=" text-white px-4 py-2 rounded"
                                style={{ backgroundColor: organization?.primaryColor }}
                            >
                                {isSubmitting ? 'Saving...' : 'Save'}
                            </button>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal
                isOpen={showWebsearchModal}
                onClose={() => setShowWebsearchModal(false)}
                title="Set Up Websearch"
            >
                <Formik
                    initialValues={{ providerName: 'exa', providerKey: '' }}
                    validationSchema={websearchValidationSchema}
                    onSubmit={handleSaveWebsearch}
                >
                    {({ isSubmitting }) => (
                        <Form className="space-y-4">
                            <div>
                                <Field
                                    type="text"
                                    name="providerName"
                                    value="exa"
                                    disabled
                                    className="w-full p-2 border rounded-md bg-gray-100"
                                />
                            </div>
                            <div>
                                <Field
                                    type="text"
                                    name="providerKey"
                                    placeholder="Provider Key"
                                    className="w-full p-2 border rounded-md"
                                />
                                <ErrorMessage name="providerKey" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className=" text-white px-4 py-2 rounded"
                                style={{ backgroundColor: organization?.primaryColor }}
                            >
                                {isSubmitting ? 'Saving...' : 'Save'}
                            </button>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal
                isOpen={showDocumentEmbeddingsModal}
                onClose={() => setShowDocumentEmbeddingsModal(false)}
                title="Set Up Document Embeddings"
            >
                <Formik
                    initialValues={{ providerKey: '' }}
                    validationSchema={pineconeValidationSchema}
                    onSubmit={handleSavePinecone}
                >
                    {({ isSubmitting }) => (
                        <Form className="space-y-4">
                            <div>
                                <Field
                                    type="text"
                                    name="providerName"
                                    value="pinecone"
                                    disabled
                                    className="w-full p-2 border rounded-md bg-gray-100"
                                />
                            </div>
                            <div>
                                <Field
                                    type="text"
                                    name="providerKey"
                                    placeholder="Pinecone API Key"
                                    className="w-full p-2 border rounded-md"
                                />
                                <ErrorMessage name="providerKey" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className=" text-white px-4 py-2 rounded"
                                style={{ backgroundColor: organization?.primaryColor }}
                            >
                                {isSubmitting ? 'Saving...' : 'Save'}
                            </button>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal
                isOpen={showDescriptionPopup}
                onClose={() => setShowDescriptionPopup(false)}
                title="AI Chat Required"
            >
                <p className="mb-4">You need to add an AI model by enabling AI Chat before you can use Document Search Feature.</p>
                <button
                    onClick={() => {
                        // setShowDescriptionPopup(false);
                        // setShowAiChatModal(true);
                        setShowDescriptionPopup(false)
                    }}
                    className=" text-white px-4 py-2 rounded"
                    style={{ backgroundColor: organization?.primaryColor }}
                >
                    Set Up AI Chat
                </button>
            </Modal>
        </div >
    );
};

export default AIModelsSection;