import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";

export const likeAndReport = createAsyncThunk("likeAndReport", async (body) => {
  try {
    const response = await apiClient().post(`/chat`, body);
    return response.data;
  } catch (error) {
    console.log("error in chat slice:", error);
    toast.error(`${error}`);
  }
});

export const getUserChatsWithinLimit = createAsyncThunk(
  "getUserChatsWithinLimit",
  async (limit) => {
    try {
      const response = await apiClient().get(
        `/chat?action=history&limit=${limit}`
      );
      return response.data;
    } catch (error) {
      console.log("error in chat slice:", error);
      toast.error(`${error}`);
    }
  }
);

export const getChatsByChatId = createAsyncThunk(
  "getChatsByChatId",
  async (id) => {
    try {
      const response = await apiClient().get(
        `/chat?action=chat&threadId=${id}`
      );
      return response.data;
    } catch (error) {
      console.log("error in chat slice:", error);
      toast.error(`${error}`);
    }
  }
);
export const getWebChatById = createAsyncThunk("getWebChatById", async (id) => {
  try {
    const response = await apiClient().get(
      `/chat?action=webchat&threadId=${id}`
    );
    return response.data;
  } catch (error) {
    console.log("error in chat slice:", error);
    toast.error(`${error}`);
  }
});

export const getStarredThreads = createAsyncThunk(
  "getStarredThreads",
  async () => {
    try {
      const response = await apiClient().get(`/starred-threads`);
      return response.data;
    } catch (error) {
      console.log("error in chat slice:", error);
      toast.error(`${error}`);
    }
  }
);

export const getDirectMessages = createAsyncThunk("getDirectMessages", async () => {
  try {
    const response = await apiClient().get(`/direct-messages/check`);
    return response.data;
  } catch (error) {
    console.log("error in chat slice:", error);
    toast.error(`${error}`);
  }
});

export const finChat = createSlice({
  name: "finChat",
  initialState: {
    chatWithLimit: [],
    chatQueries: [],
    starredThreads: [],
    status: null,
    chatStatus: null,
    chatLoadStatus: null,
    starredStatus: null,
    DirectMessages: [],
    fetchDmsStatus: null,
  },
  reducers: {
    addNewChat: (state, action) => {
      state.chatWithLimit.history = [
        action.payload,
        ...state.chatWithLimit.history,
      ];
    },
    newQuery: (state, action) => {
      state.chatQueries.chat.queries = [
        ...state.chatQueries.chat.queries,
        action.payload,
      ];
    },
    likedChat: (state, action) => {
      state.chatQueries.chat.queries = state.chatQueries.chat.queries.map(
        (chat) => {
          if (chat.messageId === action.payload) {
            return { ...chat, liked: 1 };
          }
          return chat;
        }
      );
    },
    reportChat: (state, action) => {
      state.chatQueries.chat.queries = state.chatQueries.chat.queries.map(
        (chat) => {
          if (chat.messageId === action.payload) {
            return { ...chat, report: 1 };
          }
          return chat;
        }
      );
    },
    starredThread: (state, action) => {
      state.chatWithLimit.history = state.chatWithLimit.history.map((chat) => {
        if (chat.threadId === action.payload) {
          return { ...chat, starred: !chat.starred };
        }
        return chat;
      });

      const thread = state.chatWithLimit.history.find(
        (chat) => chat.threadId === action.payload
      );
      if (thread && state.starredThreads.threads) {
        if (thread.starred) {
          state.starredThreads.threads.push(thread);
        } else {
          state.starredThreads.threads = state.starredThreads.threads.filter(
            (starredThread) => starredThread.threadId !== action.payload
          );
        }
      }
    },
    updateStarredThread: (state, action) => {
      const { threadId, key, value } = action.payload;
      const threadIndex = state.starredThreads.threads.findIndex(
        (thread) => thread.threadId === threadId
      );

      if (threadIndex !== -1) {
        key.forEach((k, index) => {
          if (value[index] !== undefined) {
            state.starredThreads.threads[threadIndex] = {
              ...state.starredThreads.threads[threadIndex],
              [k]: value[index],
            };
          }
        });
      }
    },
    updateThreadNotificatioOnOrOff: (state, action) => {
      const {threadId, notificationOn} = action.payload;
      state.chatWithLimit.history = state.chatWithLimit.history.map((chat) => {
        if (chat.threadId === threadId) {
          return { ...chat, notificationOn: notificationOn };
        }
        return chat;
      });
    },
    deleteUserLimitedChat: (state, action) => {
      state.chatWithLimit.history = state.chatWithLimit.history?.filter(
        (chat) => chat.threadId !== action.payload
      );
    },
    updateLimitedChat: (state, action) => {
      state.chatWithLimit.history = state.chatWithLimit.history?.map((chat) => {
        if (chat.threadId === action.payload.threadId) {
          return { ...chat, chatName: action.payload.chatName };
        }
        return chat;
      });
    },
    updateChatNotification: (state, action) => {
      const updatedChat = state.chatWithLimit.history.find(
        (chat) => chat.threadId === action.payload.threadId
      );

      if (updatedChat) {
        state.chatWithLimit.history = state.chatWithLimit.history.filter(
          (chat) => chat.threadId !== action.payload.threadId
        );

        if (!updatedChat.hasOwnProperty("notification")) {
          updatedChat.notification = 1;
        } else {
          updatedChat.notification = action.payload.notification;
        }

        state.chatWithLimit.history.unshift(updatedChat);
      }
    },
    updateMainThreadNotification: (state, action) => {
      state.chatWithLimit.notification = state.chatWithLimit.notification + 1;
    },
    resetMainThreadNotification: (state, action) => {
      state.chatWithLimit.notification = 0;
    },
    changeMainThreadNotification: (state, action) => {
      state.chatWithLimit.notification = state.chatWithLimit.notification - action.payload;
    },
    clearChatQueries: (state, action) => {
      state.chatQueries = [];
    },
    clearAllStates: (state, action) => {
      state.chatWithLimit = [];
      state.chatQueries = [];
      state.status = null;
    },
    resetDMNotification: (state, action) => {
      state.DirectMessages = state.DirectMessages.map((member) => {
        if (member.userId === action.payload.memberId) {
          member.notification = 0;
        }
        return member;
      });
    },
    updateDMNotification: (state, action) => {
      state.DirectMessages = state.DirectMessages.map((member) => {
        if (member.userId === action.payload.memberId) {
          member.notification = member.notification + 1;
        }
        return member;
      });
    },
    addNewDM: (state, action) => {
      state.DirectMessages = [...state.DirectMessages, action.payload];
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getChatsByChatId.fulfilled, (state, action) => {
        state.chatStatus = "succeeded";
        state.chatQueries = action.payload;
      })
      .addCase(getChatsByChatId.pending, (state, action) => {
        state.chatStatus = "loading";
      })
      .addCase(getChatsByChatId.rejected, (state, action) => {
        state.chatStatus = "failed";
      })
      .addCase(getUserChatsWithinLimit.fulfilled, (state, action) => {
        state.chatLoadStatus = "succeeded";
        state.chatWithLimit = action.payload;
      })
      .addCase(getUserChatsWithinLimit.pending, (state, action) => {
        state.chatLoadStatus = "loading";
      })
      .addCase(getUserChatsWithinLimit.rejected, (state, action) => {
        state.chatLoadStatus = "failed";
      })
      .addCase(getStarredThreads.fulfilled, (state, action) => {
        state.starredThreads = action.payload;
        state.starredStatus = "succeeded";
      })
      .addCase(getStarredThreads.pending, (state, action) => {
        state.starredStatus = "loading";
      })
      .addCase(getStarredThreads.rejected, (state, action) => {
        state.starredStatus = "failed";
      })
      .addCase(getDirectMessages.fulfilled, (state, action) => {
        if (action.payload.uniqueUsers && Array.isArray(action.payload.uniqueUsers)) {
          state.DirectMessages = action.payload.uniqueUsers;
          state.fetchDms = false;
          state.fetchDmsStatus = "succeeded";
        }
      })
      .addCase(getDirectMessages.pending, (state, action) => {
        state.fetchDms = true;
        state.fetchDmsStatus = "loading";
      })
      .addCase(getDirectMessages.rejected, (state, action) => {
        state.fetchDms = false;
        state.fetchDmsStatus = "failed";
      });
  },
});

export const {
  newQuery,
  likedChat,
  reportChat,
  updateLimitedChat,
  deleteUserLimitedChat,
  updateChat,
  clearAllStates,
  clearChatQueries,
  addNewChat,
  updateChatNotification,
  updateThreadNotificatioOnOrOff,
  updateMainThreadNotification,
  changeMainThreadNotification,
  resetMainThreadNotification,
  starredThread,
  updateStarredThread,
  resetDMNotification,
  updateDMNotification,
  addNewDM,
} = finChat.actions;

export const finChatReducer = finChat.reducer;
