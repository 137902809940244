import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import apiClient from "../../utils/axiosInstance";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Header from "../../Components/HomePage/Header";

const validationSchema = ({ isInvited, hasProfilePicture }) => {
  console.log("Validation Context:", { isInvited, hasProfilePicture });
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    businessName: !isInvited
      ? Yup.string().required("Business Name is required")
      : Yup.string().notRequired(),
    // photo: !hasProfilePicture
    //   ? Yup.mixed().required("Photo is required")
    //   : Yup.mixed().notRequired(),
  });
};

function CreateProfile() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [email, setEmail] = useState("");
  const [isInvited, setIsInvited] = useState(false);
  const location = useLocation();
  const { name, profilePicture } = location.state || {};

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const decodeToken = jwtDecode(token);
        if (decodeToken.email) {
          setEmail(decodeToken.email);
          const res = await apiClient().get(`/orgmember/${decodeToken.email}`);
          if (res.status === 200 && res.data.isInvited === true) {
            setIsInvited(true);
          }
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.log("Token Error:", error);
        navigate("/");
      }
    };
    getUserDetails();
  }, [token, navigate]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", email);
    if (!isInvited) {
      formData.append("business_name", values.businessName);
    }

    if (profilePicture) {
      formData.append("profile_picture_url", profilePicture);
    }

    if (values.photo instanceof File) {
      formData.append("photo", values.photo);
    }

    try {
      await apiClient().post("/create-profile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (isInvited) {
        const response = await apiClient().post("/login", {
          email: email,
          isDirectToLogin: true,
        });
        if (response && response.data) {
          localStorage.setItem("authToken", response.data.token);
          navigate("/dashboard");
        }
      } else {
        navigate(`/worker-email/${token}`);
      }
    } catch (error) {
      console.error("API Error:", error);
      alert("An error occurred while submitting the form. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Header />
      <div
        className="flex items-center justify-center"
        style={{ height: "calc(100vh - 81px)" }}
      >
        <div className="bg-white p-6 rounded w-full max-w-sm">
          <h2 className="text-center text-2xl font-bold mb-5">
            Create your Profile
          </h2>
          <Formik
            initialValues={{
              name: name || "",
              businessName: "",
              photo: null,
            }}
            validationSchema={() =>
              validationSchema({
                isInvited,
                hasProfilePicture: !!profilePicture,
              })
            }
            onSubmit={handleSubmit}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
          // context={{
          //   isInvited,
          //   hasProfilePicture: !!profilePicture,
          // }}
          >
            {({ isSubmitting, setFieldValue, values }) => {
              return (
                <Form>
                  <div className="mb-4 flex justify-center flex-col items-center">
                    <div
                      className="border-2 border-borderColor rounded-md flex justify-center flex-col items-center p-4 w-48 h-48 cursor-pointer"
                      onClick={() => document.getElementById("photo").click()}
                    >
                      {values.photo || profilePicture ? (
                        <img
                          src={
                            values.photo
                              ? URL.createObjectURL(values.photo)
                              : profilePicture
                          }
                          alt="Uploaded"
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <>
                          <Icon
                            icon="tabler:user-square"
                            width="2.5em"
                            height="2.5em"
                          />
                          <label
                            htmlFor="photo"
                            className="block text-sm font-medium text-center"
                          >
                            Drag & Drop or Click to Add a photo
                          </label>
                        </>
                      )}
                      <input
                        type="file"
                        id="photo"
                        accept="image/*"
                        className="hidden"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          if (file) {
                            setFieldValue("photo", file);
                          }
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="photo"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="block text-lg font-semibold"
                    >
                      Name
                    </label>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="Your Name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {!isInvited && (
                    <div className="mb-4">
                      <label
                        htmlFor="businessName"
                        className="block text-lg font-semibold"
                      >
                        Business Name
                      </label>
                      <Field
                        type="text"
                        id="businessName"
                        name="businessName"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
                        placeholder="Your Business Name"
                      />
                      <ErrorMessage
                        name="businessName"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  )}

                  <button
                    type="submit"
                    className="w-full bg-lendingButton text-white py-2 px-4 rounded hover:bg-[#35135b] focus:outline-none focus:bg-[#35135b]"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Continue"}
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateProfile;
