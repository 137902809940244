import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";

export const getOrganization = createAsyncThunk("getOrganization", async () => {
    try {
        const response = await apiClient().get(`/organization`);
        return response.data.organization;
    } catch (error) {
        localStorage.removeItem("authToken");
        window.location.href = "/";
        console.log("error in chat slice:", error);
        toast.error(`${error}`);
        throw error;
    }
});

export const organization = createSlice({
    name: "organization",
    initialState: {
        organization: [],
        organizationStatus: null,
        isPlanActive: true,
    },
    reducers: {
        addMemberToOrganization: (state, action) => {
            state.organization[0].members.push(action.payload);
        },
        removeMemberFromOrganization: (state, action) => {
            state.organization[0].members = state.organization[0].members.filter(
                (member) => member.userId !== action.payload
            );
        },
        updateMemberRole: (state, action) => {
            state.organization[0].members = state.organization[0].members.map((member) => {
                if (member.userId === action.payload.userId) {
                    member.role = action.payload.role;
                }
                return member;
            });
        },
        resetMemberNotification: (state, action) => {
            state.organization[0].members = state.organization[0].members.map((member) => {
                if (member.userId === action.payload.memberId) {
                    member.notification = 0;
                }
                return member;
            });
        },
        updateMemberNotification: (state, action) => {
            state.organization[0].members = state.organization[0].members.map((member) => {
                if (member.userId === action.payload.memberId) {
                    member.notification = member.notification + 1;
                }
                return member;
            });
        },
        updateOrgMemberStatuses: (state, action) => {
            const { email, status } = action.payload;
            state.organization[0].members = state.organization[0].members.map((member) => {
                if (member.email === email) {
                    return { ...member, user_status: status };
                }
                return member;
            });
        },
        updateOrgMember: (state, action) => {
            const updatedMember = action.payload;
            state.organization[0].members = state.organization[0].members.map(member =>
                member.userId === updatedMember.userId ? { ...member, ...updatedMember } : member
            );
        },
        uploadLogo: (state, action) => {
            const { logoS3Loc, logoUrl } = action.payload;
            state.organization[0].logoS3Loc = logoS3Loc;
            state.organization[0].logoUrl = logoUrl;
        },
        toggleAiSetting: (state, action) => {
            const { settingName, value } = action.payload;
            state.organization[0][settingName] = value;
        },
        toggleAiSettingAvaliable: (state, action) => {
            const { settingName, value } = action.payload;
            state.organization[0][settingName] = value;
        },
        updateOrganizationKeyValue: (state, action) => {
            const { key, value } = action.payload;
            state.organization[0][key] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrganization.fulfilled, (state, action) => {
                state.organizationStatus = "succeeded";
                state.organization = action.payload;
                const superAdmin = action.payload[0].members.find(member => member.role === "super admin");
                if(action.payload[0].FreeTrial || superAdmin.isSubscribe){
                    state.isPlanActive = true;
                }else{
                    state.isPlanActive = false;
                }
            })
            .addCase(getOrganization.pending, (state, action) => {
                state.organizationStatus = "loading";
            })
            .addCase(getOrganization.rejected, (state, action) => {
                state.organizationStatus = "failed";
            });
    },
});

export const { addMemberToOrganization, removeMemberFromOrganization, updateMemberRole, resetMemberNotification, updateMemberNotification, updateOrgMemberStatuses, updateOrgMember, uploadLogo, toggleAiSetting, toggleAiSettingAvaliable, updateOrganizationKeyValue } = organization.actions;
export const organizationReducer = organization.reducer;
