import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useAppDispatch, useAppSelector } from "../store";
import { EditorState, Modifier } from "draft-js";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";
import opanAilogo from "../assets/openai-logo.webp";
import geminiLogo from "../assets/Gemini2.webp";
import anthropicLogo from "../assets/anthorpic.jpeg";
import { CiGlobe } from "react-icons/ci";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { newQuery, updateStarredThread } from "../store/finChat";
import { LuBookMarked } from "react-icons/lu";
import PromptLibraryPopup from "./PromptPopup";

const AiMessageEditor = ({
    organization,
    setIsChatLoading,
    isChatLoading,
    editorState,
    setEditorState,
    setQuestion,
    questions,
    answers,
    threadId,
    chatQueries
}) => {
    const dispatch = useAppDispatch();
    const { modelState, userDetailsState, isPlanActive } =
        useAppSelector((state) => ({
            modelState: state.aiModels.aiModels,
            userDetailsState: state.auth.userDetails,
            isPlanActive: state.organization.isPlanActive,
        }));

    const [defaultModel, setDefaultModel] = useState(null);
    const [allModels, setAllModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);

    const [isWebSearched, setIsWebSearched] = useState(false);

    const [disableModel, setDisableModel] = useState(false);

    const { organizationState } = useAppSelector((state) => ({
        organizationState: state.organization.organization,
    }));

    const [isPromptPopupOpen, setIsPromptPopupOpen] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState(null);


    useEffect(() => {
        if (chatQueries?.chat?.threadType === "WebChat") {
            setIsWebSearched(true);
        }
    }, [chatQueries])

    useEffect(() => {
        if (modelState) {
            const filteredProviders = modelState?.providers?.filter(
                provider => provider.provider_name !== "pinecone" && provider.provider_name !== "exa"
            );

            let models = [];
            filteredProviders?.forEach((provider) => {
                if (!provider.provider_key) return;
                const providerModels = modelState.models[provider.modelId]?.map((model) => {
                    const newModel = {
                        ...model,
                        provider_name: provider.provider_name,
                        provider_key: provider.provider_key,
                        providerId: provider.modelId,
                    };

                    if (chatQueries?.chat?.Aimodel) {
                        if (chatQueries?.chat?.Aimodel === model.model) {
                            setSelectedModel(newModel);
                        }
                    } else if (provider.currentUsed && model.currentUsed) {
                        setDefaultModel(newModel);
                        setSelectedModel(newModel);
                    }
                    return newModel;
                });
                models = models.concat(providerModels);
            });
            setAllModels(models);
        }
    }, [modelState])

    useEffect(() => {
        if (isWebSearched) {
            setSelectedModel(allModels.find(model => model.model === "gemini-2.0-flash-exp"));
            setDisableModel(true);
        } else {
            setDisableModel(false);
        }
    }, [isWebSearched]);

    useEffect(() => {
        if (selectedPrompt?.prompt) {
          let newEditorState = EditorState.createEmpty();
          
          const contentState = newEditorState.getCurrentContent();
          const promptText = selectedPrompt.prompt + "\n\n";
          const newContentState = Modifier.insertText(
            contentState,
            contentState.getSelectionAfter(),
            promptText
          );
      
          newEditorState = EditorState.push(
            newEditorState,
            newContentState,
            'insert-characters'
          );
      
          newEditorState = EditorState.moveFocusToEnd(newEditorState);
          
          setEditorState(newEditorState);
          setIsPromptPopupOpen(false);
        }
      }, [selectedPrompt]);

    const handleChange = (newEditorState) => {
        setEditorState(newEditorState);
    };

    const handleReturn = (e) => {
        console.log("handle return called in Ai chat thread");
        if (!isPlanActive) {
            toast.error("Please upgrade your plan to use this feature");
            return;
        }

        const isEnterPress = e && e.key === "Enter" && !e.shiftKey;

        if (isEnterPress) {


            const contentState = editorState.getCurrentContent();
            const hasText = contentState.getPlainText().trim();

            if (hasText.trim()) {

                if (isWebSearched) {
                    handleWebChat(hasText);
                } else {
                    handleChat(hasText);
                }

            }
        }
    };

    const handleChat = async (query) => {
        setQuestion(query);
        setIsChatLoading(true);
        try {
            const body = {
                action: "chat",
                query: query,
                threadId: threadId,
                previous_queries: questions,
                previous_answers: answers,
                createdAt: new Date().toISOString(),
            };
            const response = await apiClient().post(`/chat`, body);
            dispatch(
                updateStarredThread({
                    threadId: response.data.threadId,
                    key: ["updatedAt"],
                    value: [response.data.updatedAt],
                })
            );
            dispatch(newQuery(response.data));
            setQuestion("");
            setEditorState(EditorState.createEmpty());
        } catch (error) {
            console.log("error in chat:", error);
        } finally {
            setIsChatLoading(false);
        }
    };

    const handleWebChat = async (query) => {
        setQuestion(query);
        setIsChatLoading(true);
        try {
            const body = {
                action: "webchat",
                query: query,
                threadId: threadId,
                previous_queries: questions,
                previous_answers: answers,
                createdAt: new Date().toISOString(),
            };
            const response = await apiClient().post(`/chat`, body);
            dispatch(
                updateStarredThread({
                    threadId: response.data.threadId,
                    key: ["updatedAt"],
                    value: [response.data.updatedAt],
                })
            );
            dispatch(newQuery(response.data));
            setQuestion("");
            setEditorState(EditorState.createEmpty());
        } catch (error) {
            console.log("error in chat:", error);
        } finally {
            setIsChatLoading(false);
        }
    }

    return (
        <div className="w-full">
            {userDetailsState && (
                <div className={`bg-white lg:px-4 px-2 py-2 lg:py-4`}>
                    <div className={`relative border-2 rounded-lg `}>
                        <div className="max-h-[300px] min-h-[100px] mb-2 relative">
                            {(selectedModel || defaultModel) &&
                                <div className="absolute flex items-center z-50 justify-between" style={{ top: `22px`, transform: "translateY(-50%)", right: "10px" }}>
                                    <DropdownWithLogos
                                        defaultModel={defaultModel}
                                        allModels={allModels}
                                        selectedModel={selectedModel}
                                        setSelectedModel={setSelectedModel}
                                        disableModel={disableModel}
                                    />
                                </div>}
                            {organizationState[0]?.isWebsearchUsed && <div className="absolute flex items-center  justify-between" style={{ top: `22px`, transform: "translateY(-50%)", left: "235px" }}>
                                <button
                                    onClick={() => setIsWebSearched(!isWebSearched)}
                                    className={`flex items-center rounded-full ${isWebSearched ? "bg-blue-100 text-blue-500" : ""
                                        } transition-colors duration-300 hover:bg-blue-200 p-1`}
                                >
                                    <CiGlobe size={20} />
                                    {isWebSearched && <span className="mx-1 text-sm">Search</span>}
                                </button>
                            </div>}

                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName  h-full"
                                onEditorStateChange={(editorState) => {
                                    handleChange(editorState);
                                }}
                                handleReturn={handleReturn}
                                toolbar={{
                                    options: ["inline", "list", "emoji", "link"],
                                    inline: {
                                        options: ["bold", "italic"],
                                        className: "inline-style",
                                        bold: { icon: "/bold.svg", className: "bold-button" },
                                    },
                                    list: {
                                        options: ["unordered", "ordered"],
                                        className: "inline-style",
                                    },
                                    link: {
                                        component: () => (
                                            <>
                                                <button
                                                    className="rdw-option-wrapper"
                                                    onClick={() => {
                                                        setIsPromptPopupOpen(true)}}
                                                >
                                                    <LuBookMarked className=" !text-[16px]" />
                                                </button>
                                            </>
                                        ),
                                    }
                                }}
                            />

                            {
                                isPromptPopupOpen && (
                                    <PromptLibraryPopup
                                        setIsPromptPopupOpen={setIsPromptPopupOpen}
                                        setSelectedPrompt={setSelectedPrompt}
                                        selectedPrompt={selectedPrompt}
                                    />
                                )
                            }
                        </div>
                        <div className="flex justify-end items-end px-4 py-2 relative bottom-0">
                            <div className="flex-shrink-0">
                                <button
                                    className={`px-4 py-1 rounded-md ${!editorState.getCurrentContent().hasText() || isChatLoading
                                        ? "text-borderColor cursor-not-allowed"
                                        : "text-white"
                                        }`}
                                    style={{
                                        backgroundColor: isChatLoading
                                            ? "gray"
                                            : editorState.getCurrentContent().hasText()
                                                ? organization?.primaryColor
                                                : organization?.secondaryColor,
                                    }}
                                    disabled={!editorState.getCurrentContent().hasText() || isChatLoading}
                                    onClick={(e) => {
                                        console.log("button clicked in Ai chat thread")
                                        e.stopPropagation();
                                        handleReturn({ key: "Enter" });
                                    }}
                                >
                                    {isChatLoading ? (
                                        <Icon
                                            icon="eos-icons:loading"
                                            width="1.5em"
                                            height="1.5em"
                                        />
                                    ) : (
                                        <Icon
                                            icon="mdi:paper-airplane"
                                            width="1.5em"
                                            height="1.5em"
                                        />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default AiMessageEditor;

const DropdownWithLogos = ({ defaultModel, allModels, selectedModel, setSelectedModel, disableModel }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, []);

    const getModelLogo = (providerName) => {
        switch (providerName) {
            case "openai":
                return opanAilogo;
            case "gemini":
                return geminiLogo;
            case "anthropic":
                return anthropicLogo;
            default:
                return null;
        }
    };

    return (
        <div className="relative">
            <button
                className={`flex items-center gap-2 mr-1 ${!disableModel ? "hover-effect" : ""}`}
                style={{
                    opacity: disableModel ? "0.5" : "1",
                }}
                onClick={() => setShowDropdown(!showDropdown)}
                disabled={disableModel}
            >
                {getModelLogo(selectedModel?.provider_name || defaultModel?.provider_name) && (
                    <img
                        src={getModelLogo(selectedModel?.provider_name || defaultModel?.provider_name)}
                        alt={selectedModel?.provider_name || defaultModel?.provider_name}
                        className="w-4 h-4 rounded-md"
                    />
                )}
                {selectedModel?.model || defaultModel?.model}
                {showDropdown ? <MdKeyboardArrowUp style={{ fontSize: "20px" }} /> : <MdKeyboardArrowDown style={{ fontSize: "20px" }} />}
            </button>

            {showDropdown && (
                <div
                    ref={dropdownRef}
                    className="absolute bottom-[30px] right-0 mt-2 w-[250px] h-[250px] overflow-auto bg-white shadow-md rounded-md z-50"
                >
                    {allModels.map((model, index) => {
                        const logo = getModelLogo(model.provider_name);
                        return (
                            <div
                                key={index}
                                className={`flex items-center my-1 p-2 cursor-pointer ${selectedModel?.model === model.model ? "bg-slate-500 text-white" : "hover:bg-slate-200"
                                    }`}
                                onClick={() => {
                                    setSelectedModel(model);
                                    setShowDropdown(false);
                                }}
                            >
                                {logo && <img src={logo} alt={model.provider_name} className="w-6 h-6 mr-3 rounded-md" />}
                                <p>{model.model}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};