import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import Sidebar from "../Components/Sidebar";
import teamsMemberPic from '../assets/team.png';
import avtarPic from "../assets/user.png"
import { useAppDispatch, useAppSelector } from "../store";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import apiClient from "../utils/axiosInstance";
import { addChat, getThreadChats, removeChat, resetChat, updateMessage, updateThreadMessageCounter } from "../store/ThreadSlice";
import useAuth from "../utils/useAuth";
import { ContentState, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSocket } from "../context/SocketProvider";
import { v4 as uuidv4 } from "uuid";
import { MdSend } from "react-icons/md";
import { convertEditorToMarkdown } from "../Components/MessageEditor";

import {
  MessageList,
  EmptyState,
  MessageSkeleton
} from '../Components/SharedMessageComponents';
import MessageEditor from "../Components/MessageEditor";
import { toast } from "react-toastify";
import { getOrganization } from "../store/organization";
import ReplyMessages from "../Components/ReplyMessages";
import { updateLimitedChat, updateStarredThread } from "../store/finChat";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Spinner from "react-spinner-material";

function Thread() {
  const { user } = useAuth();
  const { socket } = useSocket();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const threadId = queryParams.get('id');
  const dispatch = useAppDispatch();
  const { threadChatState, userDetailsState, organizationState, chatWithLimitState } = useAppSelector((state) => ({
    threadChatState: state.thread.threadChats,
    userDetailsState: state.auth.userDetails,
    organizationState: state.organization.organization,
    chatWithLimitState: state.finChat.chatWithLimit,
  }));

  const tempRespose = useLocation()?.state?.chat;
  const [thread, setThread] = useState({});
  const messagesEndRef = useRef(null);
  const [sharedFolders, setSharedFolders] = useState([]);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [isLoadingThread, setIsLoadingThread] = useState(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const navigate = useNavigate();
  const [mentionState, setMentionState] = useState({
    showAskFin: true,
    askFinTagged: false,
  });
  const [taggedFolders, setTaggedFolders] = useState([]);
  const [isReplying, setIsReplying] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [chateditName, setChatEditName] = useState("");

  const [memberList, setMemberList] = useState(false);
  const fetchThread = async () => {
    setIsLoadingThread(true);
    try {
      const response = await apiClient().get(`/thread/${threadId}`);
      const threadData = response?.data?.thread;
      setThread(threadData);
      setChatEditName(threadData?.chatName);
    } catch (error) {
      console.error("Error fetching thread:", error);
    } finally {
      setIsLoadingThread(false);
    }
  };

  useEffect(() => {
    if (threadId) {
      dispatch(getThreadChats({ threadId }))
    }
  }, [threadId])

  useEffect(() => {
    if (organizationState.length === 0) {
      dispatch(getOrganization());
    }
  }, [])

  useEffect(() => {
    if (thread?.tag?.length && organizationState?.length) {
      const updatedTags = thread.tag.map((obj) => {
        if (obj.accountType === "member") {
          const member = organizationState[0]?.members?.find(
            (memberObj) => memberObj.email === obj.tagId
          );
          return {
            ...obj,
            profileImage: member?.userPictureUrl || obj.profileImage,
          };
        }
        return obj;
      });

      if (JSON.stringify(updatedTags) !== JSON.stringify(thread.tag)) {
        setThread({ ...thread, tag: updatedTags });
      }
    }
  }, [thread, organizationState]);



  useEffect(() => {
    const fetchSharedFolders = async () => {
      if (thread) {
        try {
          const response = await apiClient().post(`/get-shared-folders`, {
            sharedTo: thread?.tag.map((tag) => tag.tagId),
            isForDm: false,
          });
          setSharedFolders(response.data.folders);
        } catch (error) {
          console.error('Error fetching shared folders:', error);
        }
      }
    };

    fetchSharedFolders();
  }, [threadId, thread]);

  useEffect(() => {
    const sendMessage = async () => {
      try {
        if (tempRespose) {
          setIsLoadingThread(false);
          setIsLoadingMessages(false);
          setThread({
            role: "admin",
            userId: tempRespose.userId,
            threadId: tempRespose.threadId,
            chatName: tempRespose.chatName,
            tag: tempRespose.tag,
            createdAt: tempRespose.createdAt,
            type: tempRespose.type,
          });

          const chatData = {
            createdAt: tempRespose.createdAt,
            receivers: tempRespose.receivers,
            messageId: tempRespose.messageId,
            message: tempRespose.message,
            threadId: tempRespose.threadId,
            senderId: tempRespose.senderId,
            senderName: tempRespose.senderName,
            senderProfile: tempRespose.senderProfile,
            messageType: tempRespose.messageType,
          };

          if (tempRespose.files && tempRespose.files.length > 0) {
            chatData.files = tempRespose.files;
          }
          dispatch(addChat(chatData));
          if (tempRespose?.isAiChat) {
            const messageId = uuidv4();
            const tempAiChatData = {
              createdAt: tempRespose.createdAt,
              answer: {
                answers: [
                  {
                    answer: "Cospace is thinking...",
                  }
                ]
              },
              messageId: messageId,
              isAiChat: true,
              messageType: "text",
              receivers: tempRespose.receivers,
              senderId: tempRespose.senderId,
              senderName: tempRespose.senderName,
              threadId: tempRespose.threadId,
            }
            dispatch(addChat(tempAiChatData));
            if (tempRespose.taggedFolders.length > 0) {
              try {
                const payload = {
                  threadId: tempRespose.threadId,
                  folderIds: tempRespose.taggedFolders,
                  query: tempRespose.message,
                  messageId: messageId,
                  createdAt: new Date().toISOString(),
                  senderId: tempRespose.senderId,
                  receivers: tempRespose.receivers,
                  type: "thread",
                }
                const response = await apiClient().post("/chatwithfolder", payload);
                const answer = {
                  answers: response.data.response
                }
                dispatch(updateMessage({ messageId: messageId, answer: answer }))
              } catch (error) {
                dispatch(removeChat(messageId));
                toast.error(error);
              }
            } else {
              try {
                let body = {
                  messageId: messageId,
                  query: tempRespose.message.replace(`@${organizationState[0]?.chatBotName}`, "").trim(),
                  action: "chat",
                  createdAt: new Date().toISOString(),
                  threadId: tempRespose.threadId,
                  tag: tempRespose.receivers
                };

                const response = await apiClient().post(`/chat`, body);
                dispatch(updateMessage({ messageId: messageId, answer: response.data.answer }));
              } catch (error) {
                dispatch(removeChat(messageId));
                toast.error(error);
              }

            }

          }
        } else {
          fetchThread();
          setIsLoadingMessages(true);
          dispatch(getThreadChats({ threadId }))
            .then(() => setIsLoadingMessages(false))
            .catch(() => {
              setIsLoadingMessages(false);
              console.error("Error fetching thread chats");
            });
        }
      } catch (error) {
        console.error("Error posting message:", error);

      }
    }
    sendMessage();
  }, [threadId, dispatch, tempRespose]);

  useEffect(() => {
    return () => {
      dispatch(resetChat());
      setThread({});
      setIsLoadingThread(true);
      setIsLoadingMessages(true);
      setSelectedFiles([]);
      window.history.replaceState({}, document.title);
    };
  }, [threadId, dispatch]);

  const scrollToBottom = () => {
    if (messagesEndRef.current ) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!user || !socket) return;

    socket.emit("join_thread_room", {
      room: `${threadId}`,
      userId: user.userId,
    });

    const handleNewMessage = ({ message }) => {
      if(threadId && threadId === message.threadId){
        dispatch(addChat(message));
        scrollToBottom();
        setEditorState(EditorState.createEmpty());
        setIsApiCalling(false);
      }
    };

    socket.on("receive_main_thread_message", handleNewMessage);
    socket.on("thread_reply_message", ({ message }) => {
      if (isReplying === false) {
        const data = {
          messageId: message.messageId,
          profile: message.profile
        }
        dispatch(updateThreadMessageCounter(data));
      }

    })

    socket.on("change_thread_name_response", (data) => {
      setThread({ ...thread, chatName: data.chatName });
      dispatch(updateLimitedChat({ threadId: data.threadId, chatName: data.chatName }));
    })

    return () => {
      socket.emit("leave_thread_room", { room: `${threadId}` });
      socket.off("receive_main_thread_message", handleNewMessage);
      socket.off("thread_reply_message");
      socket.off("change_thread_name_response")
    };
  }, [threadId, user, socket, dispatch, isReplying]);

  useEffect(() => {
    if (!isReplying){
      
      scrollToBottom();
    }
  }, [threadChatState]);

  const handleSendMessage = async (editorState) => {
    const hasText = editorState.getCurrentContent().hasText();

    const hasFiles = selectedFiles.length > 0;

    if ((!hasText && !hasFiles) || uploadingFiles.length > 0) return;

    try {
      setIsApiCalling(true);

      const contentState = editorState.getCurrentContent();
      const plainText = contentState.getPlainText();
      const containsAskfinAI = plainText.includes(
        `@${organizationState[0]?.chatBotName}`
      );
      const messageText = plainText
        .replace(`@${organizationState[0]?.chatBotName}`, "")
        .trim();
      let message = "";

      if(containsAskfinAI){
        message = messageText;
      }else{
        message = convertEditorToMarkdown(editorState);
      }

      const baseMessageData = {
        threadId: threadId,
        messageId: uuidv4(),
        isThread: false,
        tag: thread?.tag,
        createdAt: new Date().toISOString(),
        senderId: user?.userId,
        senderName: userDetailsState?.name,
        senderProfile: userDetailsState?.userPictureUrl,
      };

      let messageData;

      if (hasText && hasFiles) {
        messageData = {
          ...baseMessageData,
          message: message,
          messageType: "text-file",
          sharedFiles: selectedFiles.map((file) => ({
            docS3Loc: file.docS3Loc,
            url: file.url,
            fileName: file.file.name,
          })),
          uploaded_shared_files: selectedFiles.map((file) => ({
            docS3Loc: file.docS3Loc,
            url: file.url,
            fileName: file.file.name,
          })),
        };
      } else if (hasText) {
        messageData = {
          ...baseMessageData,
          message: message,
          messageType: "text",
        };
      } else if (hasFiles) {
        messageData = {
          ...baseMessageData,
          messageType: "file",
          sharedFiles: selectedFiles.map((file) => ({
            docS3Loc: file.docS3Loc,
            url: file.url,
            fileName: file.file.name,
          })),
          uploaded_shared_files: selectedFiles.map((file) => ({
            docS3Loc: file.docS3Loc,
            url: file.url,
            fileName: file.file.name,
          })),
        };
      }

      await sendMessage(messageData);
      setIsApiCalling(false);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsApiCalling(false);
    }
  };

  const sendMessage = async (messageData) => {
    let messageId = uuidv4();
    const data = {
      room: `${threadId}`,
      message: messageData,
      tag: thread?.tag,
      threadId: threadId,
    };

    socket.emit("new_main_thread_message", data);

    if (mentionState.askFinTagged || messageData.message.includes(`@${organizationState[0]?.chatBotName}`)) {
      dispatch(addChat(messageData));
      const tempAiData = {
        createdAt: new Date().toISOString(),
        answer: {
          answers: [
            {
              answer: `${organizationState[0]?.chatBotName} is thinking...`,
            }
          ]
        },
        messageId: messageId,
        isAiChat: true,
        messageType: "text",
        receivers: messageData.tag,
        senderId: messageData.senderId,
        senderName: messageData.senderName,
        threadId: threadId,
      }
      dispatch(addChat(tempAiData));
    } else {
      dispatch(addChat(messageData));
    }

    setEditorState(EditorState.createEmpty());
    setSelectedFiles([]);
    scrollToBottom();

    try {
      const apiMessageData = {
        threadId: messageData.threadId,
        messageId: messageData.messageId,
        isThread: false,
        tag: messageData.tag,
        createdAt: messageData.createdAt,
        messageType: messageData.messageType,
      };

      if (messageData.messageType === "text" || messageData.messageType === "text-file") {
        apiMessageData.message = messageData.message;
      }

      if (messageData.messageType === "file" || messageData.messageType === "text-file") {
        apiMessageData.sharedFiles = messageData.sharedFiles;
      }

      const { data } = await apiClient().post("/thread", apiMessageData);
      dispatch(updateStarredThread({ threadId: data.threadChat.threadId, key: ["updatedAt"], value: [data.threadChat.updatedAt] }));

      if (mentionState.askFinTagged || messageData.message.includes(`@${organizationState[0]?.chatBotName}`)) {
        if (taggedFolders.length > 0) {
          try {
            const payload = {
              threadId: threadId,
              folderIds: taggedFolders,
              query: messageData.message,
              messageId: messageId,
              createdAt: new Date().toISOString(),
              senderId: messageData.senderId,
              receivers: messageData.tag,
              type: "thread",
            }
            const response = await apiClient().post("/chatwithfolder", payload);
            const answer = {
              answers: response.data.response
            }
            dispatch(updateMessage({ messageId: messageId, answer: answer }));
          } catch (error) {
            dispatch(removeChat(messageId));
            toast.error(error);
          }
        } else {
          try {
            let body = {
              messageId: messageId,
              query: messageData.message.replace(`@${organizationState[0]?.chatBotName}`, "").trim(),
              action: "chat",
              createdAt: new Date().toISOString(),
              threadId: threadId,
              tag: messageData.tag,
            };

            const response = await apiClient().post(`/chat`, body);
            dispatch(updateMessage({ messageId: messageId, answer: response.data.answer }));
          } catch (error) {
            dispatch(removeChat(messageId));
            toast.error(error);
          }
        }
      }
    } catch (error) {
      console.error("Error posting message:", error);
    }
  };

  const handleRelatedQueryClick = (query) => {
    setMentionState({ ...mentionState, askFinTagged: true });
    const prefixedQuery = `@${organizationState[0]?.chatBotName} ${query}`;
    const newEditorState = EditorState.createWithContent(ContentState.createFromText(prefixedQuery));
    handleSendMessage(newEditorState);
  };

  const handleEditClick = (chatEditName) => {
    setIsEditing(true);
    handleSaveClick(chatEditName);
  };

  const handleInputChange = (e) => {
    setChatEditName(e.target.value);
  };

  const handleSaveClick = async (chatEditName) => {
    try {
      const response = await apiClient().put(
        `/thread/name-update/${thread?.threadId}`,
        { newName: chatEditName }
      );

      if (response.status === 200) {
        setIsEditing(false);

        const fetchedThread = await apiClient().get(`/thread/${thread?.threadId}`);
        socket.emit("change_thread_name", { threadId: thread?.threadId, chatName: chatEditName });
        setThread(fetchedThread.data.thread);
        dispatch(updateLimitedChat({ threadId: thread?.threadId, chatName: chatEditName }));
      }

    } catch (error) {
      console.error('Error updating chat name:', error);
    }
  };



  return (
    <>
      {chatWithLimitState.history === 0 ? <>
        <div className="flex flex-col items-center justify-center w-full header-height text-gray-500">
          <Icon icon="carbon:chat" width="100" height="100" />
          <h2 className="mt-4 text-xl font-semibold">No Conversation Yet!</h2>
        </div>
      </> : <>
        <div className="flex justify-between py-1 px-2 header-height" style={{ width: "calc(100vw - 366px)" }}>
          <div className={`flex pr-2 flex-col ${isReplying ? "w-[60%]" : "w-full"} my-pb-20 `} >
            <div className={`border-b-2  ${!isReplying && "xl:px-5"}  rounded transition-all duration-300 ease-in-out`}>
              <div className="close-btn md:hidden" >
                <button>
                  <Icon
                    icon="carbon:close"
                    className="w-6 h-6 text-textPrimary"
                    onClick={() => navigate('/dashboard')}
                  />
                </button>
              </div>
              <div className="flex items-center justify-between text-xl font-semibold">
                {isLoadingThread ? (
                  <div className=" bg-gray-200 rounded  animate-pulse my-5 w-[400px] h-[28px]"></div>
                ) : isEditing ? (
                  <div className="flex items-center gap-4 text-[20px] h-[68px]">
                    <Icon
                      icon="tdesign:check"
                      width="0.8em"
                      height="0.8em"
                      className="cursor-pointer"
                      onClick={() => handleEditClick(chateditName)}
                      aria-label="Save chat name"
                    />
                    <div className="relative border-2 p-2 border-gray-100 rounded-md ">
                      <input
                        type="text"
                        value={chateditName}
                        onChange={handleInputChange}
                        className="border-0 focus:outline-none p-0 px-2 text-xl "
                        aria-label="Edit chat name"
                      />
                    </div>
                  </div>

                ) : (
                  <>
                    <div className="flex items-center gap-4 text-[20px] h-[68px]">
                      <Icon
                        icon="tdesign:edit"
                        width="0.8em"
                        height="0.8em"
                        className="ml-1 cursor-pointer"
                        onClick={handleEditClick}
                      />
                      {thread?.chatName}
                    </div>

                    <div className="md:block flex items-center justify-between">
                      <div className="rounded transition-all duration-300 ease-in-out">
                        <div className="flex items-center justify-between p-1.5 md:p-0">
                          <div className="flex items-center justify-between border  rounded-md border-borderColor cursor-pointer">
                            {thread?.tag &&
                              thread?.tag?.slice(0, 3)?.map((tagItem, index) => {
                                return <>
                                  <div className="-ml-3 first:ml-0 relative first:me-0 last:me-0 me-1">
                                    <div
                                      className="w-8 h-8 rounded-md p-1 flex items-center justify-center text-white font-semibold bg-white"
                                      onClick={() => setMemberList(true)}
                                    >
                                      <img src={tagItem.accountType === "team" ? teamsMemberPic : tagItem?.profileImage || avtarPic} alt={tagItem.tagName} className="rounded-md w-full h-full object-cover" />
                                    </div>
                                    {
                                      tagItem?.accountType === "member" && <div
                                        className={`absolute -top-0 right-[0.4rem] w-2 h-2 rounded-full border border-white ${tagItem?.accountType === "thread" && tagItem?.status === "active" ? "bg-green-500" : "bg-gray-400"
                                          }`}
                                      ></div>
                                    }

                                  </div>
                                </>
                              })}
                            {thread?.tag?.length > 0 &&
                              <div className=" relative">
                                <div
                                  className="w-6 h-6 flex items-center justify-center text-black text-sm font-medium"
                                  // style={{ backgroundColor: organizationState[0]?.secondaryColor }}
                                  onClick={() => setMemberList(true)}
                                >
                                  {thread?.tag?.length}
                                </div>
                              </div>
                            }
                            {thread?.tag?.length === 0 && <>
                              <div className="-ml-2 relative">
                                <div
                                  className="w-8 h-8 rounded-md flex items-center justify-center text-white font-semibold"
                                  style={{ backgroundColor: organizationState[0]?.secondaryColor }}
                                  onClick={() => setMemberList(true)}
                                >
                                  +
                                </div>
                              </div>
                            </>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

            </div>
            <div className="flex-1 flex flex-col overflow-hidden bg-white">
              <div className="flex-1 overflow-y-auto ">
                {isLoadingMessages ? (
                  <>
                    <MessageSkeleton />
                    <MessageSkeleton />
                    <MessageSkeleton />
                  </>
                ) : threadChatState.length > 0 ? (
                  <MessageList messages={threadChatState} handleRelatedQueryClick={handleRelatedQueryClick} isReplying={isReplying} setIsReplying={setIsReplying} tab="thread" />
                ) : (
                  <EmptyState />
                )}
                <div ref={messagesEndRef} />
              </div>
              <MessageEditor
                editorState={editorState}
                organization={organizationState[0]}
                tags={thread?.tag}
                setEditorState={setEditorState}
                handleSendMessage={handleSendMessage}
                isApiCalling={isApiCalling}
                setIsApiCalling={setIsApiCalling}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                uploadingFiles={uploadingFiles}
                setUploadingFiles={setUploadingFiles}
                mentionState={mentionState}
                setMentionState={setMentionState}
                taggedFolders={taggedFolders}
                setTaggedFolders={setTaggedFolders}
                sharedFolders={sharedFolders}
                tab="thread"
                isReplying={isReplying}
              />
            </div>
          </div>
          {isReplying && <ReplyMessages isReplying={isReplying} setIsReplying={setIsReplying} sharedFolders={sharedFolders} user={user} socket={socket} tab="thread" id={threadId} notification={thread?.chatName} organization={organizationState[0]} />}
        </div>
        {memberList && <MemberList setThread={setThread} setMemberList={setMemberList} tagList={thread?.tag || []} thread={thread} />}
      </>}
    </>
  );
}

export const MemberList = ({ setMemberList, tagList = [], thread, setThread }) => {
  const { threadChatState, userDetailsState, organizationState } = useAppSelector((state) => ({
    threadChatState: state.thread.threadChats,
    userDetailsState: state.auth.userDetails,
    organizationState: state.organization.organization,
  }));
  const { user } = useAuth();

  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);

  const [dropdownItems, setDropdownItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingDeleteId, setLoadingDeleteId] = useState(null);


  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setInputValue("");
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleToChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    const lastAtIndex = value.lastIndexOf("@");
    const lastHashIndex = value.lastIndexOf("#");

    if (lastAtIndex !== -1 && lastAtIndex > lastHashIndex) {
      const query = value.slice(lastAtIndex + 1);
      const taggedTeamMembers = getMembersFromTaggedTeams();
      const allActiveMembers = getAllActiveMembers();

      const filteredMembers = allActiveMembers.filter(
        (member) =>
          member.name.toLowerCase().includes(query.toLowerCase()) &&
          !isMemberTagged(member.id) &&
          !taggedTeamMembers.includes(member.id)
      );
      setDropdownItems(filteredMembers);
      setShowDropdown(filteredMembers.length > 0);
    } else if (lastHashIndex !== -1) {
      const query = value.slice(lastHashIndex + 1);
      const teams =
        organizationState[0]?.teams.map((team) => ({
          id: team.teamId,
          type: "team",
          name: team.teamName,
        })) || [];
      const filteredTeams = teams.filter(
        (team) =>
          team.name.toLowerCase().includes(query.toLowerCase()) &&
          !isTeamTagged(team.id)
      );
      setDropdownItems(filteredTeams);
      setShowDropdown(filteredTeams.length > 0);
    } else {
      setShowDropdown(false);
    }
  };

  const handleItemClick = (item) => {
    const newTag = {
      id: item.id,
      type: item.type,
      name: item.name,
      prefix: item.type === "team" ? "#" : "@",
      removable: true,
      userId: item?.userId,
    };
    if (item.type === "team") {
      const taggedTeamMembers = organizationState[0]?.teams
        ?.filter((team) => team.teamId === item.id)
        .flatMap((team) => team.members.map((member) => member.email)) || [];
      setTags((prevTags) => {
        const updatedTags = prevTags.filter((tag) => !taggedTeamMembers.includes(tag.id));
        return [...updatedTags, newTag];
      });
    } else {
      setTags((prevTags) => {
        return [...(prevTags || []), newTag];
      });
    }
    setInputValue("");
    setShowDropdown(false);
  };




  const isTeamTagged = (teamId) => {
    return (
      tags?.some((tag) => tag.type === "team" && tag.id === teamId) || false
    );
  };

  const isMemberTagged = (memberId) => {
    return (
      tags?.some((tag) => tag.type === "member" && tag.id === memberId) || false
    );
  };

  const getAllActiveMembers = () => {
    return organizationState[0]?.members
      .filter((member) => member.isAccepted)
      .filter((member) => member.userId !== user?.userId)
      .map((member) => ({
        id: member.email,
        type: "member",
        name: member.name,
        userId: member.userId,
      }));
  };

  const getMembersFromTaggedTeams = () => {
    if (!tags || tags.length === 0) {
      return [];
    }
    const taggedTeamIds = tags
      .filter((tag) => tag.type === "team")
      .map((tag) => tag.id);
    return (
      organizationState[0]?.teams
        ?.filter((team) => taggedTeamIds.includes(team.teamId))
        .flatMap((team) => team.members.map((member) => member.email)) || []
    );
  };

  const removeTag = (index) => {
    const tagToRemove = tags[index];
    setTags((prevTags) => {
      const newTags = prevTags.filter((_, i) => i !== index);
      return newTags;
    });
  };

  const addmemberinThread = async () => {
    const member = [];
    const team = [];

    tags?.forEach((tag) => {
      if (tag?.type === "team") {
        team.push({ teamId: tag?.id });
      } else if (tag?.type === "member") {
        member.push({ tagId: tag?.id });
      }
    });

    const threadId = thread?.threadId;

    if (member.length > 0 || team.length > 0) {
      try {
        setLoadingAdd(true);
        const response = await apiClient().post(`/thread/user-add/${threadId}`, {
          tagId: member,
          teamId: team,
        });

        setThread((prevThread) => ({
          ...prevThread,
          tag: [...response.data.created_threads[0].tag],
        }));
        setTags([]);
      } catch (error) {
        console.error("Error adding member:", error);
      } finally {
        setLoadingAdd(false);
      }
    } else {
      console.error("Missing memberId or threadId");
    }
  };

  const handleRemoveMember = async (member) => {
    const memberId = member?.tagId;
    const threadId = thread?.threadId;
    setLoadingDeleteId(memberId);

    if (memberId && threadId) {
      try {
        const params =
          member?.accountType === "team" ? { teamId: memberId } : { tagId: memberId };

        const response = await apiClient().post(`/thread/user-remove/${threadId}`, params);

        const updatedTags = thread?.tag?.filter((tag) => tag?.tagId !== memberId);

        setThread((prevThread) => ({
          ...prevThread,
          tag: updatedTags,
        }));
      } catch (error) {
        console.error("Error removing member:", error);
      } finally {
        setLoadingDeleteId(null);
      }
    } else {
      console.error("Missing memberId or threadId");
    }
  };

  const isAdmin = true
  return <>
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl w-[350px] md:w-[600px]">
        <div className="my-6">
          <div className="flex items-center justify-between mx-4">
            <h2 className="text-2xl font-bold mb-4 ">All Members</h2>
            <button
              onClick={() => {
                setMemberList(false);
              }}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <Icon icon="iconamoon:close" width="1.5em" height="1.5em" />
            </button>
          </div>
          <div className="max-h-96 overflow-y-auto mx-2">
            <div
              className="flex items-center px-2 py-3 cursor-pointer"
              onClick={() => { }}
            >
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold">
                  <Icon
                    icon="icon-park-outline:people-plus-one"
                    fontSize={30}
                    style={{ color: "black" }}
                  />
                </div>
              </div>

              <div className="flex flex-wrap items-center gap-2 ml-2 w-full">
                {tags
                  ?.filter((tag) => tag.type === "team" || tag.type === "member")
                  .map((tag, index) => (
                    <span
                      key={index}
                      className={`px-3 py-1 rounded-full flex items-center text-sm ${tag.type === "team"
                        ? "bg-green-100 text-green-800"
                        : "bg-purple-100 text-purple-800"
                        }`}
                    >
                      {tag.prefix}
                      {tag.name}
                      <button
                        onClick={() => removeTag(index)}
                        className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                      >
                        <Icon icon="mdi:close" className="w-4 h-4" />
                      </button>
                    </span>
                  ))}
                <input
                  value={inputValue}
                  onChange={handleToChange}
                  className="flex-grow min-w-[200px] border-none focus:outline-none focus:ring-0 text-sm"
                  placeholder={!tags?.length > 0 && "Add Members (#spaces @member)"}
                />
                <div
                  className="px-4 py-2 rounded-xl flex items-center justify-center"
                  style={{
                    backgroundColor: organizationState[0]?.secondaryColor, // Fallback color
                  }}
                  onClick={addmemberinThread}
                >
                  {
                    loadingAdd ? (
                      <Icon icon="eos-icons:loading" width="1.5em" height="1.5em" />
                    ) : (
                      <MdSend size={20} color="#ffffff" />
                    )
                  } {/* Adjust size and color */}
                </div>
              </div>
              {showDropdown && dropdownItems.length > 0 && (
                <div
                  ref={dropdownRef}
                  className="absolute z-10 bg-white shadow-lg max-h-60 rounded-lg overflow-auto mt-2 mx-4  w-[300px]"
                >
                  {dropdownItems.map((item, index) => {
                    let shouldShow = true;
                    if (item.type === "team") {
                      shouldShow = !tagList.some((tag) => tag.tagId === item.id);
                    } else {
                      const taggedTeamMembers = getMembersFromTaggedTeams();
                      shouldShow = ![...taggedTeamMembers, ...tagList].some((tag) => tag.tagId === item.id);
                    }
                    return (
                      <div
                        key={index}
                        onClick={() => handleItemClick(item)}
                        className="cursor-pointer select-none relative py-2 px-3 hover:bg-gray-100"
                        style={{ display: shouldShow ? "flex" : "none" }}
                      >
                        <span className="font-normal truncate flex items-center">
                          <Icon
                            icon={
                              item.type === "team"
                                ? "fluent:people-team-16-regular"
                                : "carbon:user-multiple"
                            }
                            className={`mr-2 ${item.type === "team"
                              ? "text-green-500"
                              : "text-purple-500"
                              }`}
                          />
                          {item.name}
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
            {tagList.map((member) => (
              <div
                key={member.tagId}
                className="flex items-center justify-between px-2 py-3  hover:bg-gray-100 cursor-pointer"
              >
                <div className="flex items-center w-[160px]">
                  <div className="-ml-2 first:ml-0 relative">
                    <div className="w-8 h-8 rounded-full overflow-hidden flex items-center justify-center text-white font-semibold">
                      <img src={member.accountType === "team" ? teamsMemberPic : member?.profileImage || avtarPic} alt={member.tagName} className="rounded-full w-full object-cover" />
                    </div>
                    {
                      member.accountType === "member" && (
                        <div
                          className={`absolute top-0 right-0 w-3 h-3 rounded-full border-2 border-white ${member.user_status === "active"
                            ? "bg-green-500"
                            : "bg-gray-400"
                            }`}
                        ></div>
                      )
                    }

                  </div>
                  <span className="ml-2 text-sm font-normal">
                    {member.tagName}
                  </span>
                </div>
                <div className="flex items-center justify-center">
                  <span>{member.accountType}</span>
                </div>
                <div>
                  <span className="text-sm text-gray-500">
                    {isAdmin && member.tagId !== user?.email && (
                      <button
                        onClick={() => handleRemoveMember(member)}
                        className="p-2 text-md hover:underline "
                        style={{ color: organizationState[0]?.secondaryColor }}
                      >
                        {

                          loadingDeleteId && loadingDeleteId === member.tagId ? (
                            <Icon icon="eos-icons:loading" width="1.5em" height="1.5em" />
                          ) : (
                            "Remove"
                          )
                        }
                      </button>
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Thread;