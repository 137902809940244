import AIModelsSection from "../AIModelsSection";


const Aiseeting = ({org, userDetailsState, user}) => {
    return (
        <>
        <div>
            <AIModelsSection organization={org} userDetailsState={userDetailsState} user={user} />
        </div>
        </>
    )
}


export default Aiseeting;