import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { getOrganization } from "../store/organization";
import { ContentState, EditorState } from "draft-js";
import useAuth from "../utils/useAuth";
import {
  addemojiinDirectMessage,
  addNewDirectMessage,
  getDirectMessages,
  removeDirectMessage,
  resetDirectMessages,
  updateDirectMessage,
  updateDirectMessageCounter,
} from "../store/ThreadSlice";
import { useSocket } from "../context/SocketProvider";
import apiClient from "../utils/axiosInstance";
import DashboardSidebar from "./DashboardSidebar";
import Sidebar from "../Components/Sidebar";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  MessageList,
  EmptyState,
  MessageSkeleton,
} from "../Components/SharedMessageComponents";
import MessageEditor from "../Components/MessageEditor";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import ReplyMessages from "../Components/ReplyMessages";
import { convertEditorToMarkdown } from "../Components/MessageEditor";

function DirectMessage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const memberId = queryParams.get('id');
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const messagesEndRef = useRef(null);
  const { socket } = useSocket();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [isLoadingOrganization, setIsLoadingOrganization] = useState(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [sharedFolders, setSharedFolders] = useState([]);
  const [isReplying, setIsReplying] = useState(false);
  const [currentModel, setCurrentModel] = useState("");
  const { organizationState, directMessageState, userDetailsState, modelState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
    directMessageState: state.thread.directMessages,
    userDetailsState: state.auth.userDetails,
    modelState: state.aiModels.aiModels,
  }));
  const [mentionState, setMentionState] = useState({
    showAskFin: true,
    askFinTagged: false,
  });
  const [taggedFolders, setTaggedFolders] = useState([]);

  const member =
    organizationState && organizationState.length > 0
      ? organizationState[0]?.members?.find(
        (member) => member.userId === memberId
      )
      : null;

  const dms = organizationState[0]?.members?.filter((member) => member.userId !== user?.userId && member.isAccepted === true)

  const scrollToBottom = useCallback(() => {
    
    
    
    
    if (messagesEndRef.current && isReplying === false) {
    
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    console.log("isReplying", isReplying);
  }, [isReplying])

  useEffect(() => {
    const fetchSharedFolders = async () => {
      if (memberId && user) {
        try {
          const payload = {
            senderId: user?.userId,
            receiverId: memberId,
            isForDm: true,
          };
          const response = await apiClient().post(`/get-shared-folders`, payload);
          setSharedFolders(response.data.folders);
        } catch (error) {
          console.error('Error fetching shared folders:', error);
        }
      }
    };
    fetchSharedFolders();
  }, [memberId, user]);

  useEffect(() => {
    if (organizationState?.length === 0) {
      dispatch(getOrganization())
    }
  }, [dispatch, organizationState]);

  useEffect(() => {
    if (!user) return;
    setIsLoadingMessages(true);
    if (!directMessageState.length > 0 && user) {
      dispatch(
        getDirectMessages({ senderId: user?.userId, receiverId: memberId })
      ).finally(() => setIsLoadingMessages(false));
    }
    return () => dispatch(resetDirectMessages());
  }, [memberId, user]);

  useEffect(() => {
    if (!isReplying) {
      
      scrollToBottom();
    }
    
  }, [directMessageState, scrollToBottom]);

  useEffect(() => {
    if (user && socket && memberId) {
      socket.emit("set_dm_availability", { receiverId: memberId });
    }
    return () => {
      if (user && socket && memberId) {
        socket.emit("close_dm_chat", { receiverId: memberId });
      }
    };
  }, [user, socket, memberId]);

  useEffect(() => {
    if (!user || !socket) return;

    const handleNewMessage = ({ message }) => {
      
      
      dispatch(addNewDirectMessage(message));
      scrollToBottom();
      setEditorState(EditorState.createEmpty());
      setIsApiCalling(false);
    };

    socket.on("receive_direct_message", handleNewMessage);


    socket.on("thread_reply_messages", ({ message }) => {
      if (isReplying === false) {
        const data = {
          messageId: message.messageId,
          profile: message.profile,
        }
        dispatch(updateDirectMessageCounter(data));
      }
    })
    socket.on("emoji_reply", (data) => {
      dispatch(addemojiinDirectMessage({ messageId: data.message_data.directMessageId, emojis: data.message_data.emoji }))
    });
    return () => {
      socket.off("receive_direct_message", handleNewMessage);
      socket.off("thread_reply_messages");
      socket.off("emoji_reply")
    };
  }, [memberId, user, socket, dispatch, scrollToBottom, isReplying]);

  const handleSendMessage = async (editorState,selectedModel) => {
    let message = "";
    const hasText = editorState.getCurrentContent().hasText();
    const hasFiles = selectedFiles.length > 0;

    if ((!hasText && !hasFiles) || uploadingFiles.length > 0) return;

    setIsApiCalling(true);
    message = editorState.getCurrentContent().getPlainText();

    const baseMessageData = {
      createdAt: new Date().toISOString(),
      senderId: user?.userId,
      senderName: user?.name,
      receiverId: memberId,
      isAiChat: false,
      provider_model: selectedModel?.model || "",
      provider_key: selectedModel?.provider_key || "",
      provider_name: selectedModel?.provider_name || ""
    };

    let messageData;

    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    const containsAskfinAI = plainText.includes(
      `@${organizationState[0]?.chatBotName}`
    );
    const messageText = plainText
      .replace(`@${organizationState[0]?.chatBotName}`, "")
      .trim();

    let markdownContent = "";
    if (containsAskfinAI) {
      markdownContent = messageText;
    } else {
      markdownContent = convertEditorToMarkdown(editorState);
    }
    if (hasText && hasFiles) {
      messageData = {
        ...baseMessageData,
        message: markdownContent,
        messageType: "text-file",
        sharedFiles: selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        })),
      };
    } else if (hasText) {
      messageData = {
        ...baseMessageData,
        message: markdownContent,
        messageType: "text",
      };
    } else if (hasFiles) {
      messageData = {
        ...baseMessageData,
        messageType: "file",
        sharedFiles: selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        })),
      };
    }

    await sendMessage(messageData);
    setIsApiCalling(false);
  };

  const sendMessage = async (messageData) => {
    let messageId = uuidv4();
    
    setEditorState(EditorState.createEmpty());
    setSelectedFiles([]);

    scrollToBottom();

    try {
      const apiMessageData = {
        senderId: user?.userId,
        receiverId: memberId,
        createdAt: new Date().toISOString(),
        messageType: messageData.messageType,
      };

      if (messageData.messageType === "text" || messageData.messageType === "text-file") {
        apiMessageData.message = messageData.message;
      }

      if (messageData.messageType === "file" || messageData.messageType === "text-file") {
        apiMessageData.sharedFiles = messageData.sharedFiles;
      }
      const response = await apiClient().post("/direct-message", apiMessageData);
      const data = {
        senderId: user?.userId,
        receiverId: memberId,
        message: { ...response.data.message, senderName: messageData.senderName, senderProfile: userDetailsState?.userPictureUrl },
      };
      socket.emit("direct_message", data);
      dispatch(addNewDirectMessage({ ...response.data.message, senderName: messageData.senderName, senderProfile: userDetailsState?.userPictureUrl }));
      if (mentionState.askFinTagged || messageData.message.includes(`@${organizationState[0]?.chatBotName}`)) {
        const tempAiData = {
          createdAt: new Date().toISOString(),
          answer: { answer: `${organizationState[0]?.chatBotName} is thinking...` },
          directMessageId: messageId,
          messageType: "text",
          isAiChat: true,
          receiverId: memberId,
          senderId: user?.userId,
          senderName: user?.name,
        }
        dispatch(addNewDirectMessage(tempAiData));
      }
      if (mentionState.askFinTagged || messageData.message.includes(`@${organizationState[0]?.chatBotName}`)) {
        if (taggedFolders.length > 0) {
          try {
            const response = await apiClient().post("/chatwithfolder", {
              messageId: messageId,
              folderIds: taggedFolders,
              query: messageData.message,
              createdAt: new Date().toISOString(),
              senderId: user?.userId,
              receiverId: memberId,
              type: "direct-messages",
            });
            const answer = {
              answers: response.data.response
            }
            dispatch(updateDirectMessage({ directMessageId: messageId, answer: answer }));
          } catch (error) {
            dispatch(removeDirectMessage(messageId));
            toast.error(error);
            console.log(error.message);

          }
        } else {
          try {
            let body = {
              messageId: messageId,
              isForDm: true,
              query: messageData.message.replace(`@${organizationState[0]?.chatBotName}`, "").trim(),
              action: "chat",
              provider_name: messageData.provider_name || "",
              provider_model: messageData.provider_model || "",
              provider_key: messageData.provider_key || "",
              createdAt: new Date().toISOString(),
              senderId: user?.userId,
              receiverId: memberId,
            };

            const response = await apiClient().post(`/chat`, body);
            dispatch(updateDirectMessage({ directMessageId: messageId, answer: response.data.answer }));
          } catch (error) {
            dispatch(removeDirectMessage(messageId));
            toast.error(error);
          }
        }
      }
    } catch (error) {
      console.error("Error posting message:", error);
    }
  };

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleRelatedQueryClick = (query, messageType, provider) => {
    setMentionState({ ...mentionState, askFinTagged: true });
    const prefixedQuery = `@${organizationState[0]?.chatBotName} ${query}`;
    const newEditorState = EditorState.createWithContent(ContentState.createFromText(prefixedQuery));
    if(messageType === "WebChatMessage"){
      handleWebSearch(query);
    }else{
      handleSendMessage(newEditorState, currentModel);
    }
  };

  const handleWebSearch = async (query) => {
    try{
      setIsApiCalling(true);
      const basePayload = {
        action: "webchat",
        query: query,
        createdAt: new Date().toISOString(),
      };
      let payload;
      payload = {
        ...basePayload,
        is_dm_chat: true,
        receiverId: memberId
      };
      const response = await apiClient().post("/chat", payload);
      console.log(response.data);
      dispatch(addNewDirectMessage(response.data))
      socket.emit("direct_message", response.data);
    }catch(error){
      console.error("Error posting message:", error);
    }finally{
      setIsApiCalling(false);
    }
  }

  const navigate = useNavigate()

  return (  
    <>
      {dms.length === 0 ? <>
        <div className="flex flex-col items-center justify-center w-full header-height text-gray-500">
          <Icon icon="carbon:chat" width="100" height="100" />
          <h2 className="mt-4 text-xl font-semibold">No Conversation Yet!</h2>
        </div>
      </> : (
        <div className="flex justify-between py-1 px-2 header-height" style={{ width: "calc(100vw - 366px)" }}>
          <div className={`flex flex-col ${isReplying ? "md:w-[58%]" : "w-full"} py-1 my-pb-20 `}>
            <div className={`border-b-2 ${!isReplying && "xl:px-5"} h-[70px] rounded transition-all duration-300 ease-in-out flex items-center justify-between`}>
              <div className="close-btn md:hidden" >

                <button>
                  <Icon
                    icon="carbon:close"
                    className="w-6 h-6 text-textPrimary"
                    onClick={() => navigate('/dashboard')}
                  />
                </button>
              </div>


              <h1 className="flex items-center text-xl font-semibold ">
                {
                  member?.userPictureUrl ? (
                    <img src={member?.userPictureUrl} className="w-8 h-8 me-1 rounded-full" alt="Profile" />
                  ) : (

                    <Icon icon="tdesign:member" width="1.2em" height="1.2em" className="mr-1" />
                  )
                }
                {member?.name || "Loading..."}
              </h1>
            </div>
            <div className="flex-1 flex flex-col overflow-hidden bg-white ">
              <div className="flex-1 overflow-y-auto px-0 lg:px-4 ">
                {isLoadingMessages ? (
                  <>
                    <MessageSkeleton />
                    <MessageSkeleton />
                    <MessageSkeleton />
                  </>
                ) : directMessageState.length > 0 ? (
                  <MessageList messages={directMessageState} handleRelatedQueryClick={handleRelatedQueryClick} isReplying={isReplying} organization={organizationState[0]} setIsReplying={setIsReplying} tab="dm" />
                ) : (
                  <EmptyState />
                )}
                <div ref={messagesEndRef} />
              </div>
              <MessageEditor
                editorState={editorState}
                organization={organizationState[0]}
                setEditorState={setEditorState}
                handleChange={handleChange}
                handleSendMessage={handleSendMessage}
                isApiCalling={isApiCalling}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                uploadingFiles={uploadingFiles}
                setUploadingFiles={setUploadingFiles}
                mentionState={mentionState}
                setMentionState={setMentionState}
                taggedFolders={taggedFolders}
                setTaggedFolders={setTaggedFolders}
                sharedFolders={sharedFolders}
                tab="dm"
                memberId={memberId}
                setCurrentModel={setCurrentModel}
              />
            </div>
          </div>
          {isReplying && <ReplyMessages isReplying={isReplying} setIsReplying={setIsReplying} sharedFolders={sharedFolders} id={memberId} user={user} socket={socket} tab="dm" organization={organizationState[0]} />}
        </div>
      )}
    </>
  );
}

export default DirectMessage;