import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";
const initialState = {
  authState: false,
  userDetails: null,
  status: null,
};

export const getUserDetails = createAsyncThunk(
  "getUserDetails",
  async (userId) => {
    try {
      const response = await apiClient().get(`/user/${userId}`);
      return response.data;
    } catch (error) {
      localStorage.removeItem("authToken");
      window.location.href = "/";
      console.log("error in chat slice:", error);
      toast.error(`${error}`);
      throw error;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState: (state, action) => {
      state.authState = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    updateUserDetails: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.userDetails[key] = action.payload[key];
      });
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userDetails = action.payload;
      })
      .addCase(getUserDetails.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { setAuthState, setUserDetails, updateUserDetails } =
  authSlice.actions;
export const authReducer = authSlice.reducer;
