import { useEffect, useRef, useState } from "react";
import apiClient from "../../utils/axiosInstance";
import { useAppDispatch, useAppSelector } from "../../store";
import { Icon } from "@iconify/react/dist/iconify.js";
import { updateOrganizationKeyValue, uploadLogo } from "../../store/organization";
import { format } from "date-fns";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "react-spinner-material";
import { RedditIcon } from "react-share";

const OrganizationSettings = ({ userDetailsState, org }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);
    const [loader1, setLoader1] = useState(false);

    const [newOrgName, setNewOrgName] = useState(org?.organizationName);
    const [primary, setPrimary] = useState(org?.primaryColor || "#0D2425");
    const [secondary, setSecondary] = useState(org?.secondaryColor || "#000000");

    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            if (newOrgName === org?.organizationName) {
                setIsEditing(false);
                return;
            }
            const response = await apiClient().put("/organization", {
                organizationName: newOrgName,
            })
            dispatch(updateOrganizationKeyValue({ key: "organizationName", value: response.data.organizationName }));
        } catch (error) {
            console.error("Error updating org name:", error);
        } finally {
            setIsEditing(false);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsUploading(true);
        setError(null);

        const formData = new FormData();
        formData.append('logo', file);

        try {
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            const response = await apiClient().post('/logo', formData, config);
            dispatch(uploadLogo(response.data));
        } catch (err) {
            setError('Failed to upload logo. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const handlePrimaryColorChange = debounce(async (newColor) => {
        await apiClient().post("/color-config", { primaryColor: newColor });
        dispatch(updateOrganizationKeyValue({ key: "primaryColor", value: newColor }));
    }, 500);

    const handleSecondaryColorChange = debounce(async (newColor) => {
        await apiClient().post("/color-config", { secondaryColor: newColor });
        dispatch(updateOrganizationKeyValue({ key: "secondaryColor", value: newColor }));
    }, 500);

    useEffect(() => {
        handlePrimaryColorChange(primary);
        return () => handlePrimaryColorChange.cancel();
    }, [primary]);

    useEffect(() => {
        handleSecondaryColorChange(secondary);
        return () => handleSecondaryColorChange.cancel();
    }, [secondary]);


    const confirmDeleteOrganization = async () => {
        if (loader1) return;
        try {
            setLoader1(true);
            const response = await apiClient().post("organization/delete");
            navigate("/");
            localStorage.removeItem("authToken");
        } catch (error) {
            console.log("Error Deleteing Organization", error);
            toast.error(`Error Deleteing Organization!`);
        } finally {
            setShowDeleteModel(false);
            setLoader1(false);
        }
    }

    const fileInputRef = useRef(null);
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <div>

                

                <div className="flex items-center w-full justify-between border-b-2 border-borderColor mt-5 pb-5">
                    <p className="text-lg font-normal text-secondary w-[40%]">Logo</p>
                    <div className="flex items-center w-[40%]">
                        <div className="flex ">
                            {(!org?.logoUrl && !org?.logoS3Loc) ? (
                                <div className="">
                                    <p className="text-base font-normal text-textPrimary">
                                        Upload your logo
                                    </p>
                                </div>
                            ) : (
                                <div className="">
                                    <p className="text-base font-normal text-textPrimary bg-slate-100">
                                        <img src={org?.logoUrl} alt="logo" className="h-10 w-20  object-fit" />
                                    </p>
                                </div>
                            )}
                            <label htmlFor="logo-upload" className="cursor-pointer">
                                {/* <Icon
                                    icon="solar:upload-minimalistic-bold"
                                    className={`w-6 h-6 ${isUploading ? 'animate-pulse' : ''}`}
                                /> */}
                            </label>

                            <input
                                id="logo-upload"
                                type="file"
                                accept="image/*"
                                className="hidden"
                                ref={fileInputRef}
                                onChange={handleFileUpload}
                                disabled={isUploading}
                            />
                        </div>
                        {error && (
                            <p className="text-red-500 text-sm mt-2">{error}</p>
                        )}
                    </div>
                    <div className="w-[20%] flex items-center justify-end">
                        
                            {
                                userDetailsState?.isRootAdmin &&
                                <button onClick={handleButtonClick} className="" style={{ color: org?.primaryColor }} >Update</button>
                            }
                    </div>
                </div>

                <div className="flex items-center justify-between border-b-2 border-borderColor mt-5 pb-5">
                    <p className="text-lg font-normal text-secondary w-[40%]">Organization Name</p>
                    <div className="flex items-center w-[40%]">
                        {isEditing ? (
                            <>
                                <input
                                    type="text"
                                    value={newOrgName}
                                    onChange={(e) => setNewOrgName(e.target.value)}
                                    className="text-base font-normal text-textPrimary mr-2 p-1 border rounded"
                                />
                            </>
                        ) : (
                            <>
                                <p className="text-base font-normal text-textPrimary mr-2">
                                    {org?.organizationName}
                                </p>
                            </>
                        )}
                    </div>


                    <div className="w-[20%] flex items-center justify-end">
                       
                            {isEditing ? (
                                <button onClick={handleSave} style={{ color: org?.primaryColor }}>Save</button>
                            ) : (
                                userDetailsState?.isRootAdmin && (
                                    <button onClick={handleEdit} style={{ color: org?.primaryColor }}>Update</button>
                                )
                            )}
                    </div>

                </div>


                <div className="flex items-center justify-between mt-5 pb-5 border-b-2 border-borderColor">
                    <p className="text-lg font-normal text-secondary w-[40%] ">Created on</p>
                    <div className="flex w-[40%]">
                        <p className="text-base font-normal text-textPrimary">
                            {format(new Date(userDetailsState?.createdAt), "MM/dd/yyyy")}
                        </p>
                    </div>
                    <div className="w-[20%]"></div>
                </div>
                <div className="flex items-center justify-between mt-5 pb-5">
                    <p className={`text-lg font-normal text-secondary w-[40%]`} >
                        Colors
                    </p>
                    <p className="w-[40%]">Primary</p>
                    <div className="flex items-center w-[20%] justify-end bg-transparent border-transparent">
                        <p className=" ">
                            <input type="color" className="rounded-full" value={primary} disabled={!userDetailsState?.isRootAdmin} onChange={(e) => {
                                setPrimary(e.target.value)
                            }} />
                        </p>
                    </div>
                </div>
                <div className="flex items-center justify-between pb-5">
                    <p className="text-lg font-normal text-secondary w-[40%]">
                    </p>
                    <p className="w-[40%] ">Secondary</p>
                    <div className="flex items-center w-[20%] justify-end bg-transparent border-transparent">
                        <p>
                            <input type="color" className="rounded-full" value={secondary} disabled={!userDetailsState?.isRootAdmin} onChange={(e) => {
                                setSecondary(e.target.value)
                            }} />
                        </p>
                    </div>
                </div>
            </div>
            {userDetailsState?.role === "super admin" &&
            
                <div className="  flex items-center justify-end gap-10 mt-3">
                    <div className="flex items-center  justify-between  ">
                        <button
                            onClick={() => setShowDeleteModel(true)}
                            className="text-red-600 border border-[#7E7E7E]  font-medium px-4 py-2 rounded-[33px] flex items-center text-base hover:scale-105 active:scale-95 transition-transform"

                        >
                            Delete Organization
                        </button>
                    </div>
                </div>
            }
            {showDeleteModel && <DeleteOrganization setShowModal={setShowDeleteModel} confirmDeleteOrganization={confirmDeleteOrganization} loader1={loader1} />}
        </>
    )
}


export default OrganizationSettings;


const DeleteOrganization = ({ setShowModal, confirmDeleteOrganization, loader1 }) => {

    const { organizationState } = useAppSelector((state) => ({
        organizationState: state.organization.organization,
    }));

    return <>
        <div className="fixed pl-64 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
            <div className="py-4 px-8 rounded-lg w-[350px] md:w-[600px] bg-white z-51 " style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <div className="flex items-center justify-between my-3">
                    <h2 className="text-2xl font-bold">Delete Organization</h2>
                    <button
                        onClick={() => setShowModal(false)}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <Icon icon="ic:baseline-close" width="1.2em" height="1.2em" />
                    </button>
                </div>
                <div className="flex flex-col items-center justify-center my-5">
                    <p className="text-lg text-center font-semibold w-full mb-3 text">You about to delete this Organization!</p>
                    <p className="text-md text-center font-normal text-secondary w-full">Note: All the data related to organization threads, space, dms and members will be permanently deleted!</p>
                </div>
                <div className="flex items-center justify-center my-3">
                    <button
                        onClick={() => setShowModal(false)}
                        className="text-md font-normal py-2 px-5 border-2 rounded-md  transition-colors duration-300 mx-4"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => {
                            if (loader1) return;
                            confirmDeleteOrganization();
                        }}
                        className="text-md font-normal  text-white py-2 px-5 border-2 rounded-md transition-colors duration-300 mx-4 w-[186px] h-[44px]"
                        style={{ backgroundColor: organizationState[0]?.primaryColor }}
                        disabled={loader1}
                    >
                        {loader1 ? <Spinner radius={16} stroke={2} size={100} color="white" style={{ margin: "auto" }} /> : "Delete Organization"}
                    </button>
                </div>
            </div>
        </div>
    </>
};
