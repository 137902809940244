import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeModel, changeProvider, updateKey } from '../../store/aiModelSlice';
import { toast } from 'react-toastify';
import apiClient from '../../utils/axiosInstance';
import opanAilogo from "../../assets/openai-logo.webp";
import geminiLogo from "../../assets/Gemini2.webp";
import anthropicLogo from "../../assets/anthorpic.jpeg";
import placeLogo from "../../assets/logo-1.png";
import { FaLockOpen, FaLock } from "react-icons/fa";
import { toggleAiSetting } from '../../store/organization';
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FiSave } from "react-icons/fi";
import { encryptKey, decryptKey } from '../../utils/static';


const AiChatsettings = ({ handleAddNewModel }) => {
    const { modelState, userDetails, organizationState } = useSelector((state) => ({
        modelState: state.aiModels.aiModels,
        userDetails: state.auth.userDetails,
        providers: state.aiModels.aiModels.providers,
        organizationState: state.organization.organization
    }));
    const filterProvider = modelState?.providers?.filter(
        provider => provider.provider_name !== "pinecone" && provider.provider_name !== "exa"
    );
    const Providders = filterProvider;
    const openaiProvider = modelState?.providers?.find(provider => provider.provider_name === "openai");
    const openaiModelId = openaiProvider ? openaiProvider.modelId : null;
    const dispatch = useDispatch();



    const [editAPI, setEditAPI] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [viewKey, setViewKey] = useState([]);
    const [apiKeys, setApiKeys] = useState({});


    const [defaultModel, setDefaultModel] = useState(null);
    const [allModels, setAllModels] = useState([]);
    const [loader1, setLoader1] = useState(false);

    useEffect(() => {
        if (modelState) {
            const filteredProviders = modelState?.providers?.filter(
                provider => provider.provider_name !== "pinecone" && provider.provider_name !== "exa"
            );

            let models = [];
            filteredProviders?.forEach((provider) => {
                if (!provider.provider_key) return;
                const providerModels = modelState.models[provider.modelId]?.map((model) => {
                    const newModel = {
                        ...model,
                        provider_name: provider.provider_name,
                        provider_key: provider.provider_key,
                        providerId: provider.modelId,
                    };
                    if (provider.currentUsed && model.currentUsed) {
                        setDefaultModel(newModel);
                    }
                    return newModel;
                });
                models = models.concat(providerModels);
            });
            setAllModels(models);
        }
    }, [modelState])



    useEffect(() => {
        const filterProvider = modelState?.providers?.filter(
            provider => provider.provider_name !== "pinecone" && provider.provider_name !== "exa"
        );
        const obj = {}
        filterProvider?.forEach((provider) => {
            obj[provider.provider_name] = ""
        })
        setApiKeys(obj)
    }, [modelState])

    const verifyGeminiApiKey = async (apiKey) => {
        const API_VERSION = 'v1';
        const apiUrl = `https://generativelanguage.googleapis.com/${API_VERSION}/models?key=${apiKey}`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            muteHttpExceptions: true,
        });
        return response.status === 200;
    };

    const verifyOpenaiApiKey = async (apiKey) => {
        const apiUrl = `https://api.openai.com/v1/models`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiKey}` },
            muteHttpExceptions: true,
        });
        return response.status === 200;
    };


    const submitApi = async (providerId, key, providerName) => {
        if (key === "" || key == null) {
            toast.error("Provider Key Should not be empty");
            return;
        }

        if (providerName === 'openai') {
            const isValid = await verifyOpenaiApiKey(key);
            if (!isValid) {
                toast.error('Invalid OpenAI API key');
                return;
            }
        } else if (providerName === 'gemini') {
            const isValid = await verifyGeminiApiKey(key);
            if (!isValid) {
                toast.error('Invalid Gemini API key');
                return;
            }
        }

        try {
            const encryptedKey = encryptKey(key);
            if (!encryptedKey) {
                throw new Error("Encryption failed.");
            }
            handleModelChange(providerId);
            await apiClient().put(`/provider/${providerId}`, {
                providerKey: encryptedKey,
            });

            if (!organizationState[0]?.isAiChatUsed) {
                await apiClient().post("/toggle-ai-setting", { settingName: "isAiChatUsed" });
            }
            dispatch(toggleAiSetting({ settingName: "isAiChatUsed", value: true }));
            dispatch(updateKey({ providerId: providerId, key: encryptedKey }));
        } catch (error) {
            toast.error(`Error updating provider key: ${error}`);
        }
    };

    const handleModelChange = async (id) => {
        try {
            const res = await apiClient().put(`/change-provider/${id}`);
            dispatch(changeProvider({ providerId: id }));
        } catch (error) {
            console.log(error);
        }
    }

    const removeApi = async (providerId, key, providerName) => {
        try {
            await apiClient().put(`/provider/${providerId}`, {
                providerKey: "",
            });

            if (providerName === 'gemini') {
                const { data } = await apiClient().post("/toggle-ai-setting", { settingName: "isWebsearchUsed" })
                dispatch(toggleAiSetting({ settingName: "isWebsearchUsed", value: data?.setting?.isWebsearchUsed }));
            }

            let AiProviders = modelState?.providers?.filter(
                provider => provider.provider_name !== "pinecone" && provider.provider_name !== "exa" && provider.modelId !== providerId && provider.provider_key && provider.provider_key !== ""
            );

            if (AiProviders?.length !== 0) {
                handleModelChange(AiProviders[0].modelId);
            } else {
                const { data } = await apiClient().post("/toggle-ai-setting", { settingName: "isAiChatUsed" })
                dispatch(toggleAiSetting({ settingName: "isAiChatUsed", value: data?.setting?.isAiChatUsed }));
            }

            dispatch(updateKey({ providerId: providerId, key: null }));
        } catch (error) {
            toast.error(`Error updating provider key: ${error}`);
        }
    };

    const changeAIModel = async (model) => {
        if (loader1) return;
        try {
            setLoader1(true);
            const { data } = await apiClient().put("/change-provider-model", {
                providerId: model.providerId,
                model: model.model
            });
            dispatch(changeModel({ providerId: model.providerId, models: data.models }));
            dispatch(changeProvider({ providerId: model.providerId }));
        } catch (error) {
            console.log("Error changing model", error)
        } finally {
            setLoader1(false);
        }
    }


    return (<>
        <div className=' w-full px-5 my-4'>
            {openaiModelId && Providders?.map((provider, index) => {
                const openAiLink = "https://platform.openai.com/api-keys";
                const anthropicLink = "https://docs.anthropic.com/en/api/getting-started";
                const geminiLink = "https://ai.google.dev/gemini-api/docs";
                const link = provider.provider_name === 'openai' ? openAiLink : provider.provider_name === 'gemini' ? geminiLink : anthropicLink;
                const logo = provider.provider_name === 'openai' ? opanAilogo : provider.provider_name === 'gemini' ? geminiLogo : provider.provider_name === 'anthropic' ? anthropicLogo : placeLogo;
                return <>
                    <div key={index}>
                        <p style={{ fontWeight: '600', fontSize: '14px', lineHeight: '20.75px', color: '#1D1C1D' }}><span style={{ textTransform: "capitalize" }}>{provider.provider_name}</span> API Key : <a href={link} target='blank' style={{ color: '#1c64f2' }}>(Get API key here)</a> </p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='w-full my-2'>
                            <div className='bg-slate-100'>
                                <img src={logo}
                                    alt={provider.provider_name}
                                    className="w-8 h-8 rounded-md"
                                />
                            </div>
                            <div className='w-[90%] flex justify-between'>
                                {(editAPI && editIndex === index) || !provider?.provider_key ? <>
                                    <input
                                        type='text'
                                        className='rounded-md w-[70%] h-[37px] px-3 py-2 border border-gray-300 focus:outline-none'
                                        value={apiKeys[provider?.provider_name]}
                                        onChange={(e) => {
                                            setApiKeys({ ...apiKeys, [provider?.provider_name]: e.target.value })
                                        }}
                                        placeholder={`Enter the ${provider?.provider_name} API Key`} />

                                    {!apiKeys[provider?.provider_name] && provider?.provider_key ? <button
                                        className='text-white px-4 py-2 h-[37px] w-[120px] rounded-md flex items-center justify-center'
                                        style={{ backgroundColor: organizationState[0]?.primaryColor }}
                                        onClick={() => {
                                            removeApi(provider?.modelId, null, provider?.provider_name)
                                            setApiKeys({ ...apiKeys, [provider?.provider_name]: "" })
                                            setEditIndex(null);
                                            setEditAPI(false);
                                        }}>
                                        <RiDeleteBin6Fill />&nbsp;Remove
                                    </button> :
                                        <button
                                            className='text-white px-4 py-2 h-[37px] w-[120px] rounded-md flex items-center justify-center'
                                            style={{ backgroundColor: !apiKeys[provider?.provider_name] ? "#aaa" : organizationState[0]?.primaryColor }}
                                            disabled={!apiKeys[provider?.provider_name]}
                                            onClick={() => {
                                                submitApi(provider?.modelId, apiKeys[provider?.provider_name], provider?.provider_name)
                                                setEditIndex(null);
                                                setEditAPI(false);
                                            }}>
                                            <FiSave />&nbsp;Save
                                        </button>
                                    }
                                </> : <>
                                    <div className="flex items-center h-[37px] justify-between w-[100%] px-3 py-2 rounded-md border border-gray-300">
                                        {viewKey.includes(index) ? <>
                                            <span className="flex items-center text-red-500 w-[6%] cursor-pointer">
                                                <FaLockOpen onClick={() => setViewKey(prev => prev.filter((ele) => ele !== index))} />
                                            </span>
                                            <span className="text-gray-600 w-[70%]" style={{ textWrap: "nowrap", overflow: "auto" }}>{decryptKey(provider?.provider_key)}</span>
                                        </> : <>
                                            <span className="flex items-center w-[6%] cursor-pointer">
                                                <FaLock onClick={() => setViewKey(prev => [...prev, index])} />
                                            </span>
                                            <span className="text-gray-600 w-[70%] relative top-1" style={{ textWrap: "nowrap", overflow: "auto" }}>**********************************</span>
                                        </>}

                                        <button
                                            className="text-blue-500 text-sm w-[24%]"
                                            onClick={() => {
                                                setEditIndex(index);
                                                setEditAPI(true);
                                            }}
                                        >
                                            Change Key
                                        </button>
                                    </div>
                                </>}
                            </div>
                            {/* <div className='w-[20%]'>
                                <select
                                    onChange={(e) => {
                                        if (e.target.value === 'add-chat-model') {
                                            handleAddNewModel(provider);
                                        } else if (e.target.value === 'edit-chat-model') {
                                            handleAddNewModel2(provider);
                                        } else {
                                            // handleModelChange(provider?.modelId, e.target.value);
                                        }
                                    }}
                                    value={getCurrentModelForProvider(provider?.modelId) || ''}
                                    className="px-2  text-center border-slate-200 rounded-md h-[37px] w-[100%]"
                                >
                                    {modelState.models[provider?.modelId]?.map((model) => (
                                        <option key={model.model} value={model.model} className="rounded-[20px]">
                                            {model.model}
                                        </option>
                                    ))}
                                    <option value="add-chat-model" className="rounded-[20px] text-purple-600">
                                        Add Chat Model
                                    </option>
                                    <option value="edit-chat-model" className="rounded-[20px]  text-purple-600">
                                        Edit Chat Model
                                    </option>
                                </select>
                            </div> */}
                        </div>
                    </div>
                </>
            })}
            {allModels.length > 0 && <div className='mt-8 flex items-center'>
                <div style={{ fontWeight: '600', fontSize: '14px', lineHeight: '20.75px', color: '#1D1C1D', textWrap: 'nowrap' }}> Change Default Model : </div>
                <div className='ml-8'>
                    <select
                        onChange={(e) => {
                            const model = allModels.find((model) => model.model === e.target.value);
                            if (model) {
                                changeAIModel(model);
                            }
                        }}
                        value={defaultModel.model || ''}
                        className="px-2 py-1 text-center border-slate-200 rounded-md h-[38px] w-[270px]"
                    >
                        {allModels?.map((model) => (
                            <option key={model.model} value={model.model} className="rounded-[20px]" >
                                {model.model}
                            </option>
                        ))}
                    </select>
                </div>
            </div>}
        </div>
    </>
    )
}
export default AiChatsettings;


// const OLd code = () => {
// const [editableProviderKey, setEditableProviderKey] = useState('');
// const [isKeyValid, setIsKeyValid] = useState(true);
// const [showKeys, setShowKeys] = useState({});
// const [editingProviderId, setEditingProviderId] = useState(null);
// const [isModalOpen, setIsModalOpen] = useState(false);
// const [editingModelId, setEditingModelId] = useState(null);
// const [newModelName, setNewModelName] = useState('');
// const [editingAPIKey, setEditingAPIKey] = useState(false);
// const [editPid, setEditPid] = useState('')
// const [newAPIKey, setNewAPIKey] = useState({ key: "" });
// const [EditiModel, setEditiModel] = useState(false);
// const [loadingupdate, setLoadingUpdate] = useState(false);
// const inputRef = useRef(null);
// const [modelProvider, setModelProvider] = useState("")
// const [modelList, setmodelList] = useState([])
// const [pid, setpid] = useState("");

// useEffect(() => {
//     setModelProvider(modelState?.providers)
// }, [])

// const handleEdit = (model) => {
//     setEditiModel(true);
//     setEditingModelId(model);
//     setNewModelName(model);
// };

// const handleDeleteModel = async (name) => {
//     if (pid === "") {
//         toast.error("Please Select Provider First")
//         return;
//     }
//     try {
//         const res = await apiClient().delete(`/providers/${pid}/models/${name}`);
//         console.log(res.data);

//         dispatch(removeModel({ providerId: openaiModelId, modelName: name }))
//         setmodelList((prevList) => prevList.filter((model) => model.model !== name));

//         if (res.data?.message?.updated_models.length > 0) {
//             const response = await apiClient().post("/change-model", {
//                 providerId: openaiModelId,
//                 model: res.data?.message?.updated_models[0]?.model
//             });
//             dispatch(changeModel({ providerId: openaiModelId, models: response.data.models }));
//         }
//     } catch (error) {
//         console.log("Error to Delete Model", error);

//     }
// }

// const handleSaveModelName = async () => {
//     if (!newModelName.trim()) {
//         toast.error("Model name cannot be empty");
//         return;
//     }
//     try {
//         setLoadingUpdate(true);
//         await apiClient().put(`providers/${openaiModelId}/models/${editingModelId}`, { newModelName });
//         dispatch(updateModel({ providerId: openaiModelId, oldModelName: editingModelId, newModelName }));
//         setmodelList((prevList) =>
//             prevList.map((model) =>
//                 model.model === editingModelId ? { ...model, model: newModelName } : model
//             )
//         );
//         toast.success("Model Name Updated Successfully");
//     } catch (error) {
//         console.error("Error updating model:", error);
//         toast.error("Failed to update model");
//     } finally {
//         setLoadingUpdate(false);
//         setEditingModelId(null);
//         setNewModelName('');
//     }
// };

// const toggleShowKey = (providerId) => {
//     setShowKeys(prev => ({ ...prev, [providerId]: !prev[providerId] }));
// };

// const handleModelChange = async (providerId, newModel) => {
//     const res = await apiClient().post("/change-model", {
//         providerId,
//         model: newModel
//     });
//     dispatch(changeModel({ providerId, models: res.data.models }));
// };

// const handleEditClick = (openaiProvider) => {
//     setEditingProviderId(openaiProvider.modelId);
//     setEditableProviderKey(openaiProvider.provider_key);
//     setIsKeyValid(true);
// };

// const handleSaveClick = async () => {
//     if (editableProviderKey === "" || editableProviderKey == null) {
//         toast.error("Provider Key Should not be empty");
//         return;
//     }
//     const provider = modelState.providers.find(p => p.modelId === editingProviderId);
//     if (!provider) return;

//     if (provider.provider_name === 'openai') {
//         const isValid = await verifyOpenaiApiKey(editableProviderKey);
//         setIsKeyValid(isValid);
//         if (!isValid) {
//             toast.error('Invalid OpenAI API key');
//             return;
//         }
//     } else if (provider.provider_name === 'gemini') {
//         const isValid = await verifyGeminiApiKey(editableProviderKey);
//         setIsKeyValid(isValid);
//         if (!isValid) {
//             toast.error('Invalid Gemini API key');
//             return;
//         }
//     }

//     if (editableProviderKey !== provider.provider_key) {
//         try {
//             await apiClient().put(`/provider/${editingProviderId}`, {
//                 providerKey: editableProviderKey,
//             });
//             dispatch(updateKey({ providerId: editingProviderId, key: editableProviderKey }));
//         } catch (error) {
//             toast.error(`Error updating provider key: ${error}`);
//         }
//     }
//     setEditingProviderId(null);
// };

// const handleKeyChange = (e) => {
//     setEditableProviderKey(e.target.value);
//     setIsKeyValid(true);
// };

// const showModelsForProvider = (providerName) => {
//     return ['openai', 'gemini', 'anthropic'].includes(providerName.toLowerCase());
// };

// const getCurrentModelForProvider = (providerId) => {
//     const models = modelState.models[providerId];
//     if (!models) return null;
//     return models.find(model => model.currentUsed)?.model || null;
// };
// const hanfleModelChange = async (id) => {
//     try {
//         const res = await apiClient().put(`/change-provider/${id}`);
//         dispatch(changeProvider({ providerId: id }));
//     } catch (error) {
//         console.log(error);

//     }
// }

// const handleAPIKeyChange = (e) => {
//     const { name, value } = e.target;
//     setNewAPIKey({ ...newAPIKey, [name]: value });
// }

// const handleAddAPIKey = (provider) => {
//     setEditingAPIKey(true)
//     setEditPid(provider.modelId)

// };

// const handleSaveNewAPIKey = async () => {
//     try {
//         if (newAPIKey === undefined || newAPIKey === null || newAPIKey === "") {
//             toast.error("API Key Should not be empty");
//         }
//         const response = await apiClient().put(`/provider/${editPid}`, {
//             providerKey: newAPIKey.key
//         })
//         console.log(response.data);
//         dispatch(addNewProviderKey({ providerId: editPid, newProviderKey: newAPIKey.key }));
//         setEditingAPIKey(false)
//     } catch (error) {
//         console.log(error);

//     }
// }

// const handleAddNewModel2 = (provider) => {
//     const providerModelId = provider.modelId;
//     setpid(providerModelId)
//     const newModelList = modelState?.models[providerModelId] || [];
//     setmodelList(newModelList)
//     setIsModalOpen(true);
// };
//     return <>
//     <div className="relative mt-2 rounded-xl bg-white p-5 border border-borderColor">
//     {
//         openaiModelId && Providders?.map((provider) => {
//             return (
//                 <div key={provider.modelId} className="min-w-full flex">

//                     <div key={provider.modelId} className="flex items-center gap-6 justify-between w-full mb-5 overflow-auto">
//                         <label className="w-[15%] flex items-center gap-2 hover:cursor-pointer">
//                             <input type="radio" checked={provider.currentUsed} name="provider" value={provider.modelId} onChange={() => hanfleModelChange(provider.modelId)} className="hidden peer" />
//                             <span className="w-5 h-5 border-2 border-gray-500 rounded-full peer-checked:border-indigo-500 peer-checked:bg-indigo-500 relative flex items-center justify-center">
//                                 <span className="hidden peer-checked:block w-2.5 h-2.5 bg-white rounded-full"></span>
//                             </span>
//                             <span>{provider?.provider_name}</span>
//                         </label>

//                         <div className={`w-[50%] px-4  text-center flex flex-col gap-5 `}>
//                             <div className='flex items-center border border-slate-200 rounded-[20px] overflow-hidden px-2 py-2'>
//                                 <div className='w-[90%] border-t-0 border-b-0 border-l-0 border-r border-slate-200  me-2 pe-2 '>
//                                     {editingProviderId === provider.modelId ? (
//                                         <input
//                                             type="text"
//                                             value={editableProviderKey}
//                                             onChange={handleKeyChange}
//                                             className={`p-0 w-full  overflow-x-auto ${!isKeyValid ? 'border-red-500' : ''}`}
//                                         />
//                                     ) : editingAPIKey && provider?.modelId === editPid ? (
//                                         <input type='text' name='key' placeholder='enter api key' onChange={handleAPIKeyChange} className={`p-0 w-full border-none  overflow-x-auto ${!isKeyValid ? 'border-red-500' : ''}`} />)
//                                         : (
//                                             provider?.provider_key === null ? (
//                                                 <button className="w-full text-left ps-4">Add Api Key</button>
//                                             ) : (
//                                                 <div className="max-w-[300px] overflow-x-auto m-auto whitespace-nowrap">
//                                                     {showKeys[provider?.modelId] ? provider?.provider_key : '*'.repeat(36)}
//                                                 </div>
//                                             )
//                                         )}
//                                 </div>
//                                 {(userDetails?.role === "admin" || userDetails?.role === "super admin") && (
//                                     <>
//                                         {
//                                             provider?.provider_key !== null ? (
//                                                 <div className='flex items-center'>
//                                                     <div className={`text-center  flex items-center  me-1  h-full ${provider?.provider_name === 'openai' ? 'w-1/4' : 'w-1/3'}`}>
//                                                         <button onClick={() => toggleShowKey(provider?.modelId)} className="me-1 ">
//                                                             <Icon icon={showKeys[provider?.modelId] ? "mdi:eye-off" : "mdi:eye"} width="1.2em" height="1.2em" />
//                                                         </button>
//                                                     </div>
//                                                     <div className={`text-center  ms-1 border-l border-slate-200 flex items-center ${provider?.provider_name === 'openai' ? 'w-1/4' : 'w-1/3'}`}>
//                                                         {editingProviderId === provider?.modelId ? (
//                                                             <button onClick={handleSaveClick} className="ml-1 ">
//                                                                 <Icon icon="mdi:check" width="1.2em" height="1.2em" />
//                                                             </button>
//                                                         ) : (
//                                                             <button onClick={() => handleEditClick(provider)} className="ml-1 " >
//                                                                 <Icon icon="mdi:pencil" width="1.2em" height="1.2em" />
//                                                             </button>
//                                                         )}
//                                                     </div>
//                                                 </div>
//                                             ) : (

//                                                 editingAPIKey ? (
//                                                     <Icon icon="mdi:check" className='hover:cursor-pointer' onClick={handleSaveNewAPIKey} width="1.2em" height="1.2em" />
//                                                 ) : (

//                                                     <button onClick={() => handleAddAPIKey(provider)} >
//                                                         <Icon icon="mdi:plus" width="1.5em" height="1.5em" />
//                                                     </button>
//                                                 )

//                                             )
//                                         }

//                                     </>
//                                 )}
//                             </div>
//                         </div>

//                         <div className="w-[20%] flex items-center justify-end ">
//                             {provider && (
//                                 <div className="flex flex-col gap-3 whitespace-nowrap w-[100%] overflow-hidden text-ellipsis">
//                                     {modelState.models[provider?.modelId]?.length > 0 ? (
//                                         <select
//                                             onChange={(e) => {
//                                                 if (e.target.value === 'add-chat-model') {
//                                                     handleAddNewModel(provider);
//                                                 } else if (e.target.value === 'edit-chat-model') {
//                                                     handleAddNewModel2(provider);
//                                                 } else {
//                                                     handleModelChange(provider?.modelId, e.target.value);
//                                                 }
//                                             }}
//                                             value={getCurrentModelForProvider(provider?.modelId) || ''}
//                                             className="px-2 py-2 text-center border-slate-200 rounded-[20px] w-[100%]"
//                                         >
//                                             {modelState.models[provider?.modelId]?.map((model) => (
//                                                 <option key={model.model} value={model.model} className="rounded-[20px]">
//                                                     {model.model}
//                                                 </option>
//                                             ))}
//                                             <option value="add-chat-model" className="rounded-[20px] text-purple-600">
//                                                 Add Chat Model
//                                             </option>
//                                             <option value="edit-chat-model" className="rounded-[20px]  text-purple-600">
//                                                 Edit Chat Model
//                                             </option>
//                                         </select>
//                                     ) : (
//                                         <button
//                                             onClick={() => handleAddNewModel(provider)}
//                                             className="px-2 py-2 text-center border-slate-200 rounded-[20px] w-[100%] text-purple-600"
//                                         >
//                                             Add Chat Model
//                                         </button>
//                                     )}
//                                 </div>
//                             )}
//                         </div>

//                     </div>

//                 </div>
//             )
//         })
//     }

//     {
//         isModalOpen && (
//             <modal
//                 onClose={() => setIsModalOpen(false)}
//                 className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center"
//             >
//                 <div className="p-4 md:p-8 rounded-lg bg-white md:w-[600px] w-[350px]">
//                     <div className="flex items-center justify-between pb-5">
//                         <h1 className="text-2xl font-bold">Manage Models</h1>
//                         <Icon
//                             icon="fa:times"
//                             className="cursor-pointer text-md"
//                             width="1.2em"
//                             height="1.2em"
//                             onClick={() => setIsModalOpen(false)}
//                         />
//                     </div>

//                     <div className="overflow-auto max-h-[400px]">
//                         <table className="min-w-full table-auto border-collapse ">
//                             <thead>
//                                 <tr>
//                                     <th className="px-4 py-2 text-left border-b">Model Name</th>
//                                     <th className="px-4 py-2 text-left border-b">Actions</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {modelList?.map((model) => (
//                                     <tr key={model.model}>
//                                         <td className="px-4 py-2 border-b">
//                                             {editingModelId === model.model ? (
//                                                 <input
//                                                     type="text"
//                                                     value={newModelName}
//                                                     onChange={(e) => setNewModelName(e.target.value)}
//                                                     className="border rounded px-2 py-1 w-[150px]"
//                                                     ref={inputRef}
//                                                 />
//                                             ) : (
//                                                 <span>{model.model}</span>
//                                             )}
//                                         </td>
//                                         <td className="px-4 py-2 border-b">
//                                             {editingModelId === model.model ? (
//                                                 <div className="flex space-x-2">
//                                                     <button
//                                                         className={`px-3 py-1  text-white rounded`}
//                                                         style={{ backgroundColor: organizationState[0].primaryColor }}
//                                                         onClick={handleSaveModelName}
//                                                     >
//                                                         {loadingupdate ? "Updating..." : "Update"}
//                                                     </button>
//                                                     {/* <button
//                                                                 className="px-3 py-1 bg-red-400 hover:bg-red-500 text-white rounded"
//                                                                 onClick={() => handleDeleteModel(model.model)}
//                                                             >
//                                                                 {loading ? "Deleting..." : "Delete"}
//                                                             </button> */}
//                                                     <button
//                                                         className="px-3 py-1  text-white rounded"
//                                                         style={{ backgroundColor: organizationState[0].primaryColor }}
//                                                         onClick={() => {
//                                                             setEditingModelId(null);
//                                                             setNewModelName('');
//                                                         }}
//                                                     >
//                                                         Cancel
//                                                     </button>
//                                                 </div>
//                                             ) : (
//                                                 <div className='flex items-center justify-start gap-5'>
//                                                     <button
//                                                         className="text-blue-500"
//                                                         onClick={() => handleEdit(model.model)}
//                                                     >
//                                                         <Icon icon="mdi:pencil" width="1.2em" height="1.2em" />
//                                                     </button>
//                                                     <button
//                                                         className="text-red-500"
//                                                         onClick={() => handleDeleteModel(model.model)}
//                                                     >
//                                                         <Icon icon="mdi:delete" width="1.2em" height="1.2em" />

//                                                     </button>
//                                                 </div>

//                                             )}
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>

//                     <div className="flex items-center justify-around pt-4">
//                         <button
//                             onClick={() => setIsModalOpen(false)}
//                             className="px-4 py-2 bg-red-400 hover:bg-red-500 text-white rounded mt-5"
//                         >
//                             Cancel
//                         </button>
//                     </div>
//                 </div>
//             </modal>
//         )
//     }
// </div></>
// }