import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";

export const getTeams = createAsyncThunk("getTeams", async () => {
  try {
    const response = await apiClient().get(`/teams`);
    return response.data.teams;
  } catch (error) {
    console.log("error in chat slice:", error);
    toast.error(`${error}`);
    throw error;
  }
});

export const userInvitedToTeam = createAsyncThunk("userInvitedToTeam", async (data) => {
  try {
    const response = await apiClient().get(`/team/${data.teamId}`);
    console.log("response", response.data);
    return response.data.team;
  } catch (error) {
    console.log("error in chat slice:", error);
    toast.error(`${error}`);
    throw error;
  }
});

export const teams = createSlice({
  name: "teams",
  initialState: {
    teams: [],
    teamStatus: null,
    userInvitedToTeamStatus: null,
  },
  reducers: {
    addTeam: (state, action) => {
      state.teams = [...state.teams, action.payload];
    },
    updateTeam: (state, action) => {
      const { teamId, teamName, teamType } = action.payload;
      const team = state.teams.find(team => team.teamId === teamId);
      if (team) {
        team.teamName = teamName;
        team.teamType = teamType;
      }
    },
    updateTeamNotification: (state, action) => {
      state.teams = state.teams.map((team) => {
        if (team.teamId === action.payload.teamId) {
          team.notification = action.payload.notification;
        }
        return team;
      });
    },
    updateTeamNotificatioOnOrOff: (state, action) => {
      state.teams = state.teams.map((team) => {
        const { teamId , notificationOn, memberEmail} = action.payload;
        if (team.teamId === teamId) {
          team.members = team.members.map((member) => {
            if (member.email === memberEmail) {
              member.notificationOn = notificationOn;
            }
            return member;
          })
        }
        return team;
      });
    },
    updateMemberStatuses: (state, action) => {
      const { email, status } = action.payload;
      state.teams = state.teams.map(team => ({
        ...team,
        members: team.members.map(member => {
          if (member.email === email) {
            return { ...member, user_status: status };
          }
          return member;
        })
      }));
    },
    removeTeam: (state, action) => {
      state.teams = state.teams.filter(
        (team) => team.teamId !== action.payload
      );
    },
    addMember: (state, action) => {
      state.teams = state.teams.map((team) => {
        if (team.teamId === action.payload.teamId) {
          team.members = [...team.members, action.payload];
        }
        return team;
      });
    },
    updateTeamMemberRole: (state, action) => {
      state.teams = state.teams.map((team) => {
        if (team.teamId === action.payload.teamId) {
          team.members = team.members.map((member) => {
            if (member.email === action.payload.memberId) {
              member.role = action.payload.role;
            }
            return member;
          });
        }
        return team;
      });
    },
    removeTeamMember: (state, action) => {
      state.teams = state.teams.map((team) => {
        if (team.teamId === action.payload.teamId) {
          team.members = team.members.filter(
            (member) => member.email !== action.payload.memberId
          );
        }
        return team;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.fulfilled, (state, action) => {
        state.teamStatus = "succeeded";
        state.teams = action.payload;
      })
      .addCase(getTeams.pending, (state, action) => {
        state.teamStatus = "loading";
      })
      .addCase(getTeams.rejected, (state, action) => {
        state.teamStatus = "failed";
      })
      .addCase(userInvitedToTeam.fulfilled, (state, action) => {
        state.userInvitedToTeamStatus = "succeeded";
        state.teams = [...state.teams, action.payload];
      })
      .addCase(userInvitedToTeam.pending, (state, action) => {
        state.userInvitedToTeamStatus = "loading";
      })
      .addCase(userInvitedToTeam.rejected, (state, action) => {
        state.userInvitedToTeamStatus = "failed";
      })
  },
});

export const { addTeam, updateTeam, updateTeamNotification,updateTeamNotificatioOnOrOff, removeTeam, addMember, updateMemberStatuses, updateTeamMemberRole, removeTeamMember } = teams.actions;

export const teamReducer = teams.reducer;
