import React, { useState } from "react";
import useAuth from "../../utils/useAuth";
import { FaSearch, FaChevronDown, FaCog, FaLock, FaGlobe, FaUsers } from "react-icons/fa";
import avtarPic from "../../assets/user.png";
import { useAppSelector } from "../../store";

const ShareFolderModal = ({ setShareModal, shareModal, folder }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [accessLevel, setAccessLevel] = useState("restricted");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showTeamsDropdown, setShowTeamsDropdown] = useState(false);

  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));
  const { user } = useAuth();

  const teams = folder?.folder_shared_details?.filter((detail) => detail.accountType === "team") || [];
  const members = folder?.folder_shared_details?.filter((detail) => detail.accountType === "member") || [];

  return (
    <>
      {shareModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg w-[500px] p-6 space-y-6 shadow-lg">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold">Share "{folder?.folderName}"</h2>
              <div className="flex space-x-2">
                <button className="p-1 rounded-full hover:bg-gray-100 w-8 h-8 flex items-center justify-center">
                  <FaCog size={16} />
                </button>
                <button
                  className="p-1 rounded-full hover:bg-gray-100 w-8 h-8 flex items-center justify-center"
                  onClick={() => setShareModal(false)}
                >
                  <span className="text-2xl">&times;</span>
                </button>
              </div>
            </div>

            <div className="relative">
              <FaSearch className="absolute left-3 top-3 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Add people, groups, and calendar events"
                className="w-full py-3 pl-10 pr-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {/* General Access Section */}
            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-700">General access</h3>
              <div className="relative">
                <button
                  className="w-full flex items-center justify-between p-3 border rounded-lg hover:bg-gray-50"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <div className="flex items-center space-x-2">
                    {accessLevel === "restricted" ? (
                      <FaLock className="text-gray-600" size={18} />
                    ) : (
                      <FaGlobe className="text-gray-600" size={18} />
                    )}
                    <div>
                      <p className="text-sm font-medium text-left">
                        {accessLevel === "restricted" ? "Restricted" : "Anyone in this Organization"}
                      </p>
                      <p className="text-xs text-gray-500">
                        {accessLevel === "restricted" ? "Only people with access can open" : "Anyone in the organization can access"}
                      </p>
                    </div>
                  </div>
                  <FaChevronDown
                    size={20}
                    className={`text-gray-400 transition-transform ${isDropdownOpen ? "rotate-180" : ""}`}
                  />
                </button>

                {isDropdownOpen && (
                  <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                    <button
                      className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center space-x-2"
                      onClick={() => {
                        setAccessLevel("restricted");
                        setIsDropdownOpen(false);
                      }}
                    >
                      <FaLock className="text-gray-600" size={18} />
                      <span>Restricted</span>
                    </button>
                    <button
                      className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center space-x-2"
                      onClick={() => {
                        setAccessLevel("anyone");
                        setIsDropdownOpen(false);
                      }}
                    >
                      <FaGlobe className="text-gray-600" size={18} />
                      <span>Anyone in this Organization</span>
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Teams Section */}
            <div className="space-y-4">
              {/* <h3 className="text-sm font-medium text-gray-700">Teams</h3> */}
              <div className="relative">
                <button
                  className="w-full flex items-center justify-between p-3 border rounded-lg hover:bg-gray-50"
                  onClick={() => setShowTeamsDropdown(!showTeamsDropdown)}
                >
                  <div className="flex items-center space-x-2">
                    <FaUsers className="text-gray-600" size={18} />
                    <div>
                      <p className="text-sm font-medium text-left">{`Teams With Access (${teams.length})`}</p>
                      <p className="text-xs text-gray-500 text-left">Shared with teams</p>
                    </div>
                  </div>
                  <FaChevronDown
                    size={20}
                    className={`text-gray-400 transition-transform ${showTeamsDropdown ? "rotate-180" : ""}`}
                  />
                </button>

                {showTeamsDropdown && (
                  <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                    {teams.map((team, index) => (
                      <div key={index} className="px-4 py-2 flex items-center space-x-2 hover:bg-gray-50">
                        <FaUsers className="text-gray-600" size={18} />
                        <span className="text-sm">{team.sharedToname}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* People with Access Section */}
            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-700">People with access</h3>
              <div
                className="space-y-3 overflow-y-auto"
                style={{ maxHeight: "200px", width: "100%" }}
              >
                <div className="flex items-center space-x-3">
                  <img src={user?.avatar || avtarPic} alt="User avatar" className="w-10 h-10 rounded-full" />
                  <div className="flex-1">
                    <p className="text-sm font-medium">{user?.name} (you)</p>
                    <p className="text-sm text-gray-500">{user?.email}</p>
                  </div>
                  <span className="text-sm text-gray-500">Owner</span>
                </div>

                {members.map((member, index) => (
                  <div key={index} className="flex items-center space-x-3">
                    <img src={avtarPic} alt="Member avatar" className="w-10 h-10 rounded-full" />
                    <div className="flex-1">
                      <p className="text-sm font-medium">{member.sharedToname}</p>
                      <p className="text-sm text-gray-500">{member.sharedToemail}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end pt-4">
              <button
                className="px-6 py-2 rounded-lg text-white"
                style={{ backgroundColor: organizationState?.[0]?.secondaryColor }}
              >
                Share Folder
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareFolderModal;
