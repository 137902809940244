import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getTeams,
  updateTeamNotification,
  updateTeamNotificatioOnOrOff,
} from "../../store/teamSlice";
import apiClient from "../../utils/axiosInstance";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import TeamModal from "../Team/TeamModal";
import { jwtDecode } from "jwt-decode";
import { IoAdd } from "react-icons/io5";
import useAuth from "../../utils/useAuth";

function SidebarRenderSpace({ isItemActive, setActiveItemId }) {
  const { user } = useAuth();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { teamState, teamStatus, organizationState } = useAppSelector(
    (state) => ({
      teamState: state.teams.teams,
      teamStatus: state.teams.teamStatus,
      organizationState: state.organization.organization,
    })
  );
  const navigate = useNavigate();
  const [teamModal, setTeamModal] = useState(false);
  const [initialTeam, setInitialTeam] = useState(null);

  const { token } = useParams();
  const [showButtons, setShowButtons] = useState(false);
  const [error, setError] = useState(null);

  let teams = [];
  teamState
    ?.filter((team) =>
      team.members.some((member) => member.email === user?.email)
    )
    .forEach((team) => {
      const data = {
        id: team.teamId,
        name: team.teamName,
        notification: team.notification,
        teamType: team.teamType,
        notificationOn: team.notificationOn,
        members: team.members,
      };
      teams.push(data);
    });

  useEffect(() => {
    if (!teamState?.length > 0) {
      dispatch(getTeams());
    }
  }, []);

  useEffect(() => {
    if (teamState?.length > 0 && location.pathname === "/team") {
      const team = teamState[0];
      setActiveItemId(team.teamId);
      navigate(`/teams`);
    }
  }, []);

  const handleItemClick = (itemId) => {
    setActiveItemId(itemId);
  };

  const resetNotification = async (teamId) => {
    try {
      await apiClient().put(`/reset-notification/${teamId}`);
      dispatch(updateTeamNotification({ teamId: teamId, notification: 0 }));
    } catch (error) {
      console.log("error in reset notification:", error);
    }
  };

  const handleChangeNotification = async (item) => {
    // console.log("🚀 ~ handleChangeNotification ~ item:", item);
    const member = item.members.find((member) => member.email === user?.email);

    try {
      const response = await apiClient().post('/team-notification-status-update', {
        teamId: item.id,
        notificationStatus: member.notificationOn ? false : true,
      });
      console.log("response notificationOn:", response.data.team.notificationOn);
      const payload = {
        teamId: item.id,
        memberEmail:member.email,
        notificationOn: response.data.team.notificationOn,

      };

      dispatch(updateTeamNotificatioOnOrOff(payload));
    } catch (error) {
      console.error("Error discarding thread:", error);
    }
  };

  return (
    <div>
      <ul className="space-y-2 px-3 py-1 max-h-[870px] overflow-y-auto">
        <li>
          <div className="flex items-center px-3 justify-between py-5 border-b-2 sticky">
            <p className="text-xl font-bold">Spaces</p>
            {/* <button onClick={() => { setTeamModal(true) }}>
              <div className="flex items-center justify-center p-2 rounded-md" style={{backgroundColor:"#e5e7eb"}}><IoAdd/></div>
            </button> */}
          </div>
        </li>

        {teamStatus === "loading" ? (
          <div className="m-2 rounded">
            <div className="flex items-start">
              <div className="flex-1">
                <div className="w-full h-4 bg-gray-200 rounded mb-2 animate-pulse"></div>
                <div className="w-full h-4 bg-gray-200 rounded mb-1 animate-pulse"></div>
                <div className="w-full h-4 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          </div>
        ) : (
          teams.map((item, index) => {
            return (
              <li
                key={index}
                className={`text-sm flex justify-between items-center p-1 rounded-lg  transition-colors duration-200 hover:bg-gray-200 `}
                style={{
                  background: isItemActive(item.id)
                    ? organizationState[0]?.secondaryColor
                    : "",
                }}
              >
                <div
                  className="relative group w-full"
                  onMouseEnter={() => setShowButtons(index)}
                  onMouseLeave={() => setShowButtons(false)}
                >
                  <div className="flex items-center">
                    <Icon
                      icon={
                        item.teamType === "public"
                          ? "solar:hashtag-linear"
                          : "mdi:lock"
                      }
                      width="1.5em"
                      height="1.5em"
                      className="text-pricingText mr-2"
                      style={{
                        color: isItemActive(item.id) ? "white" : "black",
                      }}
                    />
                    <Link
                      onClick={() => {
                        resetNotification(item.id);
                        handleItemClick(item.id);
                      }}
                      to={`/team?id=${item.id}`}
                      style={{
                        color: isItemActive(item.id) ? "white" : "black",
                      }}
                      className={`truncate w-52  flex-grow text-landing capitalize  font-normal ${
                        item.notification > 0 && "text-gray-900 font-semibold"
                      }`}
                    >
                      {item.name}
                    </Link>
                  </div>
                  {showButtons === index && (
                    <div
                      className={`absolute right-5 top-1/2 -translate-y-1/2 flex space-x-2 rounded-md px-2 py-1 transition-opacity bg-white duration-200 scale-90`}
                    >
                      <button
                        className="rounded "
                        style={{
                          color: organizationState[0]?.secondaryColor,
                        }}
                        onClick={() => handleChangeNotification(item)}
                      >
                        
                        {item.members.map((member) => {
                          if (member.email === user?.email) {
                            return member.notificationOn ? (
                              <Icon
                                icon="basil:notification-on-outline"
                                width="1.2em"
                                height="1.2em"
                              />
                            ) : (
                              <Icon
                                icon="iconamoon:notification-off"
                                width="1.2em"
                                height="1.2em"
                              />
                            );
                          }
                        })}
                      </button>
                    </div>
                  )}
                  {item.notification > 0 && (
                    <p className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[0.5px] rounded-full ml-2 border-b-2 text-center">
                      {item.notification}
                    </p>
                  )}
                </div>
              </li>
            );
          })
        )}
        <div
          className="flex items-center gap-2 py-1 rounded sticky  hover:bg-slate-200 cursor-pointer"
          onClick={() => {
            setTeamModal(true);
          }}
        >
          <button className="text-center text-sm flex items-center gap-2">
            <div
              className="flex items-center justify-center p-1 rounded-md"
              style={{ backgroundColor: "#e5e7eb" }}
            >
              <IoAdd />
            </div>
            Create new space
          </button>
        </div>
        {/* {teamState?.length > 0 && (
          <button
            className=" font-normal  text-center ml-2 border-b-2 "
            style={{
              color: `${organizationState[0]?.primaryColor}`,
              borderColor: `${organizationState[0]?.primaryColor}`,
            }}
            onClick={() => navigate("/teams")}
          >
            View All
          </button>
        )} */}
      </ul>
      <TeamModal
        teamModal={teamModal}
        setTeamModal={setTeamModal}
        initialTeam={initialTeam}
        setInitialTeam={setInitialTeam}
      />
    </div>
  );
}

export default SidebarRenderSpace;
