// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

// ** Reducers
import { authReducer } from "./authSlice";
import { finChatReducer } from "./finChat";
import { teamReducer } from "./teamSlice";
import { organizationReducer } from "./organization";
import { threadReducer } from "./ThreadSlice";
import { sidebarReducer } from "./sidebarSlice";
import { documentReducer } from "./DocumentSlice";
import { aiModelReducer } from "./aiModelSlice";
import { promptReducer } from "./promptSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    finChat: finChatReducer,
    teams: teamReducer,
    organization: organizationReducer,
    thread: threadReducer,
    sidebar: sidebarReducer,
    document: documentReducer,
    aiModels: aiModelReducer,
    prompt: promptReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
